import { Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/AppContext";

const InfiniteVirtualTable = React.memo((props) => {
  const { data, columns, handleRowClick, fetchFn, loading, noDataComponent, noDataOverlayIcon } = props;
  const [appProperties] = useContext(AppContext);
  const [isAllDataAreFetched, setIsAllDataAreFetched] = useState();

  const locale = {
    emptyText: (
      <div style={{ height: "65dvh" }}>
        <div className="d-flex flex-grow-* gap-5 flex-column align-items-center justify-content-center h-100">
          <div className="d-flex gap-3 flex-column align-items-center">
            {noDataOverlayIcon}
            {noDataComponent}
          </div>
        </div>
      </div>
    ),
  };

  const rowRenderer = async (_, rowIndex) => {
    if (!isAllDataAreFetched && !loading && rowIndex === data.length - 1) {
      const isAllDataFetched = await fetchFn();
      setIsAllDataAreFetched(isAllDataFetched);
    }
    return {};
  };

  useEffect(() => {
    async function fetchData() {
      if (appProperties?.installedApps) {
        const isAllDataFetched = await fetchFn();
        setIsAllDataAreFetched(isAllDataFetched);
      }
    }
    fetchData();
  }, [fetchFn, appProperties]);

  return (
    <Table
      pagination={false}
      bordered={false}
      columns={columns}
      loading={loading ? { indicator: <LoadingOutlined spin style={{ color: "#605BFF" }} /> } : false} // Adjust the loading prop
      locale={locale}
      rowClassName={"overviewTableRow"}
      scroll={{ x: 700, y: 420 }}
      onRow={(record, rowIndex) => ({
        ...rowRenderer(record, rowIndex),
        onClick: () => (handleRowClick ? handleRowClick(record) : undefined),
      })}
      virtual
      className="campaignOverviewTable px-3"
      dataSource={data}
      // sticky={true}
    />
  );
});

export default InfiniteVirtualTable;
