import React, { createContext, useContext, useMemo, useState, useEffect } from "react";
import { voiceAgentStatus } from "../utils/VoiceUtils";
import { AppContext } from "../../context/AppContext";

const VoiceContext = createContext();

export const VoiceProvider = ({ children }) => {
  const [appProperties] = useContext(AppContext); // Assuming this is a hook that provides app properties
  const [callProperties, setCallProperties] = useState({
    agentStatusValue: voiceAgentStatus.OFFLINE,
    twilioCapToken: "",
    disableAgentStatusChange: false,
    isDialPadVisible: "",
    callerNumber: "",
    selectedFromNumber: "",
    initiateOutboundCall: false,
    callRecordId: "",
    callRecordModule: "",
    loadedSDK: "",
    callStatus: "idle",
    savedNumbers: [],
    callType: "",
    incomingCall: false,
    onBoarding: false,
    focusToNumberField: false,
    openUserPageDrawer: false,
    voicePlanRequestAccess: false,
  });

  const callObject = useMemo(() => [callProperties, setCallProperties], [callProperties]);

  useEffect(() => {
    if (appProperties) {
      setCallProperties((prev) => ({
        ...prev,
        ...(appProperties.focusToNumberField && { focusToNumberField: appProperties.focusToNumberField }),
        ...(appProperties.callerNumber && { callerNumber: appProperties.callerNumber }),
        ...(appProperties.callRecordId && { callRecordId: appProperties.callRecordId }),
        ...(appProperties.callRecordModule && { callRecordModule: appProperties.callRecordModule }),
        ...(appProperties.callType && { callType: appProperties.callType }),
        ...(appProperties.loadedSDK && { loadedSDK: appProperties.loadedSDK }),
        ...(appProperties.serviceInitialized && { serviceInitialized: appProperties.serviceInitialized }),
        ...(appProperties.callStatus && { callStatus: appProperties.callStatus }),
        ...(appProperties.currentTime && { currentTime: appProperties.currentTime }),
        ...(appProperties.isDialPadVisible && { isDialPadVisible: appProperties.isDialPadVisible }),
      }));
    }
  }, [appProperties]);

  return <VoiceContext.Provider value={callObject}>{children}</VoiceContext.Provider>;
};

export const useVoiceContext = () => {
  return useContext(VoiceContext);
};
