import { urlParams } from "./../constants/AppConstants";
import AppExtensionsSDK, { Command, Event, View } from "@pipedrive/app-extensions-sdk";
import SendSmsDemo from "../assets/video/SendSmsVideo.mp4";
import Thumbnail from "../assets/images/sendsms-thumbnail.png";
import { useContext, useState } from "react";

function getAllUrlParams() {
  var allUrlParams = new URLSearchParams(window.location.search);
  var resourceObj = {};
  for (var key of allUrlParams.keys()) {
    resourceObj[key] = allUrlParams.get(key);
  }
  var resourceStr = JSON.stringify(resourceObj);
  return encodeURIComponent(resourceStr);
}
export let PipeDriveSMS = {
  primaryPhoneApiName: "phone",
  secondaryPhoneApiName: "mobile",
  service: "Pipedrive",
  isOmniLicense: true,
  productId: "102",
  isInboxEnabled: true,
  isWorkflowTableFormat: true,
  isWorkflowtext: "Webhook",
  workFlowIconInTemplates: false,
  api: "api/v2/",
  addUserButton: false,
  redirectPath: "/persons/list",
  associatedLookup: false,
  SMS_GALLERY: [
    {
      headline: "Single and Bulk Messaging",
      sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
      steps: ["Send WhatsApp messages to Pipedrive prospects", "Track all conversation history within Pipedrive"],
    },
    {
      headline: "Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive",
      sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
      steps: ["Create a Webhook URL", "Set up automation in Pipedrive"],
    },
    {
      headline: "Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive",
      sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
      steps: ["Send templated messages for each deal stage", "Boost conversions with timely engagement"],
    },
  ],
  VOICE_GALLERY: [
    {
      headline: "Single and Bulk Messaging",
      sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
      steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
    },
    {
      headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
      sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
      steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
    },
    {
      headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
      sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
      steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
    },
  ],
  WHATSAPP_GALLERY: [
    {
      headline: "Boost Sales with Direct WhatsApp Messaging in Pipedrive",
      sourceUrl: "https://www.youtube.com/embed/aB6BTkcRBaM?si=7evfv3ukmLAh9Tul",
      steps: ["Send WhatsApp messages to Pipedrive prospects", `Track all conversation history within Pipedrive`],
    },
    {
      headline: `Automate SMS/WhatsApp message for Appointment Scheduling in Pipedrive`,
      sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM?si=72NEoKwJYlOw8ais",
      steps: ["Create a Webhook URL", `Set up automation in Pipedrive`],
    },
    {
      headline: `Automate SMS/WhatsApp message When Deal Stages Change in Pipedrive`,
      sourceUrl: "https://www.youtube.com/embed/QMEwqIIxyI8?si=xYp5UV_UlaQL6ZZb",
      steps: ["Send templated messages for each deal stage", `Boost conversions with timely engagement`],
    },
  ],
  automationPageHelpVideo: [
    {
      headline: "Automate SMS / WhatsApp messages for Deal Stage Changes",
      sourceUrl: "https://www.youtube.com/embed/XFV0FuCd8rw",
      steps: ["Send automatic messages to clients when deals progress in Pipedrive"],
    },
    {
      headline: "Automate SMS / WhatsApp messages  for Pipedrive Activities",
      sourceUrl: "https://www.youtube.com/embed/HAu_dA1DLnE",
      steps: ["Setup automation whenever an activity is newly created in Pipedrive"],
    },
    {
      headline: "Automate SMS / WhatsApp messages for Appointment Scheduling",
      sourceUrl: "https://www.youtube.com/embed/5IqYa64KrnQ",
      steps: ["Manage your appointments with automated messages for scheduling, rescheduling, and cancellations."],
    },
    {
      headline: "Configuring Messaging Automation",
      sourceUrl: "https://www.youtube.com/embed/XFV0FuCd8rw",
      steps: ["Learn how to set up automatic SMS/WhatsApp notification in 2 simple steps"],
    },
    {
      headline: "Automate SMS / WhatsApp messages in Pipedrive.",
      sourceUrl: "https://www.youtube.com/embed/XFV0FuCd8rw",
      steps: [`Learn to automate in Pipedrive for your business needs.`],
    },
  ],
  sendSmsVideoComponent: <video width={"100%"} height={"100%"} className="mt-2" poster={Thumbnail} src={SendSmsDemo} controls />,
  sideBarExtend: {
    homeSideBar: false,
    serviceSideBar: true,
    inboxSidebar: true,
    conversationSidebar: false,
    sendSmsSidebar: false,
    sendBulkSmsSidebar: false,
    campaignSidebar: true,
    campaignOverviewSidebar: true,
    phoneSidebar: false,
    pagesSidebar: false,
    templateSideBar: true,
    workFlowSideBar: true,
    usersSideBar: true,
    automationSideBar: true,
    workflowTabName: "Webhook",
    workflowTabSingularName: "Webhook",
    hideSMSTabsInSideBar: false,
    settingSideBar: true,
  },
  filterTypeMap: {
    user_id: 1,
    filter_id: 2,
    team_id: 3,
    everyone: null,
  },
  planDetails: {
    topPlan: "Gold",
    topPlanKey: "PD_GOLD",
    topPlanId: "Gold-USD-Monthly",
    totalUsers: 3,
  },
  defaultSettingValue: {
    emailNotification: {
      sendEmail: true,
      recordOwner: true,
      accountOwner: true,
    },
    loggingPreference: {
      default: "notes",
      logRecordsFor: "all",
    },
  },
  loadScripts: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  init: function (setAppProperties) {
    return new Promise((resolve, reject) => {
      const id = urlParams.get("id");

      try {
        let height = 550;
        let width = 550;

        let path = window.location.pathname;

        if (path === "/voice/dialpad") {
          height = 700;
          width = 400;
        }

        new AppExtensionsSDK({ identifier: id })
          .initialize({
            size: { height: height, width: width },
          })
          .then((pipeDriveSDK) => {
            if (setAppProperties) {
              setAppProperties((prev) => {
                return { ...prev, loadedSDK: pipeDriveSDK, serviceInitialized: true };
              });
            }
            listenEvents(pipeDriveSDK, setAppProperties);
          })
          .catch((error) => {
            console.log("APP SDK END SUCCESSFUL CATCH TIME and REJECTED >>>>>>>>>>", error);
          });
      } catch (error) {
        console.log("APP SDK END ERROR CATCH TIME and REJECTED>>>>>>>>>>", error);
      }
      resolve();
    });
  },
  pricingLink: function (appProperties) {
    return new Promise((resolve, reject) => {
      const { hash, osyncId, domain } = appProperties;
      var leftServiceId = urlParams.get("leftServiceId");
      var leftServiceName = urlParams.get("service");
      var allUrlParams = getAllUrlParams();
      var redirectSendSMSFromModule = domain + "/persons/list";
      var hrefUrl = `/pricing?productId=102&groupName=OMNI_CHANNEL&domain=${domain}&hash=${hash}&leftServiceId=${leftServiceId}&osyncId=${osyncId}&service=${leftServiceName}&urlParams=${allUrlParams}&serviceOrigin=${redirectSendSMSFromModule}`;
      resolve(hrefUrl);
    });
  },
  getWebhookContent: function (fetchData, fetchWebhookNotifications, appProperties) {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;
      fetchData("omessage/" + integId + "/webhookNotifications", "GET", null, appProperties).then((response) => {
        let webhookNotification = JSON.parse(response);
        resolve(webhookNotification);
      });
    });
  },
  getUserData: function () {
    var companyId = urlParams.get("companyId");
    var emailId = urlParams.get("email");
    var userId = urlParams.get("userId");
    var domain = urlParams.get("domain");
    var companyOrgId = companyId;
    return new Promise((resolve, reject) => {
      var userData = {
        companyId: companyId,
        userId: userId,
        companyOrgId: companyOrgId,
        domain: domain,
        emailId: emailId,
      };
      resolve(userData);
    });
  },
  getDefaultModuleForAGivenModule: function (moduleName) {
    return new Promise((resolve, reject) => {
      if (moduleName === "deal") {
        resolve(["person"]);
      } else {
        resolve(moduleName);
      }
    });
  },
  savePhone: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getSavedNumbers: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getAssociatedObjectId: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("selectedIds"));
    });
  },
  getAssociatedObjectType: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("resource"));
    });
  },
  getAssociatedObjectTypeInPlural: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("resource") + "s");
    });
  },
  getAssociatedIntegId: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("integId"));
    });
  },
  getAssociatedHash: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("hash"));
    });
  },

  fetchContact: function (fetchData) {
    return new Promise((resolve, reject) => {
      let phoneNumber = urlParams.get("phone")?.trim();
      resolve(phoneNumber);
    });
  },
  getSubscriptionId: function (fetchSubscriptionData, appProperties) {
    return new Promise((resolve, reject) => {
      fetchSubscriptionData(appProperties).then((response) => {
        resolve(response);
      });
    });
  },
  doAfterSuccessfulEnable: function (dataObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  associatedModules: function () {
    return new Promise((resolve) => {
      resolve({
        person: ["person", "organization"],
        deal: ["deal", "person", "organization"],
        lead: ["lead", "person", "organization"],
        organization: ["organization"],
      });
    });
  },
  fetchDefaultFilterOptions: function (moduleName) {
    return new Promise((resolve, reject) => {
      let options = [
        {
          label: `All ${moduleName}`,
          value: "-1",
          filterId: "-1",
          filterName: `All ${moduleName}`,
          systemDefined: false,
        },
      ];
      resolve(options);
    });
  },
  getUrlParamsForRedirection: function (isNewWindow, appProperties) {
    const urlParams = new URLSearchParams(window.location.search);
    const rightServiceId = appProperties.rightServiceId ?? urlParams.get("rightServiceId");
    const token = urlParams.get("token");
    urlParams.delete("source");
    return new Promise((resolve, reject) => {
      var redirectUrl = `leftServiceId=${appProperties.leftServiceId}&service=pdOmni&rightServiceId=${rightServiceId}&domain=org${appProperties.companyId}&userId=${appProperties.userId}&companyId=${appProperties.companyId}`;
      if (token) {
        redirectUrl += `&token=${token}`;
      }
      resolve(redirectUrl);
    });
  },
  fetchContactsCount: function (fetchFilterInfoByFilterId, appProperties, integId, module) {
    return new Promise((resolve, reject) => {
      var selectedIds = urlParams.get("selectedIds");
      var excludedIds = urlParams.get("excludedIds");
      this.getFilterObjectForShrinkView().then((filterObject) => {
        let filterId = filterObject?.filterId;
        let filterType = filterObject?.filterType;
        let filter = filterObject?.filter;

        if (!filterId && (selectedIds || excludedIds)) {
          filterId = "-1";
        }

        if (filterId) {
          fetchFilterInfoByFilterId(
            appProperties,
            integId,
            module,
            filterId,
            selectedIds,
            excludedIds,
            appProperties?.leftServiceId,
            filterType,
            filter
          ).then(function (response) {
            if (response?.count) {
              resolve(response?.count);
            }
          });
        }
      });
      // }
    });
  },

  getFilterObjectForShrinkView: function () {
    return new Promise((resolve, reject) => {
      var filterId = "";
      var filterType = 0;
      let filterFromParams = urlParams.get("filter");
      try {
        const filterJson = JSON.parse(filterFromParams);
        if (filterJson) {
          var filterObjectKey = Object.keys(this.filterTypeMap).find((filterTypeObjectKey) => filterJson[filterTypeObjectKey]);
          if (filterObjectKey !== "everyone") {
            filterType = this.filterTypeMap[filterObjectKey];
            filterId = filterJson[filterObjectKey];
          } else {
            filterId = "-1";
          }
        }
      } catch (error) {
        console.log(error);
      }
      resolve({
        filterId: filterId,
        filterType: filterType,
        filter: filterFromParams,
      });
    });
  },
  getSettingPreference: function (settingsObj) {
    return new Promise((resolve, reject) => {
      const keysToRemove = ["defaultCountryCode", "emailNotification", "loggingPreference", "inboxAccessSettings"];
      const filteredArray = settingsObj.filter((item) => item && (keysToRemove.includes(item.key) || keysToRemove.includes(item.parentKey)));
      resolve(filteredArray);
    });
  },
  getApplicationToken: function () {
    var token = urlParams.get("token");
    return new Promise((resolve, reject) => {
      resolve(token);
    });
  },
  showCallNotification: function (pipedriveSDK, callState, setAppProperties) {
    return new Promise((resolve, reject) => {
      showFloatingWindow(pipedriveSDK);
    });
  },
  hideCallNotification: function (pipedriveSDK) {
    return new Promise((resolve, reject) => {
      hideFloatingWindow(pipedriveSDK);
    });
  },
  enableFocusMode: function (pipeDriveSDK) {
    return new Promise((resolve, reject) => {
      enableFocusMode(pipeDriveSDK);
    });
  },
  disableFocusMode: function (pipeDriveSDK) {
    return new Promise((resolve, reject) => {
      disableFocusMode(pipeDriveSDK);
    });
  },
  openRecord: function (sdk, id, module) {
    return new Promise((resolve, reject) => {
      redirectToContact(sdk, id, module);
    });
  },
};

export const hideFloatingWindow = async (sdk) => {
  if (sdk) {
    await sdk?.execute(Command.HIDE_FLOATING_WINDOW, {});
  }
};

export const redirectToContact = async (sdk, id, module) => {
  const moduleViewMap = {
    leads: View.LEADS,
    deals: View.DEALS,
    person: View.CONTACTS,
    organization: View.ORGANIZATIONS,
  };
  const associatedObject = moduleViewMap[module] || View.CONTACTS;

  try {
    await sdk?.execute(Command.REDIRECT_TO, { view: associatedObject, id });
  } catch (error) {
    console.error("Failed to redirect", error);
  }
};

export const showFloatingWindow = async (sdk) => {
  try {
    await sdk?.execute(Command.SHOW_FLOATING_WINDOW, {});
    // await sdk.execute(Command.SET_FOCUS_MODE, true);
  } catch (error) {
    console.error("Error showing floating window or setting focus mode:", error);
  }
};

export const enableFocusMode = async (sdk) => {
  await sdk?.execute(Command.SET_FOCUS_MODE, true);
};

export const disableFocusMode = async (sdk) => {
  await sdk?.execute(Command.SET_FOCUS_MODE, false);
};

export const listenEvents = async (pipeDriveSDK, setAppProperties) => {
  pipeDriveSDK.listen(Event.VISIBILITY, ({ error, data }) => {
    // handle event
    // const [appProperties] = useContext(AppContext);
    // console.log(" listenEvents ::listening appProperties latest >>>>", appProperties);
    if (data) {
      let isVisible = data?.is_visible;
      let callToNumber = data?.context?.callToNumber;
      let personId = data?.context?.relatedIds?.personId;
      let orgId = data?.context?.relatedIds?.orgId;
      let dealId = data?.context?.relatedIds?.dealId;
      let leadId = data?.context?.relatedIds?.leadId;

      let associatedObjectId = personId;
      let associatesObjectType = "person";

      if (orgId) {
        associatedObjectId = orgId;
        associatesObjectType = "organization";
      }
      if (orgId && personId) {
        associatedObjectId = personId;
        associatesObjectType = "person";
      }
      if (dealId) {
        associatedObjectId = dealId;
        associatesObjectType = "deal";
      }
      if (leadId) {
        associatedObjectId = leadId;
        associatesObjectType = "leads";
      }

      if (callToNumber) {
        setAppProperties((prev) => {
          return {
            ...prev,
            callerNumber: callToNumber,
            callRecordId: associatedObjectId,
            callRecordModule: associatesObjectType,
            callType: "outbound",
          };
        });
      }
      setAppProperties((prev) => {
        return {
          ...prev,
          focusToNumberField: isVisible ? "visible" : "hidden",
        };
      });
    } else if (error) {
    }
  });
};
