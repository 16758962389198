import React, { useContext } from "react";
import useHttp from "../../hooks/useHttp";
import { AppContext } from "../../context/AppContext";
import { voiceAgentStatus } from "../utils/VoiceUtils";

export const useVoiceAgentStatus = () => {
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
  const voiceIntegId = voiceIntegrations?.[0]?.integProps?.integId;
  const getCurrentVoiceAgentStatus = () => {
    return new Promise((resolve, reject) => {
      if (voiceIntegId) {
        fetchData(`agent/status?integId=` + voiceIntegId, "GET", null, appProperties).then((response) => {
          if (response) {
            const resData = JSON.parse(response);
            resolve(resData?.data);
          }
        });
      }
    });
  };
  let leftServiceId = appProperties?.leftServiceId;

  // const handleStatusChange = (value) => {
  //     return new Promise((resolve, reject) => {
  //       if (leftServiceId && appProperties?.userId && voiceIntegId) {
  //         fetchData(
  //           `voice/${voiceIntegId}/agents/${appProperties.userId}?status=${value}&leftServiceId=${leftServiceId}`,
  //           "PUT",
  //           { payload: "" },
  //           appProperties
  //         )
  //           .then((response) => {
  //             const statusValueResponse = JSON.parse(response);
  //             const statusValue = statusValueResponse?.data?.voiceAgentStatusEntity?.voiceAgentStatus || voiceAgentStatus.OFFLINE;

  //             if (statusValue === voiceAgentStatus.ON_CALL || statusValue === voiceAgentStatus.AFTER_CALL_PROCESS) {
  //               setCallProperties((prev) => ({
  //                 ...prev,
  //                 agentStatusValue: voiceAgentStatus.BUSY,
  //               }));
  //             } else {
  //               setCallProperties((prev) => ({
  //                 ...prev,
  //                 agentStatusValue: statusValue,
  //               }));
  //             }

  //             if (statusValue === voiceAgentStatus.ONLINE_WEB) {
  //               setShowOfflineMessageWhileInit(false);
  //             }
  //             const token = statusValueResponse?.data?.capToken;
  //             if (token) {
  //               setCallProperties((prev) => ({
  //                 ...prev,
  //                 twilioCapToken: token,
  //               }));
  //             }
  //             resolve(statusValueResponse);
  //           })
  //           .catch((error) => {
  //             console.error("Error updating status:", error);
  //             reject(error);
  //           });
  //       } else {
  //         console.error("Missing leftServiceId or userId.");
  //       }
  //     });
  //   };
  return { getCurrentVoiceAgentStatus };
};
