import { Card, Divider, Modal } from "antd";
import React, { useState } from "react";
import "../assets/css/voiceOnboarding.css";
import HButton from "../../components/custom/input/button/Button";
import { useVoiceContext } from "../context/VoiceContext.js";
export default function VoiceOnboardingFinishPopup({ leftServiceName, appProperties }) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.delete("sideBar");
  params.delete("onBoardingPage");
  params.delete("onInstall");

  const [callProperties, setCallProperties] = useVoiceContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const hyperLink =
    leftServiceName === "Zoho CRM" ? appProperties?.domain : appProperties?.domain + (appProperties?.controller?.redirectPath || "");
  const onboardingComponents = appProperties?.providerCommonObj?.onBoardingFlow?.getFinishModalComponent(
    appProperties,
    callProperties,
    setCallProperties
  );
  const onBoardingCardData = onboardingComponents?.steps;
  const note = onboardingComponents?.note;
  const head = onboardingComponents?.head;
  const subHead = onboardingComponents?.subHead;

  const openVideoModal = (url) => {
    const urlParams = new URLSearchParams(window.location.search);
    const service = urlParams.get("service");
    if (service === "zohocrm") {
      window.open(url, "_blank");
    } else {
      setVideoUrl(url);
      setIsModalVisible(true);
    }
  };

  return (
    <div className="d-flex align-items-center flex-column w-100 gap-2 pb-2 mt-2">
      <VoiceOnboardingFinishPopup.Title head={head} subHead={subHead} />
      <VoiceOnboardingFinishPopup.InfoCard onBoardingCardData={onBoardingCardData} openVideoModal={openVideoModal} />
      {note && <VoiceOnboardingFinishPopup.Note note={note} />}
      <VoiceOnboardingFinishPopup.GoToButton hyperLink={hyperLink} leftServiceName={leftServiceName} />
      <Modal open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null} width={"80vw"}>
        <iframe
          width="100%"
          height="600"
          src={videoUrl}
          title="HelloSend"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
          // referrerpolicy="strict-origin-when-cross-origin"
          referrerPolicy="no-referrer-when-downgrade"
          allowfullscreen
        ></iframe>
      </Modal>
    </div>
  );
}

VoiceOnboardingFinishPopup.InfoCard = InfoCard;
VoiceOnboardingFinishPopup.Note = Note;
VoiceOnboardingFinishPopup.Title = Title;
VoiceOnboardingFinishPopup.GoToButton = GoToButton;
function InfoCard({ onBoardingCardData, openVideoModal }) {
  return (
    <>
      <Divider />
      {onBoardingCardData?.map((data, index) => (
        <Card key={index} className="hs-w-90 mx-3">
          <div className="d-flex flex-row gap-3 w-100">
            <div className="d-flex align-items-start">
              <div className="call-action-icons onboarding-info-icon" />
            </div>
            <div className="d-flex gap-2 flex-column w-100">
              <div className="d-flex justify-content-between">
                <div className="hs-fw-600 hs-color-BlackRussian">{data.title}</div>
                <div className="d-flex align-items-center cursor-pointer" onClick={() => openVideoModal(data.videoUrl)}>
                  <div className="call-action-icons onboarding-video-player-icon" />
                  <div className="hs-fw-500 hs-color-violet hs-fs-12">Watch</div>
                </div>
              </div>
              <div className="hs-fs-13 hs-color-dark-grey hs-fw-400">{data.description}</div>
            </div>
          </div>
        </Card>
      ))}
    </>
  );
}

function Note({ note }) {
  return (
    <div className="d-flex">
      <div className="hs-fw-500 hs-color-BlackRussian hs-fs-13">Note:</div>
      <div className="hs-color-dark-grey hs-fw-400 hs-fs-13 ps-1">{note}</div>
    </div>
  );
}

function Title({ head, subHead }) {
  return (
    <div className="d-flex flex-column pt-3 gap-2 w-100 ps-4">
      <div className="d-flex ps-2">
        <div className="hs-color-BlackRussian hs-fw-700 hs-fs-18">Welcome aboard! </div>
        <div className=" hs-color-BlackRussian hs-fw-700 hs-fs-15 ps-1 pt-1">{head}</div>
      </div>
      <div className="hs-color-light-grey-2 hs-fs-12 hs-fw-400 ps-2">{subHead}</div>
    </div>
  );
}

function GoToButton({ hyperLink, leftServiceName }) {
  return (
    <div className="d-flex justify-content-end hs-w-90">
      <HButton onClick={() => window.open(hyperLink, "_blank")}>Go to {leftServiceName}</HButton>
    </div>
  );
}
