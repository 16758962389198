import { Checkbox } from "antd";

function HCheckBoxGroup({ options, checkedValue, onChange }) {
  return (
    <div className="d-flex mt-2 gap-3">
      {options.map((option) => (
        <Checkbox
          key={option.value}
          className="schedule-check-box hs-fs-16"
          value={option.value}
          onChange={(e) => onChange(e)}
          checked={checkedValue === option.value}
        >
          {option.label}
        </Checkbox>
      ))}
    </div>
  );
}

export default HCheckBoxGroup;
