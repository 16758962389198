import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import HButton from "../../../components/custom/input/button/Button";
import VoiceSelect from "../common/VoiceSelect";

const MusicUploadComponent = ({ callFlowId, nodeId, musicData, setMusicData, integId, inputValue, switchChecked }) => {
  const [inputForm] = Form.useForm();
  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    setTextAreaValue(musicData?.text);
  }, [musicData?.text]);

  const handleTypeChange = (value) => {
    setMusicData({ ...musicData, selectType: value });
  };

  const handleApply = async () => {
    if (musicData?.file && musicData?.selectType === "playAudio") {
      setMusicData({
        ...musicData,
        music: musicData?.file,
        type: "Audio File",
        nodeId: nodeId,
        apply: true,
      });
      message.success(` Applied successfully.`);
    } else if (musicData?.text && musicData?.selectType === "textToSpeech") {
      setMusicData({
        ...musicData,
        music: textAreaValue,
        text: textAreaValue,
        type: "TTS",
        nodeId: nodeId,
        apply: true,
      });
      message.success(`Applied successfully.`);
    } else if (musicData?.url && musicData?.selectType === "url") {
      setMusicData({
        ...musicData,
        music: musicData?.url,
        type: "URL",
        nodeId: nodeId,
        apply: true,
      });
      message.success(`Applied successfully.`);
    } else if (inputValue) {
      setMusicData({
        ...musicData,
        apply: true,
      });
      message.success("Applied Successfully.");
    }
  };

  const props = {
    name: "file",
    multiple: false,
    accept: ".mp3,.wav",
    onChange(info) {
      setMusicData({
        ...musicData,
        fileName: info.file.name,
        file: info.file.originFileObj,
      });
    },
    onDrop(e) {
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile) {
        setMusicData({
          ...musicData,
          fileName: droppedFile.name,
          file: droppedFile,
        });
      }
    },
  };

  const musicUploadType = [
    { value: "textToSpeech", label: "Text to speech" },
    { value: "playAudio", label: "Play Audio" },
    { value: "url", label: "Url" },
  ];
  console.log("TextAreaValue>>>", textAreaValue, "musicData>>>", musicData);
  return (
    <div>
      <Form form={inputForm}>
        <>
          <VoiceSelect
            textAreaValue={textAreaValue}
            setTextAreaValue={setTextAreaValue}
            onChange={handleTypeChange}
            options={musicUploadType}
            value={musicData?.selectType}
            props={props}
            fileName={musicData?.fileName}
            textToSpeech={musicData?.text}
            setTextToSpeech={(value) =>
              setMusicData((prevState) => ({
                ...prevState,
                text: value,
              }))
            }
            url={musicData?.url}
            setUrl={(value) => setMusicData({ ...musicData, url: value })}
            optionSpecification={true}
            label={"Type"}
          />

          <HButton buttonClassName=" mt-4" onClick={handleApply} size="s">
            Apply
          </HButton>
        </>
      </Form>
    </div>
  );
};

export default MusicUploadComponent;
