import { message, Skeleton, Switch, Table, Tooltip, Tour } from "antd";
import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import onBoardingPhoneSwitch from "../../assets/gif/onBoardingPhoneSwitch.gif";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import "../../voice/assets/css/voiceConfigPhone.css";
import { initialEdges, initialNodes } from "../../voice/components/nodes-edges";
import { useVoiceContext } from "../../voice/context/VoiceContext";
import { useCallFlow } from "../../voice/hooks/useCallFlow";
import { useVoiceNumbers } from "../../voice/hooks/useVoiceNumbers";
import { getCurrentFeature } from "../../voice/utils/VoiceUtils";
import { featureUpgradeAlertPopup } from "../utils/CommonNotifications";
import { urlParams } from "./../../constants/AppConstants";
import { EmptyListView, UpgradeAlertPopup } from "../utils/CommonVessels";

const VoiceConfigPhone = ({ rightServiceObj, setShowFinishButton }) => {
  const { getCallFlow } = useCallFlow();
  const location = useLocation();

  const [callProperties, setCallProperties] = useVoiceContext();
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const ref1 = useRef(null);
  const { getVoiceSavedNumbers, deleteVoiceSavedNumber, addNumber } = useVoiceNumbers();
  const ref3 = useRef(null);
  const [fetchingNumberList, setFetchingNumberList] = useState(true);
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [savedNumbers, setSavedNumbers] = useState([]);
  const { fetchData } = useHttp();
  const [isEnabled, setIsEnabled] = useState({});
  const [callFlows, setCallFlows] = useState([]);
  const [switchLoading, setSwitchLoading] = useState({});

  const [appProperties] = useContext(AppContext);
  const voiceIntegrations = useMemo(
    () => appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE"),
    [appProperties]
  );
  const voiceIntegId = useMemo(() => voiceIntegrations?.[0]?.integProps?.integId, [voiceIntegrations]);

  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  let onBoardingPage = urlParams.get("onBoardingPage");
  const [tourStart, setTourStart] = useState(onBoardingPage === "true");

  useEffect(() => {
    if (savedNumbers) {
      setCallProperties((prev) => ({
        ...prev,
        savedNumbers: savedNumbers,
      }));
    }
  }, [savedNumbers]);

  const onBoardingTourSteps = [
    {
      description: (
        <>
          <img alt="onBoardingPhoneSwitch.gif" style={{ height: "70px" }} src={onBoardingPhoneSwitch} />
        </>
      ),
      placement: "right",
      target: () => ref1.current,
    },
  ];

  const handleClickOutside = (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      setTourStart(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns = [
    {
      title: (
        <div className="d-flex flex-row">
          <div className="ms-2">Numbers</div>
          <Tooltip title={"Only voice-enabled numbers will be listed here"}>
            <div className="call-action-icons numbersExclamatoryIcon " />
          </Tooltip>
        </div>
      ),
      dataIndex: "numbers",
      key: "numbers",
    },
    {
      title: <div className="me-4">Call Flow</div>,
      dataIndex: "callFlow",
      key: "callFlow",
    },
    // {
    //   title: <div className="">Hold Music</div>,
    //   dataIndex: "holdMusic",
    //   key: "holdMusic",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const handleSwitchChange = async (checked, phoneNumber) => {
    setSwitchLoading((prev) => ({ ...prev, [phoneNumber]: true }));

    const payload = {
      phoneNumber: phoneNumber,
      nodes: initialNodes,
      edges: initialEdges,
    };

    try {
      if (checked) {
        setIsEnabled((prev) => ({ ...prev, [phoneNumber]: true }));

        const response = await addNumber(payload, voiceIntegId);
        if (response) {
          if (response) {
            setTourStart(false);
            setCallProperties((prev) => ({
              ...prev,
              savedNumbers: [...prev?.savedNumbers, response],
            }));

            setShowFinishButton(true);
          }
          goToNextStep();
          setSavedNumbers((prev) => [...prev, response]);
          message.success("Phone number saved successfully.");
        } else {
          message.error("An error occurred while saving the phone number. Please try again.");
        }
      } else {
        try {
          setIsEnabled((prev) => ({ ...prev, [phoneNumber]: false }));
          goToNextStep();

          await deleteVoiceSavedNumber(phoneNumber).then((response) => {
            setSavedNumbers((prevNumberData) => prevNumberData.filter((num) => num.phoneNumber !== phoneNumber));
            message.success("Phone number deleted successfully.");
          });
        } catch (error) {
          console.error("Error while deleting phone number:", error);
          message.error("An error occurred while deleting the phone number.");
        }
      }
    } catch (error) {
      console.error("Error in handling phone number:", error);
      message.error("An error occurred while processing your request.");
    } finally {
      setSwitchLoading((prev) => ({ ...prev, [phoneNumber]: false }));
    }
  };

  useEffect(() => {
    const featureName = getCurrentFeature(appProperties);
    if (featureName === "Message") {
      setUpgradeAlertPopup(true);
    } else {
      if (voiceIntegId) {
        setFetchingNumberList(true);
        fetchData(`omessage/${voiceIntegId}/numbers?capabilities=voice`, "GET", null, appProperties)
          .then((response) => {
            const responseData = JSON.parse(response);
            const nestedData = JSON.parse(responseData?.data?.data || "{}");
            const phoneNumbers = nestedData?.phone || [];
            setPhoneNumberList(phoneNumbers);
          })
          .catch((error) => console.error("Error fetching or parsing data:", error))
          .finally(() => setFetchingNumberList(false));
      }
    }
  }, [appProperties, fetchData, voiceIntegId]);

  useEffect(() => {
    const loadCallFlows = async () => {
      try {
        const res = await getCallFlow();
        if (res.length > 0) {
          setCallFlows(res);
        }
      } catch (error) {
        console.error("Error parsing response data:", error);
      }
    };

    loadCallFlows();
  }, [getCallFlow]);

  useEffect(() => {
    if (voiceIntegId && !upgradeAlertPopup) {
      getVoiceSavedNumbers("all")
        .then((response) => {
          if (response) {
            setSavedNumbers(response);
            const fileNames = {};
            setCallProperties((prev) => ({
              ...prev,
              savedNumbers: response,
            }));
            response?.forEach((number) => {
              fileNames[number.phoneNumber] = number.holdMusicFileName || "default.mp3";
            });
          }
        })
        .catch((err) => {
          console.log("Error fetching SavedNumbers:", err);
        });
    }
  }, [getVoiceSavedNumbers, voiceIntegId]);

  const findCallFlowNameById = (callFlowId, savedNumber) => {
    const flow = callFlows.find((flow) => flow.callFlowId === callFlowId);
    return flow?.callFlowName ? flow?.callFlowName : savedNumber?.callFlowName ? savedNumber?.callFlowName : "Call Flow";
  };

  const navigateCallFlow = (callFlowId, phoneNumber) => {
    try {
      const editCallFlowEndpoint = `/voice/callFlow/edit/${callFlowId}/phoneNumber/${encodeURIComponent(phoneNumber)}`;
      navigate(editCallFlowEndpoint + location.search);
    } catch (error) {
      console.log("Error navigating callFlow", error);
    }
  };

  const dataSource = phoneNumberList.map((num, index) => {
    const savedNumber = savedNumbers?.find((element) => element?.phoneNumber === num);
    const callFlowName = savedNumber ? findCallFlowNameById(savedNumber.callFlowId, savedNumber) : "-";
    return {
      key: index,
      numbers: (
        <div className=" me-2" style={{ fontWeight: 600 }}>
          {num}
        </div>
      ),
      callFlow: (
        <div style={{ fontWeight: 500, cursor: "default" }}>
          {callFlowName}
          {callFlowName !== "-" && (
            <span
              ref={ref3}
              className={isEnabled[num] || savedNumbers.some((element) => element.phoneNumber === num) ? "enabled-span" : "disabled-span"}
              onClick={() => {
                navigateCallFlow(savedNumber?.callFlowId, num, savedNumber);
              }}
            >
              {" "}
              Edit
            </span>
          )}
        </div>
      ),
      status: (
        <Switch
          ref={index === 0 ? ref1 : null}
          loading={switchLoading[num]}
          checked={savedNumbers?.some((element) => element?.phoneNumber === num)}
          className="addPhoneSwitch"
          onClick={(e) => handleSwitchChange(e, num)}
        />
      ),
    };
  });

  const goToNextStep = () => {
    setCurrentStep((prevStep) => (prevStep + 1 < onBoardingTourSteps.length ? prevStep + 1 : 0));
  };

  return (
    <>
      <div className="d-flex align-items-center mt-2" style={{ backgroundColor: "#fff" }}>
        <div className="w-100 mt-2 ms-2">
          {fetchingNumberList ? (
            <Skeleton className="w-100%" paragraph={{ rows: 4 }} active />
          ) : (
            <div className="">
              <Table
                // scroll={{ y: 350 }}
                pagination={false}
                className="phoneAddTable voiceNumberTable voice-config-phone-table ms-0"
                columns={columns}
                dataSource={dataSource}
                style={{ width: "750px" }}
                locale={{
                  emptyText: (
                    <EmptyListView
                      icon={"callCardIcons emptyVoiceCallListView display-inline-block"}
                      tagLineOne={"No Numbers"}
                      tagLineTwo={"You have no numbers in Twilio or no numbers available."}
                      tagHeight={"40vh"}
                    />
                  ),
                }}
              />
              <Tour
                rootClassName="voice-tour"
                closeIcon={false}
                open={tourStart}
                onClose={() => setTourStart(false)}
                steps={onBoardingPage === "true" ? onBoardingTourSteps : onBoardingTourSteps}
                // current={currentStep}
                // onChange={setCurrentStep}
              />
            </div>
          )}
        </div>
      </div>

      {upgradeAlertPopup && <UpgradeAlertPopup upgradeAlertPopup={upgradeAlertPopup} setUpgradeAlertPopup={setUpgradeAlertPopup} />}
    </>
  );
};

export default VoiceConfigPhone;
