import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, { Handle, Position, ReactFlowProvider, useEdgesState, useNodesState } from "reactflow";
import "reactflow/dist/style.css";
import "../assets/css/callFlow.css";
import "../assets/css/dnd.css";

import { Col, Row } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import MusicUploadComponent from "./common/MusicUploadComponent";
import NodesDrawer from "./common/NodesDrawer";
import FlowFooter from "./FlowFooter";
import { initialEdges, initialNodes } from "./nodes-edges";
import BusinessHour from "./Nodes/BusinessHour";
import { ClosedNode, CustomNode, HandleSvg, NoAnswer, OpenNode } from "./Nodes/CallFlowNodes";
import CallRecording from "./Nodes/CallRecording";
import CustomEdge from "./Nodes/CustomEdge";
import HangUp from "./Nodes/HangUp";
import PhoneNumberNode from "./Nodes/PhoneNumberNode";
import RingTeam from "./Nodes/RingTeam";
import { featureUpgradeAlertPopup } from "../../pages/utils/CommonNotifications";
import { AUTOMATED_MESSAGE_TYPE, getCurrentFeature } from "../utils/VoiceUtils";
import { UpgradeAlertPopup } from "../../pages/utils/CommonVessels";

const edgeTypes = {
  CustomEdge,
};

const defaultViewport = { x: 180, y: 130, zoom: 1.5 };

const CallHandlingFlowChart = () => {
  const [nodeTitle, setNodeTitle] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [nodeTitleStyle, setNodeTitleStyle] = useState("");
  const [nodeDescription, setNodeDescription] = useState("");
  const [nodeDescriptionStyle, setNodeDescriptionStyle] = useState("");
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [drawerContent, setDrawerContent] = useState();
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const [callFlowName, setCallFlowName] = useState("");
  const [voiceIntegId, setVoiceIntegId] = useState();
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const [clickedNodeId, setClickedNodeId] = useState("1");
  const { callFlowId, phoneNumber } = useParams();

  /// WELCOME
  const [welcomeMusicData, setWelcomeMusicData] = useState({
    classification: " WELCOME",
    fileName: "",
    text: "",
    url: "",
    file: "",
    music: null,
    selectType: "textToSpeech",
    type: "TTS",
    nodeId: "",
    apply: false,
  });

  /// PHONE-NUMBER
  const [phoneNumberData, setPhoneNumberData] = useState({
    classification: "HOLD",
    voiceMailStatus: 1,
    fallBackNumber: "",
    // file: '',
    // text: 'Your call is on hold please wait',
    // url: "",
    // fileName: "default.mp3 or",
    // music: null,
    // selectType: "playAudio",
    // type: "TTS",
    nodeId: "",
    apply: false,
  });

  /// BUSINESS-HOUR
  const [businessHourData, setBusinessHourData] = useState({
    isCalendarHours: true,
    timeZone: "",
    businessHourId: "",
    businessDays: [
      { hourStartTime: "09", hourEndTime: "17", isOpen: 0, minuteStartTime: "00", minuteEndTime: "00", businessDay: "0" },
      { hourStartTime: "09", hourEndTime: "17", isOpen: 1, minuteStartTime: "00", minuteEndTime: "00", businessDay: "1" },
      { hourStartTime: "09", hourEndTime: "17", isOpen: 1, minuteStartTime: "00", minuteEndTime: "00", businessDay: "2" },
      { hourStartTime: "09", hourEndTime: "17", isOpen: 1, minuteStartTime: "00", minuteEndTime: "00", businessDay: "3" },
      { hourStartTime: "09", hourEndTime: "17", isOpen: 1, minuteStartTime: "00", minuteEndTime: "00", businessDay: "4" },
      { hourStartTime: "09", hourEndTime: "17", isOpen: 1, minuteStartTime: "00", minuteEndTime: "00", businessDay: "5" },
      { hourStartTime: "09", hourEndTime: "17", isOpen: 0, minuteStartTime: "00", minuteEndTime: "00", businessDay: "6" },
    ],
    apply: false,
  });

  /// TEAMS
  const [teamData, setTeamData] = useState({
    selectedTeamName: "",
    apply: false,
    ringingType: 1,
    repeatCount: 3,
    ringInterval: 6,
  });

  /// RECORDING
  const [recordData, setRecordData] = useState({
    classification: "RECORD",
    recordStatus: 0,
    file: null,
    fileName: "",
    url: "",
    text: "",
    selectType: "textToSpeech",
    music: null,
    type: "TTS",
    nodeId: "",
    apply: false,
  });

  /// CALL-WAITING
  const [callWaitingMusicData, setCallWaitingMusicData] = useState({
    classification: "WAITING",
    fileName: "",
    file: null,
    text: "",
    url: "",
    type: "TTS",
    selectType: "textToSpeech",
    music: null,
    nodeId: "",
    apply: false,
  });

  /// BUSY
  const [busyMusicData, setBusyMusicData] = useState({
    classification: "BUSY",
    fileName: "",
    file: null,
    url: "",
    text: "",
    music: null,
    selectType: "textToSpeech",
    type: "TTS",
    nodeId: "",
    apply: false,
  });

  ///  UNAVAILABLE
  const [unavailableMusicData, setUnavailableMusicData] = useState({
    classification: "UNAVAILABLE",
    fileName: "",
    file: null,
    url: "",
    text: "",
    selectType: "textToSpeech",
    type: "TTS",
    music: null,
    nodeId: "",
    apply: false,
  });

  const [automatedMessagesObj, setAutomatedMessagesObj] = useState("");

  useEffect(() => {
    if (appProperties?.installedApps) {
      const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
      if (voiceIntegrations?.[0]?.integProps?.integId) {
        setVoiceIntegId(voiceIntegrations?.[0]?.integProps?.integId);
      }
    }
  }, [appProperties?.installedApps]);

  useEffect(() => {
    const fetchConfigData = async () => {
      fetchData(
        `voice/${voiceIntegId}/savedNumber/configuration?phoneNumber=${encodeURIComponent(phoneNumber)}`,
        "GET",
        null,
        appProperties
      ).then(async (response) => {
        const responseData = JSON.parse(response);
        const savedNumberData = responseData?.data?.numberVoiceConfig;
        const callFlowData = responseData?.data?.callFlowEntity;
        const businessHourModel = responseData?.data?.businessHourModel;
        const automatedMessages = responseData?.data?.automatedMessages;
        if (automatedMessages != null) {
          setAutomatedMessagesObj(automatedMessages);
        }

        if (savedNumberData || (businessHourModel && Object.keys(businessHourModel).length > 0)) {
          setBusinessHourData({
            ...businessHourData,
            isCalendarHours: savedNumberData?.businessHourType === 1,
            businessHourId: savedNumberData?.businessHourId,
            ...(businessHourModel &&
              Object.keys(businessHourModel).length > 0 && {
                timeZone: businessHourModel?.businessHour?.timeZone,
                businessDays: businessHourModel?.businessDays,
              }),
          });

          setRecordData({
            ...recordData,
            recordStatus: savedNumberData?.callRecordStatus,
          });

          setPhoneNumberData((prevData) => ({
            ...prevData,
            fallBackNumber: savedNumberData?.fallBackNumber,
            voiceMailStatus: savedNumberData?.voiceMailStatus,
          }));
          setTeamData((prevTeamData) => ({
            ...prevTeamData,
            ringingType: savedNumberData?.ringingType,
            repeatCount: savedNumberData?.repeatCount,
            ringInterval: savedNumberData?.ringInterval,
            selectedTeamName: responseData?.data?.teamNameAssignedForThisCallFlow,
          }));
        }

        if (callFlowData) {
          const nodes = callFlowData?.nodes;
          const edges = callFlowData?.edges;
          setNodes(nodes);
          setEdges(edges);
          setCallFlowName(callFlowData?.callFlowName);
        }
      });
    };
    const featureName = getCurrentFeature(appProperties);
    if (featureName === "Message") {
      setUpgradeAlertPopup(true);
    } else if (voiceIntegId) {
      fetchConfigData();
    }
  }, [phoneNumber, voiceIntegId, fetchData]);

  useEffect(() => {
    if (Array.isArray(automatedMessagesObj)) {
      automatedMessagesObj?.forEach((message) => {
        const classification = message?.voiceAutomatedMessages?.classification;
        const nodeId = message?.voiceAutomatedMessages?.nodeId;
        const contentType = message?.voiceAutomatedMessages?.automatedMessageContentType;
        const file = message?.voiceAutomatedMessages?.automatedMessageName;
        const text = message?.voiceAutomatedMessages?.automatedMessage;
        if (classification === "WELCOME") {
          populateMusicDataBasedOnType(contentType, setWelcomeMusicData, welcomeMusicData, file, text, nodeId);
        } else if (classification === "RECORD") {
          populateMusicDataBasedOnType(contentType, setRecordData, recordData, file, text, nodeId);
        } else if (classification === "HOLD") {
          populateMusicDataBasedOnType(contentType, setPhoneNumberData, phoneNumberData, file, text, nodeId);
        } else if (classification === "WAITING") {
          populateMusicDataBasedOnType(contentType, setCallWaitingMusicData, callWaitingMusicData, file, text, nodeId);
        } else if (classification === "BUSY") {
          populateMusicDataBasedOnType(contentType, setBusyMusicData, businessHourData, file, text, nodeId);
        } else if (classification === "UNAVAILABLE") {
          populateMusicDataBasedOnType(contentType, setUnavailableMusicData, unavailableMusicData, file, text, nodeId);
        }
      });
    }
  }, [automatedMessagesObj]);
  const populateMusicDataBasedOnType = (contentType, setMusicData, musicData, file, text, nodeId) => {
    if (contentType === AUTOMATED_MESSAGE_TYPE.FILE) {
      setMusicData({
        ...musicData,
        selectType: "playAudio",
        fileName: file || "default.mp3",
        type: "Audio File",

        nodeId: nodeId,
      });
    } else if (contentType === AUTOMATED_MESSAGE_TYPE.URL) {
      setMusicData({
        ...musicData,
        selectType: "url",
        url: text,
        type: "URL",
        nodeId: nodeId,
      });
    } else if (contentType === AUTOMATED_MESSAGE_TYPE.TEXT) {
      setMusicData({
        ...musicData,
        selectType: "textToSpeech",
        text: text,
        type: "TTS",
        nodeId: nodeId,
      });
    }
  };

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onNodeDragStop = useCallback((event, node) => {}, []);
  const onDragOver = useCallback((event, node) => {
    event.preventDefault();
    event.dataTransfer.setData("application/reactflow", node?.id);
    event.dataTransfer.dropEffect = "move";
  }, []);

  const getDrawerContentByNodeType = (nodeType, id, data, nodeId, number) => {
    switch (nodeType) {
      case "phone_number":
        return {
          component: (
            <PhoneNumberNode
              integId={voiceIntegId}
              phoneNumberData={phoneNumberData}
              setPhoneNumberData={setPhoneNumberData}
              nodeId={nodeId}
              callFlowId={callFlowId}
            />
          ),
          title: "Phone Number",
          description: "Handle calls via phone number",
          titleStyle: "callActionIcons DrawerCallIcon ",
          descriptionStyle: "hs-fs-14 hs-fw-400",
        };

      case "business_hour":
        return {
          component: (
            <BusinessHour integId={voiceIntegId} businessHourData={businessHourData} setBusinessHourData={setBusinessHourData} number={number} />
          ),
          title: "Work Schedule",
          description: "Handle incoming calls for business hours",
          titleStyle: "callActionIcons DrawerHourIcon ",
          descriptionStyle: "hs-fs-14 hs-fw-400 ",
        };

      case "welcome_message":
        return {
          component: (
            <MusicUploadComponent
              integId={voiceIntegId}
              nodeId={nodeId}
              callFlowId={callFlowId}
              musicData={welcomeMusicData}
              setMusicData={setWelcomeMusicData}
            />
          ),
          title: "Play Audio/TTS",
          description: `Playing pre-recorded music during the phone call`,
          titleStyle: "DrawerPlayIcon",
          descriptionStyle: "hs-fs-14 hs-fw-400 ",
        };

      case "call_recording":
        return {
          component: (
            <CallRecording
              integId={voiceIntegId}
              recordData={recordData}
              setRecordData={setRecordData}
              nodeId={nodeId}
              callFlowId={callFlowId}
            />
          ),
          title: "Call Recording",
          description: "Record and save phone conversations",
          titleStyle: "callActionIcons DrawerHourIcon ",
          descriptionStyle: "hs-fs-14 hs-fw-400 ",
        };

      case "ring_team":
        return {
          component: <RingTeam integId={voiceIntegId} id={id} data={data} teamData={teamData} setTeamData={setTeamData} />,
          title: "Ring Team",
          description: "Ring multiple team members simultaneously for incoming calls.",
          titleStyle: "DrawerPlayIcon",
          descriptionStyle: "hs-fs-14 hs-fw-400 ",
        };

      // case "call_waiting":
      //   return {
      //     component: <MusicUploadComponent
      //       integId={integId}
      //       nodeId={nodeId}
      //       callFlowId={callFlowId}
      //       musicData={callWaitingMusicData}
      //       setMusicData={setCallWaitingMusicData}
      //     />,
      //     title: "Call Waiting",
      //     description: "Set up the call waiting message for the automated system.",
      //     titleStyle: "callActionIcons automatedMessageDrawerIcon me-2",
      //     descriptionStyle: "hs-fs-14 hs-fw-400 mt-1"
      //   };

      case "busy":
        return {
          component: (
            <MusicUploadComponent
              integId={voiceIntegId}
              nodeId={nodeId}
              callFlowId={callFlowId}
              musicData={busyMusicData}
              setMusicData={setBusyMusicData}
            />
          ),
          title: "Busy Message",
          description: "Set up the busy message for the automated system.",
          titleStyle: "callActionIcons automatedMessageDrawerIcon",
          descriptionStyle: "hs-fs-14 hs-fw-400 ",
        };
      case "unavailable":
        return {
          component: (
            <MusicUploadComponent
              integId={voiceIntegId}
              nodeId={nodeId}
              callFlowId={callFlowId}
              musicData={unavailableMusicData}
              setMusicData={setUnavailableMusicData}
            />
          ),
          title: "Unavailable Message",
          description: "Set up the busy message for the automated system.",
          titleStyle: "callActionIcons automatedMessageDrawerIcon",
          descriptionStyle: "hs-fs-14 hs-fw-400 ",
        };

      case "open_node":
      case "close_node":
      case "handle_svg":
      case "hang_up":
      case "no_answer":
        return {
          component: drawerContent,
          title: nodeTitle,
          description: nodeDescription,
          titleStyle: nodeTitleStyle,
          descriptionStyle: nodeDescriptionStyle,
        };
      default:
        return null;
    }
  };

  const handleNodeClick = useCallback((event, clickedNode) => {
    setClickedNodeId(clickedNode.id);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    const currentNode = nodes?.find((node) => node?.id === clickedNodeId);
    if (currentNode) {
      const typeOfClickedNode = currentNode?.type;
      const data = currentNode?.data;
      const nodeId = currentNode?.nodeId;

      const drawerData = getDrawerContentByNodeType(typeOfClickedNode, currentNode.id, data, nodeId, callFlowId);
      setDrawerContent(drawerData?.component);
      setNodeTitle(drawerData?.title);
      setNodeTitleStyle(drawerData?.titleStyle);
      setNodeDescription(drawerData?.description);
      setNodeDescriptionStyle(drawerData?.descriptionStyle);
    }
  }, [
    clickedNodeId,
    welcomeMusicData,
    phoneNumberData,
    busyMusicData,
    teamData.selectedTeamName,
    teamData.repeatCount,
    teamData.apply,
    teamData.ringInterval,
    teamData.ringingType,
    callWaitingMusicData,
    unavailableMusicData,
    businessHourData,
    recordData,
    busyMusicData,
    callWaitingMusicData,
    unavailableMusicData,
  ]);

  const nodeTypes = useMemo(
    () => ({
      phone_number: ({ data, selected }) => (
        <div className={`${selected || firstLoad ? "nodesBorder" : "border rounded"}`}>
          <Handle type="target" position={Position.Top} className="handleInvisible" />
          <CustomNode title={`Phone Number`} content={phoneNumber} iconClass="phoneNumberCallIcon ms-2" />
          <Handle type="source" position={Position.Bottom} className="handleVisible" />
        </div>
      ),
      business_hour: ({ data, selected }) => (
        <div className={`${selected ? "nodesBorder" : "border rounded"}`}>
          <Handle type="target" position={Position.Top} className="handleInvisible" />
          <CustomNode
            title="Business Hours"
            content={businessHourData.isCalendarHours ? "Calender" : "Custom"}
            iconClass="businessHourClockIcon ms-1 me-1"
          />
          <Handle type="source" position={Position.Bottom} className="handleVisible" />
        </div>
      ),
      handle_svg: () => (
        <div style={{ cursor: "auto" }} className="nonSelectable">
          <HandleSvg />
        </div>
      ),
      open_node: () => (
        <div style={{ cursor: "auto" }} className="nonSelectable">
          <OpenNode />
        </div>
      ),
      close_node: () => (
        <div style={{ cursor: "auto" }} className="nonSelectable">
          <ClosedNode />
        </div>
      ),
      welcome_message: ({ selected }) => (
        <div className={`${selected ? "nodesBorder" : "border rounded"}`}>
          <Handle type="target" position={Position.Top} className="handleInvisible" />
          <CustomNode title={`Welcome Message `} content={welcomeMusicData?.type} iconClass="vPlayIcon ms-2" />
          <Handle type="source" position={Position.Bottom} className="handleVisible" />
        </div>
      ),
      call_recording: ({ selected }) => (
        <div className={`${selected ? "nodesBorder" : "border rounded"}`}>
          <Handle type="target" position={Position.Top} className="handleInvisible" />
          <CustomNode title="Call Recording" content={recordData?.recordStatus === 1 ? "On" : "Off"} iconClass="vRecordingIcon ms-2" />
          <Handle type="source" position={Position.Bottom} className="handleVisible" />
        </div>
      ),
      ring_team: ({ selected, data }) => (
        <div className={`${selected ? "nodesBorder" : "border rounded"}`}>
          <Handle type="target" position={Position.Top} className="handleInvisible" />
          <CustomNode title={"Ring Team"} content={teamData?.selectedTeamName} iconClass="vRingIcon ms-2" />
          <Handle type="source" position={Position.Bottom} className="handleVisible" />
        </div>
      ),
      hang_up: () => (
        <div>
          <HangUp />
        </div>
      ),
      call_waiting: ({ selected }) => (
        <div className={`${selected ? "nodesBorder" : "border rounded"}`}>
          <Handle type="target" position={Position.Top} className="handleVisible" />
          <CustomNode title="Call Waiting" content={callWaitingMusicData.type} iconClass="automatedMessageNodeIcon ms-2" />
          <Handle type="source" position={Position.Bottom} className="handleVisible" />
        </div>
      ),
      busy: ({ selected }) => (
        <div className={`${selected ? "nodesBorder" : "border rounded"}`}>
          <Handle type="target" position={Position.Top} className="handleVisible" />
          <CustomNode title="Busy" content={busyMusicData.type} iconClass="automatedMessageNodeIcon ms-2" />
          <Handle type="source" position={Position.Bottom} className="handleVisible" />
        </div>
      ),
      unavailable: ({ selected }) => (
        <div className={`${selected ? "nodesBorder" : "border rounded"}`}>
          <Handle type="target" position={Position.Top} className="handleVisible" />
          <CustomNode title="Unavailable" content={unavailableMusicData.type} iconClass="automatedMessageNodeIcon ms-2" />
          <Handle type="source" position={Position.Bottom} className="handleVisible" />
        </div>
      ),
      no_answer: () => (
        <div style={{ cursor: "auto" }} className="nonSelectable">
          <NoAnswer />
        </div>
      ),
    }),
    [businessHourData, firstLoad, welcomeMusicData, teamData, callWaitingMusicData, busyMusicData, unavailableMusicData, recordData, phoneNumber]
  );

  return (
    <div className="dndflow h-auto w-100">
      <ReactFlowProvider>
        {" "}
        {/* Keep components using React Flow inside ReactFlowProvider */}
        <Row className="w-100 ">
          <Col span={18}>
            <Row className="hs-h-7">
              <Col span={24} className="hs-border-top-light hs-bg-white" style={{ zIndex: "10" }}>
                <FlowFooter
                  nodes={nodes}
                  initialPhoneNumberData={phoneNumberData}
                  initialBusinessHourData={businessHourData}
                  initialWelcomeMusicData={welcomeMusicData}
                  initialCallRecordingData={recordData}
                  initialCallWaitingMusicData={callWaitingMusicData}
                  initialTeamData={teamData}
                  initialBusyMusicData={busyMusicData}
                  initialUnavailableMusicData={unavailableMusicData}
                  callFlowId={callFlowId}
                  phoneNumber={phoneNumber}
                  callFlowName={callFlowName}
                  setCallFlowName={setCallFlowName}
                  integId={voiceIntegId}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* <FlowSideBar /> */}
                <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ height: "100vh", width: "100%" }}>
                  <ReactFlow
                    onPaneClick={() => setFirstLoad(false)}
                    nodes={nodes}
                    edges={edges}
                    edgeTypes={edgeTypes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    // onConnect={onConnect}
                    // onNodesChange={handleNodeClick}
                    onNodeClick={handleNodeClick}
                    nodeTypes={nodeTypes}
                    panOnScroll={false}
                    nodesDraggable={false}
                    preventScrolling={false}
                    zoomOnDoubleClick={false}
                    onInit={setReactFlowInstance}
                    onNodeDragStop={onNodeDragStop}
                    // onDrop={onDrop}
                    // onDragOver={onDragOver}
                    defaultViewport={defaultViewport}
                  >
                    {" "}
                  </ReactFlow>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={6} className="hs-bg-white hs-border-md-grey">
            <NodesDrawer
              data={drawerContent}
              title={nodeTitle}
              titleStyle={nodeTitleStyle}
              descriptionStyle={nodeDescriptionStyle}
              description={nodeDescription}
            />
          </Col>
        </Row>
        {upgradeAlertPopup && <UpgradeAlertPopup upgradeAlertPopup={upgradeAlertPopup} setUpgradeAlertPopup={setUpgradeAlertPopup} />}
      </ReactFlowProvider>
    </div>
  );
};

export default CallHandlingFlowChart;

// const ZoomLevelPanel = () => {
//   const { x, y, zoom } = useViewport();
//   return (
//     <div className="zoom-level-panel">
//       <p>
//         Zoom Level
//         {Math.round(zoom * 100)}
//         {/* {console.log(Math.round(zoom * 100), "zoom")} */}
//       </p>
//     </div>
//   );
// };
