import { Form } from "antd";
import ButtonGroup from "../input/button/ButtonGroup";
import Input from "../input/input/Input";
import Select from "../input/select/Select";

export default function GetVariableValues({
  handleOnSelect = () => {},
  fields,
  handleOnChange = () => {},
  getVariableValuesForm,
  okButtonProps,
  cancelButtonProps,
  selectSource,
}) {
  return (
    <div className="hs-mw-500-px p-lg-2">
      <Form form={getVariableValuesForm} className="d-grid gap-3">
        <Select
          label={"Select Fields"}
          placeHolder={"Select"}
          customProps={{
            placeholder: "Select Template",
            variant: "borderless",
            tabIndex: 3,
            onSelect: handleOnSelect,
          }}
          options={fields}
          name="selectedField"
          required={true}
          selectSource={selectSource}
        />
        <Input
          formItemLabel={"Enter default value"}
          formItemName={"defaultValue"}
          onChange={handleOnChange}
          required={true}
          inputHelper={{
            text: `Map fields for {{*}} params and set a default value to be used when the field value is empty.`,
            size: "s",
            type: "secondary",
          }}
        />
        <div className="d-flex justify-content-center">
          <ButtonGroup
            align="center"
            buttons={[
              {
                variant: "cancel",
                type: "secondary",
                children: cancelButtonProps?.buttonText,
                ...cancelButtonProps,
              },
              {
                children: okButtonProps?.buttonText,
                ...okButtonProps,
              },
            ]}
            size="l"
          />
        </div>
      </Form>
    </div>
  );
}
