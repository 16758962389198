import { message, Switch, TimePicker } from "antd";
import dayjs from "dayjs";
import React, { useContext } from "react";
import TimezoneSelect from "react-timezone-select";
import HButton from "../../../components/custom/input/button/Button";
import "../../assets/css/businessHours.css";
import HCheckBoxGroup from "../common/HCheckBoxGroup";
import { AppContext } from "../../../context/AppContext";

const BusinessHour = ({ id, data, number, businessHourData, setBusinessHourData, integId }) => {
  const format = "HH:mm";

  const [appProperties] = useContext(AppContext);
  const handleDayToggle = (day) => {
    setBusinessHourData((prevState) => ({
      ...prevState,
      businessDays: prevState?.businessDays?.map((item, index) =>
        index.toString() === day
          ? {
              ...item,
              isOpen: item?.isOpen ? 0 : 1,
            }
          : item
      ),
    }));
  };

  const handleStartChange = (day, time) => {
    setBusinessHourData((prevState) => ({
      ...prevState,
      businessDays: prevState?.businessDays.map((item, index) =>
        index?.toString() === day
          ? {
              ...item,
              hourStartTime: time?.format("HH"),
              hourStartMinute: time?.format("mm"),
            }
          : { ...item }
      ),
    }));
  };

  const handleEndChange = (day, time) => {
    setBusinessHourData((prevState) => ({
      ...prevState,
      businessDays: prevState?.businessDays?.map((item, index) =>
        index?.toString() === day
          ? {
              ...item,
              hourEndTime: time?.format("HH"),
              minuteEndTime: time?.format("mm"),
            }
          : item
      ),
    }));
  };

  const handleSubmit = async () => {
    const invalidDay = Object.keys(businessHourData?.businessDays || {}).find((day) => {
      const { hourStartTime, hourEndTime, minuteStartTime, minuteEndTime, isOpen } = businessHourData?.businessDays[day];
      if (isOpen && (!hourStartTime || !hourEndTime || !minuteStartTime || !minuteEndTime || hourStartTime === "00" || hourEndTime === "00")) {
        return true;
      }
      const startMinutes = parseInt(hourStartTime, 10) * 60 + parseInt(minuteStartTime, 10);
      const endMinutes = parseInt(hourEndTime, 10) * 60 + parseInt(minuteEndTime, 10);
      if (startMinutes >= endMinutes) {
        return true;
      }
      return false;
    });

    if (invalidDay) {
      message.warning("Please provide valid start and end times for all open business days.");
      return;
    }

    if (!businessHourData?.timeZone) {
      message.warning("Please select a TimeZone.");
      return;
    }

    if (!businessHourData?.businessDays || Object.keys(businessHourData?.businessDays).length === 0) {
      message.warning("Please provide Business Days Timing.");
      return;
    }
    setBusinessHourData((prevState) => ({
      ...prevState,

      apply: true,
    }));

    message.success("Applied successfully");
  };

  const onCalenderHoursChange = (e) => {
    setBusinessHourData((prevState) => ({
      ...prevState,
      isCalendarHours: e.target.value === "calendar",
      apply: true,
    }));
    if (e.target.value === "calendar") {
      message.success("Applied to calendar hours");
    }
  };

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const businessHourOption = [
    { value: "calendar", label: "Calendar Hours" },
    {
      value: "custom",
      label: "Custom",
    },
  ];

  return (
    <>
      <div className="h-100">
        <HCheckBoxGroup
          options={businessHourOption}
          checkedValue={businessHourData?.isCalendarHours ? "calendar" : "custom"}
          onChange={onCalenderHoursChange}
        />
        {console.log("businessHourData?.timeZone  userTimeZone>>>>>>>>>>>>>>", businessHourData?.timeZone)}
        {console.log("appProperties?.currentUser?.userTimeZone userTimeZone >>>>>>>>>>>>>>", appProperties?.currentUser?.userTimeZone)}
        {!businessHourData?.isCalendarHours && (
          <>
            <div style={{ marginTop: 20 }}>
              <label className="mb-2 mt-2">Time Zone</label>
              <TimezoneSelect
                className="time-zone-select"
                value={
                  businessHourData?.timeZone
                    ? businessHourData?.timeZone
                    : appProperties?.currentUser?.userTimeZone
                    ? appProperties?.currentUser?.userTimeZone
                    : null
                }
                onChange={(value) =>
                  setBusinessHourData((prevState) => ({
                    ...prevState,
                    timeZone: value.value,
                  }))
                }
              />
            </div>
            <div className="mt-4">
              {businessHourData?.businessDays &&
                Object.keys(businessHourData?.businessDays).map((dayIndex) => {
                  const day = dayNames[dayIndex];
                  return (
                    <div
                      key={day}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <span style={{ width: 50 }}>{day}</span>
                      <Switch
                        className="addPhoneSwitch-chatView"
                        size="small"
                        checked={businessHourData?.businessDays[dayIndex]?.isOpen === 1}
                        onChange={() => handleDayToggle(dayIndex)}
                      />
                      {businessHourData?.businessDays[dayIndex]?.isOpen === 1 && (
                        <>
                          <TimePicker
                            className="bh-timer-picker"
                            popupClassName="timePickerPopupPanel"
                            value={
                              businessHourData?.businessDays[dayIndex]?.hourStartTime &&
                              businessHourData?.businessDays[dayIndex]?.minuteStartTime
                                ? dayjs(
                                    `${businessHourData?.businessDays[dayIndex]?.hourStartTime}:${businessHourData?.businessDays[dayIndex]?.minuteStartTime}`,
                                    format
                                  )
                                : dayjs("09:00 ", format)
                            }
                            format={format}
                            style={{ marginLeft: 10, width: 110 }}
                            onChange={(time) => handleStartChange(dayIndex, time)}
                          />
                          <TimePicker
                            className="bh-timer-picker"
                            popupClassName="timePickerPopupPanel"
                            value={
                              businessHourData?.businessDays[dayIndex]?.hourEndTime && businessHourData?.businessDays[dayIndex]?.minuteEndTime
                                ? dayjs(
                                    `${businessHourData?.businessDays[dayIndex]?.hourEndTime || "12"}:${
                                      businessHourData?.businessDays[dayIndex]?.minuteEndTime || "00"
                                    }`,
                                    format
                                  )
                                : dayjs("17:00", format)
                            }
                            format={format}
                            style={{ marginLeft: 10, width: 110 }}
                            onChange={(time) => handleEndChange(dayIndex, time)}
                          />
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
            <HButton buttonClassName="mt-4" onClick={handleSubmit} size="s">
              Apply
            </HButton>
          </>
        )}
      </div>
    </>
  );
};

export default BusinessHour;
