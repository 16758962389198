import { Button, Modal as AntdModal, Space } from "antd";
import { config } from "../config";

const Modal = (props) => {
  const {
    mask,
    okButtonProps,
    cancelButtonProps,
    doOnClose = () => { },
    header,
    modalCloseIcon,
    children = <></>,
    contentSpaceSize = "large",
    rootClassName = "",
    modalContentClassName = "",
    alignButton = "end",
  } = props;

  const renderModal = (node) => {
    return <div className={rootClassName}>{node}</div>;
  };

  return (
    <AntdModal
      {...props}
      onCancel={doOnClose}
      centered
      closeIcon={null}
      mask={mask}
      maskClosable={false}
      footer={null}
      modalRender={renderModal}
    >
      {modalCloseIcon && (
        <div className="d-flex justify-content-between align-items-start ms-1 mb-1">
          <span className={config.modal.header.size[contentSpaceSize]}>{header}</span>
          <div onClick={doOnClose && doOnClose} className={"actionIconsSprite mediumSprite closeIconBig ms-2"} />
        </div>
      )}
      <Space size={contentSpaceSize} className={`w-100 h-100 modalContent ${modalContentClassName}`} direction="vertical">
        {children}
        {cancelButtonProps || okButtonProps ? (
          <div className={`d-flex justify-content-${alignButton} pb-3`}>
            <div className="d-flex align-items-center gap-4">
              {cancelButtonProps && (
                <Button
                  {...cancelButtonProps}
                  danger={cancelButtonProps?.danger ?? true}
                  className={`hs-border-10 lg-button hs-fs-16 px-3 ${cancelButtonProps?.className}`}
                >
                  <span className="px-3">{cancelButtonProps?.buttonText}</span>
                </Button>
              )}
              {okButtonProps && (
                <Button
                  {...okButtonProps}
                  className={`hs-bg-violet hs-border-10 lg-button text-white hs-fs-16 px-3 ${okButtonProps?.className}`}
                >
                  <span className="px-3">{okButtonProps?.buttonText}</span>
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </Space>
    </AntdModal>
  );
};

export default Modal;
