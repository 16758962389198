import { LoadingOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  Grid,
  Image,
  Input,
  List,
  notification,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
  TreeSelect,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import "../assets/css/conversationPage.css";
import MediaError from "../assets/images/mediaError.jpg";
import ComponentPreview from "../components/custom/ComponentPreview";
import HSButton from "../components/custom/input/button/Button";
import ReusableFilePreview from "../components/custom/ReusableFilePreview";
import MessagingAreaHeader from "../components/main/MessagingAreaHeader";
import {
  CREDIT_BASED_SRVICES,
  urlParams,
  VOICE_CHARGEBEE_PLAN_MONTHLY,
  VOICE_CHARGEBEE_PLAN_YEARLY,
  WHATSAPP_DISPLAY_NAME,
} from "../constants/AppConstants";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../constants/ProviderConstants";
import { AppContext } from "../context/AppContext";
import useHttp from "../hooks/useHttp";
import { useVoiceContext } from "../voice/context/VoiceContext";
import { initiateOutboundCall } from "../voice/utils/VoiceUtils";
import { ConvContext, DEFAULT_CONTACT_ID } from "./ConversationContext";
import { SavedTemplatesSelect } from "./outbound/SavedTemplatesSelect";
import { failureNotification, failureNotificationWithBtn } from "./utils/CommonNotifications";
import { checkTruthy, mapToMediaList, openServiceNotAuthorizedModal } from "./utils/commonUtils";
import { BuyFunds, ConfigurePhone, FromNumberComponent, RenderNameWithEllipsis, SendSMSToNumberComponent } from "./utils/CommonVessels";
const { Search } = Input;

function ConversationView(props) {
  const { chatView, toNumber, setHelloSend, fromSendSMSPage, phoneFieldConfiguration, setPhoneFieldConfiguration, setToNumberNumberInFormView } =
    props;
  const [appProperties] = useContext(AppContext);
  const { channel, sendsms, selectedContactId, conversationContext, setConversationContext } = useContext(ConvContext);
  const [numberList, setNumbersList] = useState([]);
  const [showDescription, setShowDescription] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [filterContact, setFilterContact] = useState("");
  const [services, setServices] = useState([]);
  const [showConversations, setShowConversations] = useState(false);
  const [isChannelPresent, setIsChannelPresent] = useState(services?.length > 0 ? true : false);
  const [serviceList, setServiceList] = useState([]);
  const [customerInfoObj, setCustomerInfoObj] = useState([]);
  const [conversationClassName, setConversationClassName] = useState();
  const [showChatOverlay, setShowChatOverlay] = useState(false);
  const [callProperties, setCallProperties] = useVoiceContext();
  const newToNumberInputRef = useRef();

  const { fetchData } = useHttp();

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (callProperties?.voicePlanRequestAccess) {
      api.open({
        message: "Call Capability Access Requested 🎉",
        description: "The call capability access request has been submitted. Our team will reach out to you via email shortly. ",
        showProgress: true,
        pauseOnHover: true,
      });

      setCallProperties((prev) => ({
        ...prev,
        voicePlanRequestAccess: false,
      }));
    }
  }, [callProperties?.voicePlanRequestAccess]);

  useEffect(() => {
    if (fromSendSMSPage) {
      setConversationClassName("conversationInbox hs-h-92");
    } else {
      setConversationClassName("conversationInbox pt-lg-2 px-lg-4");
    }
  }, [fromSendSMSPage]);
  useEffect(() => {
    if (services?.length < 1 && appProperties.apps && !chatView) {
      fetchData("omni/integrations?includeResources=true", "GET", null, appProperties)
        .then(function (response) {
          try {
            if (response) {
              response = JSON.parse(response);
              var servicesResp = response.data
                .filter((obj) => obj?.serviceInfo?.omniChannelCategory !== 4) // omni channel category 4 is voice
                .map((obj) => {
                  return {
                    value: obj?.serviceInfo.serviceId,
                    title: obj?.serviceName,
                    children: obj?.resource?.data?.map((data) => {
                      return {
                        value: data.phoneNumber,
                        title: data.phoneNumber,
                      };
                    }),
                  };
                });

              setServiceList(response?.data);
              setServices(servicesResp);
            }
          } catch (error) {
            console.error("Error processing response:", error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [appProperties?.apps, fetchData, services?.length]);

  const handleNumberSelect = (firstName, selectedNumber) => {
    setIsChannelPresent(true);
    setShowChatOverlay(true);
    setSelectedContact(selectedNumber);
    var getConversationObject = [];
    if (numberList.length > 0) {
      getConversationObject = numberList?.filter((obj) => obj?.orgCustomer === selectedNumber?.toNumber);
    }
    if (getConversationObject.length < 1 || selectedNumber.new) {
      var idObject = {
        firstName: firstName,
        orgCustomer: selectedNumber?.toNumber,
        avatar: selectedNumber?.avatar,
        sourceModule: selectedNumber?.sourceModule,
        sourceId: selectedNumber?.sourceId,
        new: "New",
      };
      setNumbersList((prevNumberList) => [{ ...idObject }, ...prevNumberList]);
    }
    if (firstName) {
      // setCustomerInfoObj((prev) => ({ ...prev, firstName: firstName }));
      setCustomerInfoObj((prev) => {
        return {
          ...prev,
          firstName: firstName,
        };
      });
    }
  };

  useEffect(() => {
    if (!chatView) {
      channel?.bind("CONTACTS_UPDATE", (data) => {
        console.log(">> CONTACTS_UPDATE >>>>>", data);
        if (data) {
          try {
            let response = JSON.parse(data?.data);
            setNumbersList((prev) => {
              if (response?.contactId === selectedContactId.current) {
                response.unreadCount = 0;
              }

              const existingIndex = prev?.findIndex((item) => item?.contactId === response?.contactId);
              if (existingIndex !== -1) {
                const newList = [...prev];
                newList[existingIndex] = response;
                newList.unshift(newList.splice(existingIndex, 1)[0]);
                return newList;
              } else {
                return [response, ...(prev || [])];
              }
            });
          } catch (e) {
            console.error("ERROR::::", e);
          }
        }
      });
      return () => {
        channel?.unbind("CONTACTS_UPDATE");
      };
    }
  }, [channel, chatView]);

  return (
    <>
      {contextHolder}
      {appProperties?.osyncId && (
        <>
          <div className={conversationClassName}>
            <Row className={`hs-border-dark-grey overflow-lg-hidden hs-max-h-100vh ${chatView ? "h-100" : "hs-h-91-dvh"}`}>
              {!chatView && (
                <Col xs={24} xl={8} md={8} className="hs-border-right-light h-100">
                  <ListView
                    setSelectedContact={setSelectedContact}
                    services={services}
                    setShowDescription={setShowDescription}
                    numberList={numberList}
                    selectedContact={selectedContact}
                    handleNumberSelect={handleNumberSelect}
                    setIsChannelPresent={setIsChannelPresent}
                    filterContact={filterContact}
                    setFilterContact={setFilterContact}
                    setNumbersList={setNumbersList}
                    appProperties={appProperties}
                    setCustomerInfoObj={setCustomerInfoObj}
                    selectedContactId={selectedContactId}
                    showConversations={showConversations}
                    setShowConversations={setShowConversations}
                    showDescription={showDescription}
                    newToNumberInputRef={newToNumberInputRef}
                    setShowChatOverlay={setShowChatOverlay}
                  />
                </Col>
              )}
              <Col
                xs={showChatOverlay || chatView ? 24 : 0}
                xl={chatView ? 24 : 16}
                md={chatView ? 24 : 16}
                className={`${chatView ? "w-100 h-100" : "page-overlay"}`}
              >
                <DetailView
                  selectedContactId={selectedContactId}
                  chatView={chatView}
                  channel={channel}
                  selectedContact={selectedContact}
                  serviceList={serviceList}
                  isChannelPresent={isChannelPresent}
                  services={services}
                  handleNumberSelect={handleNumberSelect}
                  from={selectedContact?.fromNumber}
                  toNumber={toNumber || selectedContact?.toNumber}
                  setHelloSend={setHelloSend}
                  associatedIntegID={selectedContact?.integId}
                  setNumbersList={setNumbersList}
                  numberList={numberList}
                  omni={true}
                  customerInfoObj={customerInfoObj}
                  setCustomerInfoObj={setCustomerInfoObj}
                  sendsms={sendsms}
                  showConversations={chatView ? true : showConversations}
                  setShowConversations={setShowConversations}
                  showDescription={showDescription}
                  phoneFieldConfiguration={phoneFieldConfiguration}
                  setPhoneFieldConfiguration={setPhoneFieldConfiguration}
                  setToNumberNumberInFormView={setToNumberNumberInFormView}
                  newToNumberInputRef={newToNumberInputRef}
                  setConversationContext={setConversationContext}
                  conversationContext={conversationContext}
                  setShowChatOverlay={setShowChatOverlay}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default ConversationView;

export const DetailView = React.memo(
  ({
    isChannelPresent,
    services,
    handleNumberSelect,
    toNumber,
    setNumbersList,
    numberList,
    omni,
    from,
    selectedContact,
    serviceList,
    customerInfoObj,
    setCustomerInfoObj,
    channel,
    associatedIntegID,
    chatView,
    setHelloSend,
    sendsms,
    selectedContactId,
    showConversations,
    setShowConversations,
    showDescription,
    phoneFieldConfiguration,
    setPhoneFieldConfiguration,
    setToNumberNumberInFormView,
    newToNumberInputRef,
    conversationContext,
    setConversationContext,
    setShowChatOverlay,
  }) => {
    const [appProperties, setAppProperties] = useContext(AppContext);
    const [searchContact, setSearchContact] = useState("");
    const [contactList, setContactList] = useState([]);
    const { fetchData } = useHttp();
    const scrollableDivRef = useRef(null);
    const inboxDivPaginationRef = useRef(null);
    const isInboxPaginationDoneRef = useRef(null);
    const isFromFetchApi = useRef(null);
    const messageBodyRef = useRef(null);
    const navigate = useNavigate();
    const [showConversationsChat, setShowConversationsChat] = useState(false);
    const [newFromNumber, setNewFromNumber] = useState();
    const [integId, setIntegId] = useState();
    const [savedNumbersList, setSavedNumbersList] = useState();
    const [showChatView, setShowChatView] = useState(false);
    const [fromNumberFlag, setFromNumberFlag] = useState(false);
    const [searchDropdownLoading, setSearchDropdownLoading] = useState(false);
    const [showAddBtn, setShowAddBtn] = useState(false);
    const [notFoundContent, setNotFoundContent] = useState(null);
    const [toNumberChatView, setToNumberChatView] = useState();
    const [contactInfo, setContactInfo] = useState(null);
    const [fetchConversationLoading, setFetchConversationLoading] = useState(false);
    const [channelRedirectUrl, setChannelRedirectUrl] = useState(null);
    const [debouncedSearchContact] = useDebounce(searchContact, 400);
    const previousScrollHeight = useRef(null);
    const balanceCredits =
      appProperties?.licenseObj?.licenseDetails?.creditsPurchased - appProperties?.licenseObj?.licenseDetails?.creditsConsumed;
    const { conversations = [], unReadMessages = [], previewResponse } = conversationContext?.[selectedContactId.current] || {};
    useEffect(() => {
      if (appProperties?.controller?.getUrlParamsForRedirection) {
        appProperties?.controller
          ?.getUrlParamsForRedirection(true, appProperties)
          .then(function (respUrl) {
            setChannelRedirectUrl(respUrl);
          })
          .catch((err) => console.log(err));
      }
    }, [appProperties, appProperties?.controller]);

    const getUrlForFetchConversations = (integ, fromFlag) => {
      let targetUrl;
      if (showChatView) {
        targetUrl =
          "channels/" +
          (integ || associatedIntegID) +
          "/conversationsList/" +
          encodeURIComponent(chatView ? toNumberChatView : toNumber) +
          "?channelName=" +
          encodeURIComponent(fromFlag ? from : newFromNumber);
      } else {
        targetUrl =
          "channels/" +
          (integ || associatedIntegID) +
          "/conversations/all?orgMember=" +
          encodeURIComponent(fromFlag ? from : newFromNumber) +
          "&orgCustomer=" +
          encodeURIComponent(chatView ? toNumberChatView : toNumber) +
          "&leftServiceId=" +
          appProperties?.leftServiceId;
      }

      if (inboxDivPaginationRef?.current?.lastKey) {
        if (selectedContactId?.current && selectedContactId?.current !== DEFAULT_CONTACT_ID) {
          targetUrl += "&contactId=" + selectedContactId.current;
        }
        targetUrl += "&lastKey=" + encodeURIComponent(inboxDivPaginationRef?.current?.lastKey ?? "");
      }
      return targetUrl;
    };

    useEffect(() => {
      if (toNumber && !chatView) {
        setToNumberChatView(toNumber);
        if (setToNumberNumberInFormView) {
          setToNumberNumberInFormView(toNumber);
        }
      } else if (toNumberChatView) {
        setToNumberChatView(toNumberChatView);

        if (setToNumberNumberInFormView) {
          setToNumberNumberInFormView(toNumberChatView);
        }
      }
    }, [toNumber, toNumberChatView]);

    async function fetchByList(integ, fromFlag, fromFetchApi) {
      if (!fromFetchApi && (fetchConversationLoading || isInboxPaginationDoneRef?.current)) {
        return;
      }

      if (fromFetchApi) {
        isInboxPaginationDoneRef.current = null;
      }

      setFetchConversationLoading(true);
      var currentContactId = selectedContactId.current;
      isFromFetchApi.current = fromFetchApi;
      await fetchData(getUrlForFetchConversations(integ, fromFlag), "GET", null, appProperties)
        .then(function (response) {
          try {
            if (response) {
              response = JSON.parse(response);
              let conversations = response?.data?.data?.messages;
              inboxDivPaginationRef.current = response?.data?.data?.pagination;
              let unreads = [];
              let reads = [];

              if (conversations?.length === 0 || !checkTruthy(response?.data?.data?.pagination)) {
                isInboxPaginationDoneRef.current = true;
              } else {
                isInboxPaginationDoneRef.current = null;
              }
              let contact = response?.data?.data?.participants?.find((obj) => obj?.role === "source_user");
              setContactInfo(contact);
              if (contact?.contactId && (chatView || currentContactId === DEFAULT_CONTACT_ID)) {
                selectedContactId.current = currentContactId = contact?.contactId;
              }

              conversations.forEach((conversationObj, index) => {
                if (index < conversations?.length - (customerInfoObj?.unreadCount ?? unReadMessages?.length ?? 0)) {
                  reads.push(conversationObj);
                } else {
                  if (conversationObj?.direction === 1) {
                    unreads.push({
                      ...conversationObj,
                      isUnreadedMessage: true,
                    });
                  } else {
                    unreads = [];
                    reads.push(conversationObj);
                  }
                }
              });
              setConversationContext((prev) => ({
                ...prev,
                [selectedContactId.current]: {
                  ...prev[selectedContactId.current],
                  conversations: fromFetchApi ? reads : [...reads, ...(prev[selectedContactId.current]?.conversations || [])],
                  unReadMessages: unreads,
                },
              }));

              setShowConversationsChat(true);
              setShowConversations(true);
              if (!chatView) {
                messageBodyRef.current.focus();
              }
            }
          } catch (error) {
            console.error("Error processing response:", error);
            setConversationContext((prev) => ({
              ...prev,
              [selectedContactId.current]: { ...prev[selectedContactId.current], conversations: [], unReadMessages: [] },
            }));
            setShowConversationsChat(true);
            setShowConversations(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowConversationsChat(true);
          setShowConversations(true);
        })
        .finally(() => {
          setFetchConversationLoading(false);
        });
    }

    useEffect(() => {
      const fetchApi = async () => {
        setShowConversationsChat(false);
        let savedNumbers;
        if (!savedNumbersList) {
          var phoneObj = appProperties?.savedNumbers?.filter((obj) => obj?.isMessagingService !== "true");
          var msgObj = appProperties?.savedNumbers?.filter((obj) => obj?.isMessagingService === "true");
          if (msgObj?.length > 0) {
            phoneObj = [
              ...(phoneObj || []),
              {
                label: (
                  <Divider plain style={{ margin: 0, fontSize: "12px" }} orientation="left">
                    Message Service ID
                  </Divider>
                ),
                value: "divider",
                disabled: true,
              },
              ...msgObj,
            ];
          }
          setSavedNumbersList(phoneObj);
          savedNumbers = phoneObj;
          if (chatView) {
            const defaultPhoneNumber =
              phoneObj.find((obj) => obj?.defaultNumber === 1)?.phoneNumber ||
              (phoneObj[0]?.value === "divider" ? phoneObj[1]?.phoneNumber : phoneObj[0]?.phoneNumber);
            setNewFromNumber(defaultPhoneNumber);
          } else if (phoneObj?.length === 1) {
            setNewFromNumber(phoneObj?.[0]?.phoneNumber);
          }
        }
        let list = savedNumbers || savedNumbersList;
        if (list?.length === 1) {
          setNewFromNumber(list?.[0]?.phoneNumber);
        }
        var integ;

        if (customerInfoObj?.displayName?.startsWith("Facebook") || customerInfoObj?.displayName?.startsWith("Instagram")) {
          integ = customerInfoObj?.integId;
          setIntegId(integ);
        } else {
          integ = list?.find((entry) => entry.phoneNumber === from)?.integId;
        }
        let fromFlag;
        if (!integ) {
          integ = list?.find((entry) => entry.phoneNumber === newFromNumber)?.integId;
          if (integ) {
            fromFlag = false;
            setFromNumberFlag(false);
          } else {
            fromFlag = true;
          }
        } else {
          setFromNumberFlag(true);
          fromFlag = true;
        }
        if (!from || !integ) {
          setConversationContext((prev) => ({
            ...prev,
            [selectedContactId.current]: { ...prev[selectedContactId.current], conversations: [], unReadMessages: [] },
          }));
        }
        setIntegId(integ);
        if ((toNumberChatView || toNumber) && (from || newFromNumber)) {
          setShowConversationsChat(false);
          setIntegId(integ);
          await fetchByList(integ, fromFlag, true);
        } else {
          setShowConversationsChat(true);
        }
      };
      if (appProperties && appProperties.apps) {
        fetchApi();
      }
      if (setHelloSend) {
        setHelloSend(savedNumbersList?.find((obj) => obj?.phoneNumber === newFromNumber)?.isHelloSendPhoneNumber === "true" ? true : false);
      }
      return () => {
        scrollableDivRef.current = null;
        inboxDivPaginationRef.current = null;
        isFromFetchApi.current = null;
      };
    }, [
      fetchData,
      chatView,
      associatedIntegID,
      showChatView,
      savedNumbersList,
      setHelloSend,
      appProperties.serviceInstalled,
      from,
      toNumber,
      toNumberChatView,
      newFromNumber,
    ]);

    useEffect(() => {
      const scrollableDiv = scrollableDivRef.current;
      if (scrollableDiv && isFromFetchApi.current) {
        scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      } else if (scrollableDiv && previousScrollHeight?.current) {
        const newScrollHeight = scrollableDiv.scrollHeight;
        const scrollDelta = newScrollHeight - previousScrollHeight.current;

        if (scrollDelta > 0) {
          scrollableDiv.scrollTop = scrollDelta;
        }
      }
    }, [conversations]);

    useEffect(() => {
      if (debouncedSearchContact?.length > 2 && appProperties.serviceInstalled) {
        setSearchDropdownLoading(<div className="actionIconsSprite search-loading"></div>);
        fetchData(
          "omessage/" + appProperties?.randomIntegId + "/search?searchText=" + encodeURIComponent(debouncedSearchContact) + "&module=ALL",
          "GET",
          null,
          appProperties
        )
          .then(function (response) {
            try {
              if (response) {
                response = JSON.parse(response);
                if (response?.data?.data) {
                  let data = response?.data?.data;
                  setContactList(data);
                  if (data.length > 0) {
                    setShowAddBtn(false);
                    setNotFoundContent(null);
                  } else {
                    setShowAddBtn(true);
                    setNotFoundContent(<div className="d-flex align-items-center ms-2 h-100 hs-h-55-px">No matches found.</div>);
                  }
                }
                setSearchDropdownLoading(null);
              }
            } catch (error) {
              console.error("Error processing response:", error);
              setSearchDropdownLoading(null);
            }
          })
          .catch((error) => {
            console.log("Error processing response:", error);
            setSearchDropdownLoading(null);
          });
      } else {
        setNotFoundContent(null);
      }
    }, [debouncedSearchContact, appProperties.serviceInstalled, fetchData, appProperties]);
    const handleSelectContact = (e) => {
      let contact = contactList?.filter((obj) => obj?.lastName === e);
      let queryObj = numberList ?? numberList?.find((obj) => obj?.orgCustomer === contact[0]?.phone);
      handleNumberSelect(contact[0]?.lastName, {
        fromNumber: queryObj?.orgMember,
        toNumber: contact[0]?.phone,
        sourceModule: contact[0]?.module,
        sourceId: contact[0]?.id,
        avatar: contact[0]?.avatar,
        integId: queryObj?.integId,
        new: true,
      });
    };
    const updateUnreadMessages = useCallback(() => {
      if (unReadMessages?.length > 0) {
        isFromFetchApi.current = true;
        setConversationContext((prev) => ({
          ...prev,
          [selectedContactId.current]: {
            ...prev[selectedContactId.current],
            conversations: [
              ...(prev[selectedContactId.current]?.conversations || []),
              ...(prev[selectedContactId.current]?.unReadMessages || []),
            ],
            unReadMessages: [],
          },
        }));
        setNumbersList((prev) => {
          const newNumbersList = prev.map((item) => {
            if (item?.orgMember === selectedContact?.fromNumber && item?.orgCustomer === selectedContact?.toNumber) {
              return {
                ...item,
                unreadCount: 0,
              };
            } else {
              return item;
            }
          });
          return newNumbersList;
        });
        setCustomerInfoObj((prev) => ({ ...prev, unreadCount: 0 }));
      }
    }, [selectedContact?.fromNumber, selectedContact?.toNumber, selectedContactId, unReadMessages]);
    const handleSendSmsResp = (response, newUUID, idObject) => {
      var newContact;
      const contactId = response.data?.contact?.contactId || selectedContactId.current;
      if (!conversations || conversations.length < 1) {
        newContact = true;
      }
      if (response.data.status === true) {
        const updatedList = numberList?.map((item) => {
          if (
            item.contactId === response.data?.contact?.contactId ||
            (item.orgCustomer === idObject.id && item.orgMember === idObject.sender_id) ||
            (item.orgCustomer === idObject.id && item.new)
          ) {
            if ((item.orgCustomer === idObject.id && item.new && !conversations) || conversations.length < 1) {
              newContact = true;
            }
            return { ...item, ...response.data?.contact, new: false };
          } else {
            return item;
          }
        });
        if (newContact) {
          selectedContactId.current = response?.data?.contact?.contactId;
        }
        if (contactId === selectedContactId?.current || newContact || chatView) {
          setCustomerInfoObj((prev) => ({ ...prev, displayName: response?.data?.contact?.sourceFrom }));
          isFromFetchApi.current = true;
          setConversationContext((prev) => {
            let existingIndex = prev[contactId]?.conversations?.findIndex((item) => item?.tempMessageId === newUUID);
            if (!existingIndex) {
              existingIndex = 0;
            }

            if (existingIndex !== -1) {
              const newList = newContact ? prev[DEFAULT_CONTACT_ID]?.conversations : prev[contactId]?.conversations;
              if (!newList) {
                return prev;
              }
              const messageData = {
                message: newList?.[existingIndex]?.message,
                components: newList?.[existingIndex]?.components,
                id: response.data?.messageId,
                reply_by: response.data?.reply_by,
                sender_url: response.data?.sender_url,
                createdTime: newList?.[existingIndex]?.createdTime,
                attachments: newList?.[existingIndex]?.attachments,
                direction: 2,
                messageStatus: "sent",
              };
              newList[existingIndex] = messageData;
              if (newContact) {
                const { [DEFAULT_CONTACT_ID]: _, ...rest } = prev;
                return {
                  ...rest,
                  [contactId]: {
                    ...prev[contactId],
                    conversations: newList,
                  },
                };
              }

              return {
                ...prev,
                [contactId]: {
                  ...prev[contactId],
                  conversations: newList,
                },
              };
            } else {
              return {
                ...prev,
                [contactId]: {
                  ...prev[newContact ? DEFAULT_CONTACT_ID : contactId],
                  conversations: [...(prev[newContact ? DEFAULT_CONTACT_ID : contactId]?.conversations ?? []), response?.data],
                },
              };
            }
          });
        }
        setNumbersList(updatedList);
      } else {
        if (response?.data) {
          if (response?.data?.license?.code) {
            response.data.data = response?.data?.license?.description;
            response.data.errorCode = response?.data?.license?.code;
          }
          if (newContact) {
            selectedContactId.current = contactId;
          }
          isFromFetchApi.current = true;
          setConversationContext((prev) => ({
            ...prev,
            [contactId]: {
              ...prev[newContact ? DEFAULT_CONTACT_ID : contactId],
              conversations: (prev[newContact ? DEFAULT_CONTACT_ID : contactId]?.conversations || []).map((conversation) => {
                if (conversation.tempMessageId === newUUID) {
                  return {
                    ...conversation,
                    direction: 2,
                    errorMessage: response?.data?.data || response?.data?.errorMessage,
                    errorCode: response?.data?.errorCode,
                    messageStatus: "failed",
                  };
                }
                return conversation;
              }),
            },
          }));
          const updatedList = numberList?.map((item) => {
            if (
              item?.contactId === response.data?.contact?.contactId ||
              (item?.orgCustomer === idObject.id && item?.orgMember === idObject.sender_id) ||
              (item?.orgCustomer === idObject.id && item?.new)
            ) {
              selectedContactId.current = response.data?.contact?.contactId;
              return response.data?.contact || item;
            } else {
              return item;
            }
          });
          setNumbersList(updatedList);
        }
      }
      if (response.data?.omniLicense) {
        setAppProperties((prev) => ({
          ...prev,
          licenseObj: {
            ...prev.licenseObj,
            licenseDetails: response.data?.omniLicense,
          },
        }));
      }
    };

    const handlePreviewResponse = (response) => {
      setConversationContext((prev) => ({
        ...prev,
        [selectedContactId.current]: { ...prev[selectedContactId.current], previewResponse: response },
      }));
    };

    return (
      <Flex vertical={true} justify="space-between" className={`bg-white h-100`}>
        {showConversations ? (
          <>
            {isChannelPresent || !omni || chatView ? (
              <>
                {chatView ? (
                  <ChatViewHeader
                    appProperties={appProperties}
                    newFromNumber={newFromNumber}
                    setNewFromNumber={setNewFromNumber}
                    savedNumbersList={savedNumbersList}
                    setSavedNumbersList={setSavedNumbersList}
                    setToNumberChatView={setToNumberChatView}
                    phoneFieldConfiguration={phoneFieldConfiguration}
                    setPhoneFieldConfiguration={setPhoneFieldConfiguration}
                    selectedContactId={selectedContactId}
                  />
                ) : (
                  <ConversationHeader
                    savedNumbersList={savedNumbersList}
                    customerInfoObj={customerInfoObj}
                    toNumber={toNumber}
                    showChatView={showChatView}
                    setShowChatView={setShowChatView}
                    newFromNumber={newFromNumber}
                    from={from}
                    setShowChatOverlay={setShowChatOverlay}
                  />
                )}
                {showConversationsChat ? (
                  <>
                    {conversations?.length > 0 || unReadMessages?.length > 0 ? (
                      <ConversationMessages
                        setConversationContext={setConversationContext}
                        sendsms={sendsms}
                        updateUnreadMessages={updateUnreadMessages}
                        conversations={conversations}
                        unReadMessages={unReadMessages}
                        customerInfoObj={contactInfo || customerInfoObj}
                        scrollableDivRef={scrollableDivRef}
                        integId={integId}
                        appProperties={appProperties}
                        handleSendSmsResp={handleSendSmsResp}
                        toNumberChatView={toNumberChatView}
                        toNumber={toNumber}
                        balanceCredits={balanceCredits}
                        chatView={chatView}
                        fetchByList={fetchByList}
                        previousScrollHeight={previousScrollHeight}
                        fetchConversationLoading={fetchConversationLoading}
                        selectedContactId={selectedContactId}
                        channelRedirectUrl={channelRedirectUrl}
                        previewResponse={previewResponse}
                      />
                    ) : (
                      <NoConversations
                        channelRedirectUrl={channelRedirectUrl}
                        navigate={navigate}
                        chatView={chatView}
                        appProperties={appProperties}
                      />
                    )}
                  </>
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center m-auto">
                    <LoadingOutlined
                      spin
                      style={{
                        fontSize: 24,
                        color: "#605BFF",
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="inbox-header hs-border-bottom-light d-flex align-items-center">
                  <div className="container d-flex hs-w-95  align-items-center">
                    <div
                      className="d-md-none pe-2"
                      onClick={() => {
                        setShowChatOverlay(false);
                      }}
                    >
                      <div className="actionIconsSprite back-icon" />
                    </div>
                    <span>To:</span>
                    <Select
                      suffixIcon={<span>{searchDropdownLoading}</span>}
                      tabIndex={3}
                      notFoundContent={notFoundContent}
                      showSearch
                      onSelect={handleSelectContact}
                      onSearch={(e) => {
                        setSearchContact(e);
                      }}
                      onBlur={() => {
                        setContactList([]);
                      }}
                      ref={newToNumberInputRef}
                      className="hs-focus-border"
                      filterOption={false}
                      variant="borderless"
                      style={{ minWidth: 250 }}
                      placeholder={`Enter a name or phone number to search from ${appProperties?.leftServiceDisplayName}`}
                      dropdownStyle={{ minHeight: "3rem", alignItems: "center" }}
                    >
                      {contactList && (
                        <>
                          {Array.from(new Set(contactList.map((obj) => obj?.module))).map((module) => (
                            <Select.OptGroup
                              key={module}
                              label={
                                <Tag
                                  color="#F5F4FF"
                                  style={{
                                    color: "#605bff",
                                    borderRadius: 12,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {module}
                                </Tag>
                              }
                            >
                              {contactList
                                .filter((obj) => obj?.module === module)
                                .map((obj, index) => (
                                  <Select.Option key={`${module}-${index}`} value={obj?.lastName}>
                                    {obj?.lastName}
                                  </Select.Option>
                                ))}
                            </Select.OptGroup>
                          ))}
                        </>
                      )}
                    </Select>
                    {showAddBtn && debouncedSearchContact && /^\+?\d+$/.test(debouncedSearchContact) && (
                      <div className="hs-w-fit p-0">
                        <HSButton
                          size="s"
                          type="primary"
                          prefixIcon={<div className="actionIconsSprite plusIconWhite" />}
                          onClick={() => {
                            if (debouncedSearchContact && debouncedSearchContact !== "") {
                              handleNumberSelect(null, {
                                fromNumber: null,
                                toNumber: debouncedSearchContact,
                                new: true,
                              });
                              if (!chatView && messageBodyRef.current) {
                                messageBodyRef.current.focus();
                              }
                            }
                          }}
                        >
                          Add
                        </HSButton>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-grow-* flex-column align-items-center justify-content-center h-100">
                  <div className="staticIconsSprite chatIcon-sprite"></div>
                  {!appProperties?.serviceInstalled ? (
                    <div className="d-flex flex-column align-items-center noConvo-info text-center hs-max-w-300-px">
                      <span
                        style={{
                          fontSize: chatView ? 14 : 16,
                          color: "#818094",
                        }}
                      >
                        Stay connected with your customers.{" "}
                      </span>
                      <span
                        style={{
                          fontSize: chatView ? 12 : 14,
                          color: "#818094",
                        }}
                      >
                        Connect your channel and begin typing a contact name or number above to initiate a new message.{" "}
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center noConvo-info text-center hs-max-w-300-px">
                      <span
                        style={{
                          fontSize: chatView ? 14 : 16,
                          color: "#818094",
                        }}
                      >
                        Stay connected with your customers.{" "}
                      </span>
                      {!chatView && (
                        <span
                          style={{
                            fontSize: chatView ? 12 : 14,
                            color: "#818094",
                          }}
                        >
                          Begin typing a contact name or number above to initiate a new message.
                        </span>
                      )}
                    </div>
                  )}
                  {!appProperties?.serviceInstalled ? (
                    <Button
                      className="rounded m-2 addChannel-btn"
                      onClick={() => {
                        const textSmsUrl = "/channels/textsms?" + channelRedirectUrl;
                        if (chatView) {
                          window.open(textSmsUrl, "_blank");
                        } else {
                          navigate(textSmsUrl);
                        }
                      }}
                      type=""
                    >
                      Add Channels
                    </Button>
                  ) : null}
                </div>
              </>
            )}
            {(services?.length > 0 && showConversations && toNumber) || chatView || !omni ? (
              <>
                {(conversations || unReadMessages) &&
                  !(conversations?.length > 0 || unReadMessages?.length > 0) &&
                  (from?.includes("whatsapp") || newFromNumber?.includes("whatsapp")) &&
                  !fetchConversationLoading &&
                  !searchDropdownLoading && (
                    <div className="hs-min-h-2-5-rem mb-2 overflow-hidden d-flex justify-content-center">
                      <div
                        key={`${toNumber}:${from}:${newFromNumber}`}
                        className={`p-1 hs-border-5 hs-bg-violet hs-slide-top text-white hs-h-2-5-rem hs-w-95`}
                      >
                        <Row className="ms-2" justify={"start"} align={"middle"}>
                          <Col md={1} sm={2}>
                            <div className="d-flex justify-content-center">
                              <div className="staticIconsSprite alert-icon-white"></div>
                            </div>
                          </Col>
                          <Col span={21}>
                            <div className="ms-1">
                              <span className="my-auto hs-fw-500">
                                The first message must be a pre-approved {WHATSAPP_DISPLAY_NAME} template.
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                <MessageArea
                  setConversationContext={setConversationContext}
                  conversationContext={conversationContext}
                  handleSendSmsResp={handleSendSmsResp}
                  appProperties={appProperties}
                  unReadMessages={unReadMessages}
                  customerInfoObj={customerInfoObj}
                  updateUnreadMessages={updateUnreadMessages}
                  serviceList={serviceList}
                  toNumberChatView={toNumberChatView}
                  integId={integId}
                  messageBodyRef={messageBodyRef}
                  contactList={contactList}
                  conversations={conversations}
                  numberList={numberList}
                  fromNumberFlag={fromNumberFlag}
                  from={from}
                  toNumber={toNumber}
                  setNumbersList={setNumbersList}
                  newFromNumber={newFromNumber}
                  setNewFromNumber={setNewFromNumber}
                  chatView={chatView}
                  savedNumbersList={savedNumbersList}
                  setSavedNumbersList={setSavedNumbersList}
                  sendsms={sendsms}
                  channel={channel}
                  contactId={selectedContactId}
                  balanceCredits={balanceCredits}
                  isFromFetchApi={isFromFetchApi}
                  previewResponse={previewResponse}
                  setPreviewResponse={handlePreviewResponse}
                  handlePreviewResponse={handlePreviewResponse}
                  fetchConversationLoading={fetchConversationLoading}
                />
              </>
            ) : null}
          </>
        ) : null}
      </Flex>
    );
  }
);
const MessageArea = ({
  integId,
  messageBodyRef,
  appProperties,
  chatView,
  from,
  serviceList,
  customerInfoObj,
  conversations,
  setNumbersList,
  numberList,
  fromNumberFlag,
  contactList,
  savedNumbersList,
  setSavedNumbersList,
  toNumber,
  sendsms,
  newFromNumber,
  updateUnreadMessages,
  toNumberChatView,
  setNewFromNumber,
  unReadMessages,
  channel,
  contactId,
  handleSendSmsResp,
  balanceCredits,
  isFromFetchApi,
  previewResponse,
  setPreviewResponse,
  source,
  fetchConversationLoading,
  conversationContext,
  setConversationContext,
  handlePreviewResponse,
}) => {
  const { fetchData } = useHttp();
  const [templateIdRef, setTemplateIdRef] = useState(null);
  const [sendBtnLoading, setSendBtnLoading] = useState(false);
  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  const [resource, setResource] = useState("");
  const [templateOpen, setTemplateOpen] = useState(false);
  const [messageHeaderTemplateOpen, setMessageHeaderTemplateOpen] = useState(false);
  const perRowHeightApprox = 25;
  const messageTextAreaContentRef = useRef(null);
  const antIcon = <LoadingOutlined className="loading-button" spin />;
  const { message = "", templateId = null, fileList = [] } = conversationContext?.[contactId.current] || {};

  function updateConversationContext(updates) {
    setConversationContext((prev) => ({
      ...prev,
      [contactId.current]: {
        ...prev?.[contactId.current],
        ...updates,
      },
    }));
  }

  useEffect(() => {
    updateConversationContext({ templateId: templateIdRef });
  }, [contactId, templateIdRef]);

  function handleMessage(messageText, event) {
    if (!templateOpen && messageText?.trim?.() === "/") {
      setTemplateOpen(true);
    } else if (templateOpen) {
      setTemplateOpen(false);
    }

    setConversationContext((prev) => ({
      ...prev,
      [contactId.current]: {
        ...prev?.[contactId.current],
        message: messageText?.native ? (prev?.[contactId.current]?.message ?? "") + messageText?.native : messageText,
      },
    }));
  }

  function handleMedia(mediaFile) {
    const fileList = mediaFile ? mapToMediaList(mediaFile, false) : [];
    updateConversationContext({ fileList });
  }

  useEffect(() => {
    appProperties?.controller?.getAssociatedObjectType().then(function (currentModuleFromApplication) {
      if (currentModuleFromApplication) {
        setResource(currentModuleFromApplication);
      }
    });
  }, [appProperties?.controller, appProperties?.controller?.serviceInitialized]);

  useEffect(() => {
    const globalChannel = { channel: channel };
    if (globalChannel) {
      globalChannel?.channel?.bind("CONVERSATION_UPDATE", (data) => {
        console.log("CONVERSATION_UPDATE data", data);

        if (data) {
          try {
            let response = JSON.parse(data?.data);
            if (
              response?.contactId === contactId.current ||
              ([from, newFromNumber, toNumberChatView, toNumberChatView?.replace(/[\s()-]/g, "")].includes(response?.sender_id) &&
                [from, newFromNumber, toNumberChatView, toNumberChatView?.replace(/[\s()-]/g, "")].includes(response?.receiver_id))
            ) {
              if (response?.messageStatus === "failed") {
                response.class = "input_error";
              }
              const payload = {
                integId: response?.integId,
                contactId: contactId.current,
                orgMember: fromNumberFlag ? from : newFromNumber,
                orgCustomer: chatView ? toNumberChatView : toNumber,
              };
              if (response.direction === 1) {
                fetchData("clearUnread", "PUT", payload, appProperties);
                // playNotificationSound();
              }
              if (unReadMessages?.length > 0) {
                updateUnreadMessages();
              }
              isFromFetchApi.current = true;
              setConversationContext((prev) => {
                const existingIndex = prev?.[contactId?.current]?.conversations?.findIndex((item) => item?.id === response?.messageId);
                if (existingIndex !== -1) {
                  const newList = [...(prev[contactId?.current]?.conversations ?? [])];
                  newList[existingIndex] = {
                    ...newList[existingIndex],
                    ...response,
                  };
                  prev[contactId?.current].conversations = newList;
                  return prev;
                } else {
                  prev[contactId?.current].conversations = [...(prev[contactId?.current]?.conversations || []), response];
                  return prev;
                }
              });
            }
            setNumbersList((prev) => {
              const existingIndex = prev?.findIndex((item) => item?.contactId === response?.contactId);
              if (existingIndex !== -1) {
                const newList = [...prev];
                newList[existingIndex] = {
                  ...newList[existingIndex],
                  messageTagStatus: response?.messageStatus,
                };
                return newList;
              } else {
                return [...(prev || [])];
              }
            });
          } catch (e) {
            console.error("ERROR::::", e);
          }
        }
      });
      return () => {
        globalChannel?.channel?.unbind("CONVERSATION_UPDATE");
        if (globalChannel.dispose) {
          globalChannel?.dispose();
        }
      };
    }
  }, [channel, toNumberChatView, from, newFromNumber, fromNumberFlag, unReadMessages]);

  const handleSendSMS = async () => {
    if (fetchConversationLoading) {
      return;
    }
    updateUnreadMessages();
    let newUUID = crypto.randomUUID();
    if (!(fromNumberFlag ? from : newFromNumber)) {
      failureNotification("From Number is required !");
    } else if (!toNumber && !toNumberChatView) {
      failureNotification("To Number is required !");
    } else if (message === "" && fileList?.length === 0 && !previewResponse?.components) {
      failureNotification("Message Body is required !");
    } else {
      setSendBtnLoading(true);
      let associatedObjectId = null;
      let associatedObjectType = null;

      if (chatView) {
        associatedObjectId = await appProperties?.controller?.getAssociatedObjectId();
        associatedObjectType = await appProperties?.controller?.getAssociatedObjectType();
        console.log("sendsms CTC >>>>>> associatedObjectId >>>>>>>", associatedObjectId);
        console.log("sendsms CTC >>>>>> associatedObjectType >>>>>>>", associatedObjectType);
      } else if (contactId.current && contactId.current !== DEFAULT_CONTACT_ID && numberList?.length > 0) {
        console.log("sendsms CTC ELSE_1 >>>>>> contactId.current >>>>>>>", contactId.current);
        let currentContact = numberList.find((obj) => obj?.contactId === contactId.current);
        associatedObjectId = currentContact?.sourceId;
        associatedObjectType = currentContact?.sourceModule;

        console.log("sendsms CTC ELSE_1 >>>>>> associatedObjectId >>>>>>>", associatedObjectId);
        console.log("sendsms CTC ELSE_1 >>>>>> associatedObjectType >>>>>>>", associatedObjectType);
      } else if (contactList?.length > 0) {
        associatedObjectId = contactList.filter((obj) => obj?.phone === toNumber)?.map((val) => val.id)?.[0];
        associatedObjectType = contactList.filter((obj) => obj?.phone === toNumber)?.map((val) => val.module)?.[0];

        console.log("sendsms CTC ELSE_2 >>>>>> associatedObjectId >>>>>>>", associatedObjectId);
        console.log("sendsms CTC ELSE_2 >>>>>> associatedObjectType >>>>>>>", associatedObjectType);
      }

      const mediaFileList =
        fileList?.length > 0
          ? fileList?.map((file) => {
              return {
                name: file.name,
                url: file.url,
                contentType: file.type,
                size: file.size,
              };
            })
          : [];
      var body = {
        from: fromNumberFlag ? from : newFromNumber,
        pageId: fromNumberFlag ? from : newFromNumber,
        to: chatView ? toNumberChatView : toNumber,
        messageBody: message,
        mediaFileList: mediaFileList,
        userId: urlParams.get("userId"),
        associatedObjectId: associatedObjectId,
        associatedObjectType: associatedObjectType,
        templateId: templateId,
        components: previewResponse?.components,
        fieldNameAndValueMap: previewResponse?.fieldNameAndValueMap,
      };
      let rightPhoneObj = appProperties.installedApps?.find((obj) => obj?.integProps?.integId === integId);

      if (rightPhoneObj?.right?.auth?.ignoreAuthorizedCheck || rightPhoneObj?.right?.auth?.authorized) {
        isFromFetchApi.current = true;
        updateConversationContext({ message: "" });
        setTemplateIdRef(null);
        setPreviewResponse({});
        var idObject = {
          sender_id: fromNumberFlag ? from : newFromNumber,
          id: toNumber,
          class: "input-error",
          tempMessageId: newUUID,
          messageStatus:
            !CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) ||
            (CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) && balanceCredits > 0.5)
              ? "queued"
              : "failed",
          errorMessage:
            CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) && balanceCredits < 0.5 ? (
              <span>
                Message sending failed due to low credits. Please
                <BuyFunds />.
              </span>
            ) : null,
          direction: 2,
          components: previewResponse?.components,
          message: message,
          attachments: fileList?.length > 0 ? fileList : null,
          createdTime: new Date().toISOString(),
        };

        const updatedList = numberList?.map((item) => {
          if ((item.orgCustomer === idObject.id && item.orgMember === idObject.sender_id) || (item.orgCustomer === idObject.id && item.new)) {
            return {
              ...item,
              orgMember: fromNumberFlag ? from : newFromNumber,
              sourceFrom: rightPhoneObj?.right?.service_display_name,
              message: message,
              messageTagStatus:
                !CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) ||
                CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) ||
                balanceCredits > 0.5
                  ? "queued"
                  : "failed",

              messageTagType: fileList?.[fileList?.length - 1]?.type,
              messageTime: dayjs().format("YYYY-MM-DD HH:mm"),
            };
          } else {
            return item;
          }
        });
        setNumbersList(updatedList);
        setConversationContext((prev) => ({
          ...prev,
          [contactId.current]: {
            ...prev?.[contactId.current],
            conversations: [...(prev?.[contactId?.current]?.conversations || []), idObject],
            fileList: [],
          },
        }));
        if (
          !CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) ||
          (CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) && balanceCredits > 0.5)
        ) {
          sendsms(integId, body)
            .then(function (response) {
              try {
                if (response) {
                  response = JSON.parse(response);
                  handleSendSmsResp(response, newUUID, idObject);
                  setSendBtnLoading(false);
                }
              } catch (error) {
                console.error("Error processing response:", error);
              }
            })
            .catch((error) => {
              setSendBtnLoading(false);
              setConversationContext((prev) => {
                var existingIndex = prev[contactId?.current]?.conversations?.findIndex((item) => item?.tempMessageId === newUUID);
                if (existingIndex !== -1) {
                  const newList = [...prev[contactId?.current]?.conversations];
                  let messageData = {
                    message: newList[existingIndex]?.message,
                    createdTime: newList[existingIndex]?.createdTime,
                    attachments: newList[existingIndex]?.attachments,
                    direction: 2,
                    class: "input-error",
                    messageStatus: "failed",
                  };
                  newList[existingIndex] = messageData;
                  prev[contactId?.current].conversations = newList;
                }
                return prev;
              });
            });
        }
      } else {
        if (rightPhoneObj?.right) {
          openServiceNotAuthorizedModal(rightPhoneObj?.right, appProperties);
        }
        setSendBtnLoading(false);
      }
    }
  };

  useEffect(() => {
    if (sendBtnLoading) {
      setTemplateIdRef();
    }
  }, [sendBtnLoading]);

  useEffect(() => {
    let obj = serviceList?.find((obj) => obj?.serviceInfo?.displayName === customerInfoObj?.displayName);
    let rightServiceName = obj?.serviceInfo?.name;
    if (newFromNumber && savedNumbersList?.length > 0) {
      let numberObj = savedNumbersList?.find((obj) => obj?.phoneNumber === newFromNumber);
      rightServiceName = appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === numberObj?.integId)?.right?.service_name;
    }
    if (rightServiceName) {
      let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[rightServiceName]);
      appProperties.providerCommonObj = assignerObj1;
    }
  }, [customerInfoObj, newFromNumber, appProperties, savedNumbersList, serviceList]);

  const handleFromNumberChange = (e) => {
    setNewFromNumber(e);
  };
  useEffect(() => {
    if (templateId) messageBodyRef?.current?.focus();
  }, [templateId]);

  const handlePreviewRemove = () => {
    setPreviewResponse((prev) => {
      return { ...prev, components: null };
    });
    setTemplateIdRef(null);
  };

  const messageTextAreaRowCount = useMemo(
    () => (messageTextAreaContentRef.current ? Math.floor(messageTextAreaContentRef.current?.offsetHeight / perRowHeightApprox) : 3),
    [messageTextAreaContentRef.current?.offsetHeight]
  );

  const handleMediaFileList = (fileList) => {
    const contactIdOfUploadedMediaFile = fileList?.[0]?.contactId ?? contactId.current;
    setConversationContext((prev) => ({
      ...prev,
      [contactIdOfUploadedMediaFile]: {
        ...prev[contactIdOfUploadedMediaFile],
        fileList: fileList,
      },
    }));
  };

  return (
    <>
      {previewResponse?.components ? (
        <>
          <ComponentPreview
            handleRemove={handlePreviewRemove}
            componentsData={previewResponse?.components}
            chatView={chatView}
            source="message-area"
          />
          <SendMessageButton
            previewResponse={previewResponse}
            conversations={conversations}
            contactId={contactId}
            unReadMessages={unReadMessages}
            handleFromNumberChange={handleFromNumberChange}
            savedNumbersList={savedNumbersList}
            setSavedNumbersList={setSavedNumbersList}
            integId={integId}
            chatView={chatView}
            antIcon={antIcon}
            sendBtnLoading={sendBtnLoading}
            handleSendsms={handleSendSMS}
          />
        </>
      ) : (
        <div className={`${"hs-h-30 py-1 position-relative"}`}>
          {fileList && fileList?.length > 0 ? (
            <Row className="position-absolute w-100 preview-absolute-container-height">
              <div className="d-flex align-items-end w-100 preview-container-height">
                <ReusableFilePreview
                  fileList={fileList}
                  setFileList={handleMediaFileList}
                  shape={"circle"}
                  direction={"horizontal"}
                  customClasses={"ps-3"}
                />
              </div>
            </Row>
          ) : null}
          <div className="hs-border-0-25-rem hs-focus-border h-100 hs-outline-dark-grey hs-w-98 mx-auto d-flex flex-column justify-content-end">
            {[VOICE_CHARGEBEE_PLAN_MONTHLY, VOICE_CHARGEBEE_PLAN_YEARLY].includes(appProperties?.licenseObj?.licenseDetails?.planName) ? (
              <div className="d-flex justify-content-between align-items-center px-2 pb-2">
                <div className="hs-bg-light-gray w-100 p-2 hs-border-8 mx-2 hs-border-md-grey d-flex align-items-center justify-content-between">
                  <div className="hs-fs-14 hs-color-md-grey hs-fw-400">Upgrade to PRO to unlock messaging features</div>
                  <div className="d-flex flex-row hs-fs-14 hs-fw-500 align-items-center hs-color-violet">
                    Upgrade
                    <div className="call-action-icons go-to-arrow-violet" />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={`h-75`}>
                  <div className={"h-25"}>
                    <MessagingAreaHeader
                      fetchOnlyByModule={chatView}
                      iconsSize={chatView ? "small" : "large"}
                      refetchOnModuleChange={true}
                      toNumber={chatView ? toNumberChatView : toNumber}
                      showOnlyPopup={false}
                      integrationId={customerInfoObj?.integId || integId}
                      setMessageAreaLoading={setMessageAreaLoading}
                      templateId={templateId}
                      setTemplateId={setTemplateIdRef}
                      setLoading={null}
                      handleMessage={handleMessage}
                      handleMedia={handleMedia}
                      loadTemplates={true}
                      moduleName={chatView ? resource : ""}
                      chatView={chatView}
                      groupBy={chatView ? null : "moduleName"}
                      showPreview={true}
                      fileList={fileList}
                      setFileList={handleMediaFileList}
                      contactId={contactId.current}
                      source="conversation"
                      open={messageHeaderTemplateOpen}
                      previewResponse={previewResponse}
                      setPreviewResponse={handlePreviewResponse}
                      setOpen={setMessageHeaderTemplateOpen}
                      appProperties={appProperties}
                    />
                  </div>
                  <div className={"h-75"} ref={messageTextAreaContentRef}>
                    <MessageAreaTemplateSelect
                      customerInfoObj={customerInfoObj}
                      chatView={chatView}
                      toNumberChatView={toNumberChatView}
                      toNumber={toNumber}
                      setMessageAreaLoading={setMessageAreaLoading}
                      setTemplateId={setTemplateIdRef}
                      templateId={templateId}
                      handleMessage={handleMessage}
                      handleMedia={handleMedia}
                      setPreviewResponse={setPreviewResponse}
                      appProperties={appProperties}
                      integId={integId}
                      resource={resource}
                      setTemplateOpen={setTemplateOpen}
                      templateOpen={templateOpen}
                      previewResponse={previewResponse}
                    />

                    <Spin spinning={messageAreaLoading}>
                      <TextArea
                        variant="borderless"
                        autoFocus
                        style={{
                          resize: "none",
                          transition: "none",
                          minHeight: chatView ? 70 : source === "campaign" ? 150 : 100,
                          paddingTop: ".5rem",
                          fontSize: "16px",
                        }}
                        rows={messageTextAreaRowCount}
                        placeholder={"Type '/' to open Templates"}
                        rootClassName="ms-2"
                        value={previewResponse?.components ? null : message}
                        disabled={previewResponse?.components}
                        onChange={(e) => {
                          handleMessage(e?.target?.value, e);

                          if (unReadMessages?.length > 0) {
                            updateUnreadMessages();
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey && source !== "campaign") {
                            e.preventDefault();
                            handleSendSMS();
                          }
                        }}
                        ref={messageBodyRef}
                        className={`conv-textArea h-100 ${chatView ? "" : "ps-2"}`}
                      />
                    </Spin>
                  </div>
                </div>
                <SendMessageButton
                  previewResponse={previewResponse}
                  conversations={conversations}
                  contactId={contactId}
                  unReadMessages={unReadMessages}
                  handleFromNumberChange={handleFromNumberChange}
                  savedNumbersList={savedNumbersList}
                  setSavedNumbersList={setSavedNumbersList}
                  integId={integId}
                  chatView={chatView}
                  antIcon={antIcon}
                  sendBtnLoading={sendBtnLoading}
                  handleSendsms={handleSendSMS}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
function SendMessageButton({
  previewResponse,
  conversations,
  contactId,
  unReadMessages,
  handleFromNumberChange,
  savedNumbersList,
  setSavedNumbersList,
  integId,
  chatView,
  antIcon,
  sendBtnLoading,
  handleSendsms,
}) {
  return (
    <div className={`${previewResponse?.components ? "hs-h-10" : "h-25"} pb-1`}>
      <div className="d-flex align-items-center h-100 hs-w-98">
        <div className="d-flex ms-auto align-items-center">
          {(([conversations]?.length < 1 && unReadMessages?.length < 1) || !integId) && !chatView && (
            <FromNumberComponent
              customClasses={"fromNumber-conv"}
              fromNumber={""}
              handleFromNumberChange={handleFromNumberChange}
              savedNumbersList={savedNumbersList}
              setSavedNumbersList={setSavedNumbersList}
              disabled={false}
            />
          )}
          <Row
            onClick={() => handleSendsms()}
            align={"middle"}
            className="cursor-pointer new-sendsms-icon-wrapper hs-bg-violet hs-fs-14 text-white hs-fw-500"
          >
            <Col className="d-flex justify-content-center align-items-center" span={10}>
              {sendBtnLoading ? <Spin indicator={antIcon} /> : <div className="actionIconsSprite new-sendsms-icon" />}
            </Col>
            <Col span={12}>Send</Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
function MessageAreaTemplateSelect({
  chatView,
  toNumberChatView,
  toNumber,
  setMessageAreaLoading,
  setTemplateId,
  handleMessage,
  handleMedia,
  setPreviewResponse,
  appProperties,
  integId,
  resource,
  setTemplateOpen,
  templateOpen,
  previewResponse,
  customerInfoObj,
  templateId,
}) {
  return (
    <SavedTemplatesSelect
      refetchOnModuleChange={true}
      toNumber={chatView ? toNumberChatView : toNumber}
      showOnlyPopup={true}
      integrationId={customerInfoObj?.integId || integId}
      setMessageAreaLoading={setMessageAreaLoading}
      setTemplateId={setTemplateId}
      templateId={templateId}
      setLoading={null}
      handleMessage={handleMessage}
      handleMedia={handleMedia}
      loadTemplates={true}
      moduleName={chatView ? resource : ""}
      chatView={chatView}
      groupBy={chatView ? null : "moduleName"}
      showPreview={true}
      open={templateOpen}
      previewResponse={previewResponse}
      setPreviewResponse={setPreviewResponse}
      setOpen={setTemplateOpen}
      appProperties={appProperties}
    />
  );
}

const ChatViewHeader = (props) => {
  const {
    appProperties,
    newFromNumber,
    setNewFromNumber,
    setSavedNumbersList,
    savedNumbersList,
    setToNumberChatView,
    phoneFieldConfiguration,
    setPhoneFieldConfiguration,
    selectedContactId,
  } = props;
  const [toNumber, setToNumber] = useState("");
  const [phoneFields, setPhoneFields] = useState([]);
  const handleFromNumberChange = (e) => {
    selectedContactId.current = DEFAULT_CONTACT_ID;
    setNewFromNumber(e);
  };
  const [debouncedToNumber] = useDebounce(toNumber, 500);
  useEffect(() => {
    selectedContactId.current = DEFAULT_CONTACT_ID;
    setToNumberChatView(debouncedToNumber);
  }, [debouncedToNumber]);

  return (
    <Flex justify="center" className="hs-h-20 w-100">
      <div className="hs-w-95">
        <Row className="w-100 hs-border-bottom h-50">
          <Col className="d-flex align-items-center justify-content-between" span={3}>
            <div>From</div>
            <div>:</div>
          </Col>
          <Col span={21}>
            <FromNumberComponent
              chatView={true}
              fromNumber={newFromNumber}
              handleFromNumberChange={handleFromNumberChange}
              savedNumbersList={savedNumbersList}
              setSavedNumbersList={setSavedNumbersList}
              disabled={false}
              defaultSelectFirstOption={true}
              variant="borderless"
              customClasses="h-100"
            />
          </Col>
        </Row>
        <Row className="w-100 hs-border-bottom h-50">
          <Col className="d-flex align-items-center justify-content-between" span={3}>
            <div>To</div>
            <div>:</div>
          </Col>
          <Col span={19} className="d-flex align-items-center justify-content-start">
            <SendSMSToNumberComponent
              toNumber={toNumber}
              setToNumber={setToNumber}
              savedNumbersList={savedNumbersList}
              setSavedNumbersList={setSavedNumbersList}
              disabled={false}
              defaultSelectFirstOption={true}
              setPhoneFields={setPhoneFields}
              phoneFieldConfiguration={phoneFieldConfiguration}
              setPhoneFieldConfiguration={setPhoneFieldConfiguration}
            />
          </Col>
          <Col span={2} className="d-flex align-items-center justify-content-center">
            <ConfigurePhone
              phoneFieldConfiguration={phoneFieldConfiguration}
              setPhoneFieldConfiguration={setPhoneFieldConfiguration}
              phoneFields={phoneFields}
              toNumber={toNumber}
              setToNumber={setToNumber}
              setPhoneFields={setPhoneFields}
            />
          </Col>
        </Row>
      </div>
    </Flex>
  );
};
const ConversationHeader = ({
  customerInfoObj,
  toNumber,
  setShowChatView,
  showChatView,
  savedNumbersList,
  newFromNumber,
  from,
  setShowChatOverlay,
}) => {
  const [showSwitch, setShowSwitch] = useState(true);
  const historySupportedArr = ["Twilio", "RingCentral", "Hello Send", "Facebook"];
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { md, xl } = Grid.useBreakpoint();
  useEffect(() => {
    if (from && !historySupportedArr.includes(customerInfoObj?.displayName)) {
      setShowChatView(false);
    }
    let integ = savedNumbersList?.find((entry) => entry.phoneNumber === from)?.integId;
    let findNumber = savedNumbersList?.find((obj) => (obj?.phoneNumber === integ ? from : newFromNumber));
    if (findNumber?.isMessagingService === "true" || findNumber?.isAlphaSender === "true") {
      setShowSwitch(false);
    }
  }, [savedNumbersList, newFromNumber, from]);

  return (
    <>
      <div className="d-flex align-items-center inbox-header">
        <Row className="w-100 d-flex align-items-center border-bottom flex-nowrap py-2 h-100">
          <div
            className="d-md-none px-2"
            onClick={() => {
              setShowChatOverlay(false);
            }}
          >
            <div className="actionIconsSprite back-icon" />
          </div>
          <div className="ps-md-3">
            {customerInfoObj?.contactAvatar ?? (
              <div className="ms-1 hs-bg-light-orange rounded-circle d-flex align-items-center justify-content-center list-row-avatar">
                {getDefaultAvatar()}
              </div>
            )}
          </div>
          <Col className="flex-grow-1 d-flex justify-content-between align-items-center m-1">
            <span className="d-flex flex-column">
              {customerInfoObj?.firstName && (
                <span className="hs-fs-16">
                  <RenderNameWithEllipsis content={customerInfoObj?.firstName} maxWidth={md ? "100%" : "150px"} />
                </span>
              )}
              <span
                style={{
                  cursor: "default",
                  fontSize: customerInfoObj?.firstName ? 12 : 16,
                }}
              >
                {toNumber}
              </span>
            </span>
            <div className="flex-grow-1 d-flex justify-content-end align-items-center m-1">
              <div className="d-flex justify-content-end align-items-center w-75">
                {/* {toNumber && (
                  <div
                    onClick={() => {
                      initiateOutboundCall(toNumber, customerInfoObj?.sourceId, customerInfoObj?.sourceModule, appProperties, setAppProperties);
                    }}
                    className="d-flex align-items-center justify-content-end flex-row h-100 hs-bg-light-mint-green hs-border-9999-px click-to-call-icon p-2 px-4 cursor-pointer"
                  >
                    <div className="call-action-icons logs-clickToCall-Icon" />
                    <div className="hs-color-vibrant-green hs-fs-12">Call</div>
                  </div>
                )} */}
                {historySupportedArr.includes(customerInfoObj?.displayName) && showSwitch && (
                  <div className="px-2">
                    <span style={{ cursor: "default" }}>Show All</span>
                    <Switch
                      className="addPhoneSwitch mx-2"
                      value={showChatView}
                      onChange={(e) => {
                        setShowChatView(e);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
const ConversationMessages = React.memo(
  ({
    customerInfoObj,
    conversations,
    scrollableDivRef,
    updateUnreadMessages,
    unReadMessages,
    integId,
    sendsms,
    appProperties,
    handleSendSmsResp,
    toNumber,
    toNumberChatView,
    balanceCredits,
    chatView,
    previousScrollHeight,
    fetchConversationLoading,
    fetchByList,
    selectedContactId,
    channelRedirectUrl,
    setConversationContext,
  }) => {
    useEffect(() => {
      const currentContactId = selectedContactId.current;
      return () => {
        setConversationContext((prev) => {
          return {
            ...prev,
            [currentContactId]: {
              ...prev?.[currentContactId],
              fileList: prev?.[currentContactId]?.fileList?.filter((file) => file.status === "done"),
            },
          };
        });
      };
    }, []);

    const handleRetrySendsms = (obj) => {
      let rightPhoneObj = appProperties.installedApps?.find((obj) => obj?.integProps?.integId === integId);
      if (rightPhoneObj?.right?.auth?.ignoreAuthorizedCheck || rightPhoneObj?.right?.auth?.authorized) {
        let newUUID = crypto.randomUUID();
        var idObject = {
          ...obj,
          createdTime: new Date().toISOString(),
          messageStatus:
            !CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) ||
            (CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) && balanceCredits > 0.5)
              ? "queued"
              : "failed",
          errorMessage:
            CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) && balanceCredits < 0.5 ? (
              <span>
                Message sending failed due to low credits. Please
                <BuyFunds />.
              </span>
            ) : null,
          tempMessageId: newUUID,
        };
        setConversationContext((prev) => ({
          ...prev,
          [selectedContactId.current]: {
            ...prev[selectedContactId.current],
            conversations: [...(prev[selectedContactId.current]?.conversations || []), idObject],
          },
        }));

        const mediaFileList = obj.attachments
          ? obj.attachments.map((file) => {
              return {
                name: file.name,
                url: file.url,
                contentType: file.type,
                size: file.size,
              };
            })
          : [];
        const body = {
          from: obj.sender_id,
          to: chatView ? toNumberChatView : toNumber,
          message: obj.message,
          pageId: obj.sender_id,
          tempMessageId: newUUID,
          messageBody: obj.message,
          mediaFileList: mediaFileList,
        };
        if (
          !CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) ||
          (CREDIT_BASED_SRVICES.includes(rightPhoneObj?.right?.service_name) && balanceCredits > 0.5)
        ) {
          sendsms(integId, body)
            .then(function (response) {
              if (response) {
                response = JSON.parse(response);
                handleSendSmsResp(response, newUUID, idObject);
              }
            })
            .catch((err) => {
              setConversationContext((prev) => {
                var existingIndex = prev[selectedContactId?.current]?.conversations?.findIndex((item) => item?.tempMessageId === newUUID);
                if (existingIndex !== -1) {
                  const newList = [...prev[selectedContactId?.current]?.conversations];
                  let messageData = {
                    ...idObject,
                    class: "input-error",
                    messageStatus: "failed",
                  };
                  newList[existingIndex] = messageData;
                  prev[selectedContactId?.current].conversations = newList;
                }
                return prev;
              });
            });
        }
      } else {
        if (rightPhoneObj?.right) {
          failureNotificationWithBtn(
            <span className="text-nowrap">
              {`${rightPhoneObj?.right?.service_display_name} service is not
              authorized !`}
            </span>,
            "Authorize",
            `/channels/textsms/${rightPhoneObj?.right?.service_name}?` + channelRedirectUrl
          );
        }
      }
    };

    function mapMessages(data, isUnreadMessage) {
      return (
        <>
          {data?.map((obj, index) => (
            <div key={index}>
              {obj?.isUnreadedMessage && isUnreadMessage && (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    {formatDate(obj?.createdTime) !== formatDate(conversations[conversations?.length - 1]?.createdTime) &&
                    formatDate(obj?.createdTime) !== formatDate(data[index - 1]?.createdTime) ? (
                      <div
                        className="p-1 rounded ps-2 pe-2 mb-1"
                        style={{
                          backgroundColor: "#F4F5FA",
                          fontSize: 12,
                        }}
                      >
                        {formatDate(obj?.createdTime)}
                      </div>
                    ) : null}
                  </div>
                  {index === 0 && (
                    <div className="d-flex align-items-center justify-content-center">
                      <div
                        className="p-1 mt-1 rounded-5 ps-3 pe-3"
                        style={{
                          backgroundColor: "#F4F5FA",
                          fontSize: 15,
                        }}
                      >
                        {unReadMessages.length} Unread Messages
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {formatDate(obj?.createdTime) !== formatDate(data[index - 1]?.createdTime) && !isUnreadMessage ? (
                  <div
                    className="p-1 rounded ps-2 pe-2 mb-1"
                    style={{
                      backgroundColor: "#F4F5FA",
                      fontSize: 12,
                    }}
                  >
                    {formatDate(obj?.createdTime)}
                  </div>
                ) : null}
              </div>

              <div
                className={"d-flex flex-column p-1 m-auto"}
                style={{
                  alignItems: obj?.direction === 2 ? "end" : "start",
                }}
              >
                <div
                  className="d-flex justify-content-end align-items-end"
                  style={{
                    flexFlow: obj?.direction === 2 ? "row" : "row-reverse",
                    marginInlineStart: obj?.direction === 2 ? "30%" : 0,
                    marginInlineEnd: obj?.direction === 2 ? 0 : "30%",
                  }}
                >
                  <div className="d-flex flex-column align-items-end position-relative">
                    {obj?.attachments?.length > 0 ? (
                      <div className="position-relative shadow-sm rounded p-2">
                        <EmojiOverlay conversationObject={obj} />
                        {obj?.attachments.map((attachment, index) => (
                          <>
                            {(attachment.type?.startsWith("application/") || attachment.type?.startsWith("text/")) && (
                              <div className="h-100 w-100 mb-2">
                                <a key={index} href={attachment.url} target="_blank" rel="noopener noreferrer" className="pe-2 pdfAttch">
                                  <div className="d-flex align-items-center justify-content-start">
                                    <span
                                      className={`${attachment.type === "application/pdf" ? "pdf-icon" : "doc-icon"} staticIconsSprite`}
                                    ></span>
                                    {attachment.name}
                                  </div>
                                </a>
                              </div>
                            )}
                            {attachment.type?.startsWith("audio/") && (
                              <div>
                                <audio
                                  key={index}
                                  src={attachment.url}
                                  controls
                                  className="mt-1"
                                  style={{
                                    borderRadius: 12,
                                  }}
                                />
                              </div>
                            )}
                          </>
                        ))}
                        {obj?.attachments.some(
                          (attachment) =>
                            attachment.type?.startsWith("application/") ||
                            attachment.type?.startsWith("text/") ||
                            attachment.type?.startsWith("audio/")
                        ) && <DateAndStatus conversationObject={obj} />}
                      </div>
                    ) : null}

                    {obj?.attachments?.length > 0 &&
                    obj?.attachments?.some((attachment) => attachment?.type?.startsWith("image/") || attachment?.type?.startsWith("video/")) ? (
                      <>
                        <div
                          className={`position-relative image-container ${
                            ["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) ? "image-failed" : ""
                          }`}
                        >
                          <EmojiOverlay conversationObject={obj} />
                          <div className={obj.attachments.length > 1 ? "conv-attachments" : ""}>
                            {obj?.attachments.map((attachment, index) => (
                              <>
                                {attachment.type?.startsWith("image/") && (
                                  <div className="image-background">
                                    <Image
                                      key={index}
                                      src={attachment.url}
                                      preview={{
                                        mask: <div />,
                                        toolbarRender: () => null,
                                        maskClassName: "previewMask",
                                      }}
                                      width={getMediaDimension(obj, "22rem")}
                                      className="chatImage mt-1"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = MediaError;
                                      }}
                                      style={{
                                        borderRadius: 12,
                                        margin: -1,
                                        padding: "0px 2px 4px 0px",
                                        minHeight: getMediaDimension(obj, "16rem"),
                                        height: getMediaDimension(obj, "16rem"),
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                )}
                                {attachment.type?.startsWith("video/") && (
                                  <div>
                                    <video
                                      key={index}
                                      src={attachment.url}
                                      controls
                                      width={getMediaDimension(obj, "16rem")}
                                      className="mt-1"
                                      style={{
                                        borderRadius: 12,
                                        margin: -1,
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                          {obj?.message &&
                            obj?.attachments?.some(
                              (attachment) => attachment?.type?.startsWith("image/") || attachment?.type?.startsWith("video/")
                            ) && (
                              <pre
                                className={obj?.message ? "p-1" : ""}
                                style={{
                                  fontSize: 14,
                                  whiteSpace: "pre-wrap",
                                  wordBreak: "break-word",
                                  position: "relative",
                                  maxWidth:
                                    obj.attachments.length === 1 && !chatView
                                      ? "16rem"
                                      : obj.attachments.length > 1 && chatView
                                      ? "14rem"
                                      : obj.attachments.length === 1 && chatView
                                      ? "11rem"
                                      : "22rem",
                                }}
                              >
                                {convertURLsToLinks(obj, true)}
                              </pre>
                            )}
                          <DateAndStatus conversationObject={obj} />
                        </div>
                        {["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) && (
                          <div
                            className="message-retry"
                            onClick={() => {
                              handleRetrySendsms(obj);
                            }}
                          >
                            <div className="actionIconsSprite message-retry-icon "></div>{" "}
                          </div>
                        )}
                      </>
                    ) : null}
                    {(obj?.message || obj?.components) &&
                      !obj?.attachments?.some(
                        (attachment) => attachment?.type?.startsWith("image/") || attachment?.type?.startsWith("video/")
                      ) &&
                      (obj?.components ? (
                        <>
                          <div className="position-relative">
                            <EmojiOverlay conversationObject={obj} />
                            <ComponentPreview source={"conversation"} componentsData={obj?.components} />
                          </div>
                          <DateAndStatus conversationObject={obj} />
                        </>
                      ) : (
                        <div
                          className={`${
                            ["failed", "undelivered"].includes(obj?.messageStatus || obj?.status)
                              ? "failedMessage"
                              : obj?.direction === 2
                              ? "deliveredMessage"
                              : "receivedMessage"
                          }  ${obj?.class ?? ""}`}
                        >
                          <EmojiOverlay conversationObject={obj} />
                          <div
                            style={{
                              position: "relative",
                            }}
                            className={obj?.message && "p-2"}
                          >
                            <pre
                              style={{
                                fontSize: 14,
                                position: "relative",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                                textAlign: `start`,
                              }}
                            >
                              {convertURLsToLinks(obj, false)}
                            </pre>
                            {["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) && (
                              <div
                                className="message-retry"
                                onClick={() => {
                                  handleRetrySendsms(obj);
                                }}
                              >
                                <div className="actionIconsSprite message-retry-icon "></div>{" "}
                              </div>
                            )}
                            <DateAndStatus conversationObject={obj} />
                          </div>
                        </div>
                      ))}
                  </div>

                  <div
                    className="ms-2 me-2"
                    style={{
                      borderRadius: 8,
                      height: 30,
                      width: 30,
                      marginBottom: 1,
                      minWidth: 30,
                      background: !obj?.reply_by ? "" : obj?.reply_by === data[index + 1]?.reply_by ? "white" : "#EBF5ED",
                    }}
                  >
                    {obj?.direction === 2 ? (
                      <div
                        style={{
                          height: 30,
                          borderRadius: 8,
                          width: 30,
                          color: "#3A974C",
                          display: !obj?.reply_by ? "none" : obj?.reply_by === data[index + 1]?.reply_by ? "none" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {obj?.sender_url ? (
                          <Tooltip title={obj?.reply_by}>
                            <img height={30} width={30} alt="sender_url" src={obj?.sender_url} style={{ borderRadius: 4, objectFit: "cover" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={obj?.reply_by}>{obj?.reply_by?.slice(0, 1)}</Tooltip>
                        )}
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: 30,
                          borderRadius: 8,
                          width: 30,
                          color: "#FB784E",
                          fontSize: 18,
                          textTransform: "capitalize",
                          fontWeight: 700,
                          backgroundColor: customerInfoObj?.firstName?.slice(0, 1) && obj?.direction !== data[index + 1]?.direction && "#FFF2EE",
                        }}
                      >
                        {obj?.direction !== data[index + 1]?.direction && (
                          <span>
                            {customerInfoObj?.avatar || customerInfoObj?.avatar_url ? (
                              <Tooltip title={customerInfoObj?.firstName}>
                                <img
                                  height={30}
                                  width={30}
                                  alt="receiver_url"
                                  style={{
                                    borderRadius: 4,
                                    objectFit: "cover",
                                  }}
                                  src={customerInfoObj?.avatar || customerInfoObj?.avatar_url}
                                />
                              </Tooltip>
                            ) : (
                              <span> {customerInfoObj?.firstName?.slice(0, 1)}</span>
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );

      function getMediaDimension(obj) {
        return obj.attachments.length === 1 && !chatView ? "256px" : obj.attachments.length > 1 && chatView ? "112px" : "176px";
      }
    }

    const handleInboxDivScroll = () => {
      const scrollableDiv = scrollableDivRef?.current;
      if (scrollableDiv?.scrollTop === 0) {
        previousScrollHeight.current = scrollableDiv.scrollHeight;
        scrollableDiv.style.scrollBehavior = "auto";
        fetchByList(integId, !chatView);
      }
    };

    return (
      <div
        className={`p-1 overflow-auto mt-auto h-auto ${chatView ? "hs-max-h-50" : "hs-max-h-63"}`}
        style={{ scrollBehavior: "smooth", position: "relative" }}
        ref={scrollableDivRef}
        onScroll={handleInboxDivScroll}
      >
        {fetchConversationLoading && (
          <div className="w-100 d-flex justify-content-center">
            <div className="actionIconsSprite dropDownLoading" />
          </div>
        )}
        {mapMessages(conversations)}
        {unReadMessages?.length > 0 && <div onClick={() => updateUnreadMessages()}>{mapMessages(unReadMessages, true)}</div>}
      </div>
    );
  }
);

function DateAndStatus({ conversationObject }) {
  return (
    <div className={`d-flex ${conversationObject?.direction === 2 ? "justify-content-end" : ""}`}>
      <span
        style={{
          color: "#8B8D97",
          fontSize: 9,
        }}
        className="d-flex align-items-center"
      >
        {formatTime(conversationObject?.createdTime)}
        {conversationObject?.direction === 2 && (
          <span className={`ps-1`}>
            <Tooltip
              title={
                <span style={{ textTransform: "capitalize" }}>
                  {conversationObject?.errorCode ? (
                    <div>Error Code : {conversationObject?.errorCode}</div>
                  ) : (
                    <div> {conversationObject?.messageStatus || conversationObject?.status}</div>
                  )}
                  {conversationObject?.errorMessage && <div>{conversationObject?.errorMessage}</div>}
                </span>
              }
            >
              {(conversationObject?.messageStatus || conversationObject?.status) === "queued" ? (
                <div className="actionIconsSprite queue-icon"></div>
              ) : (conversationObject?.messageStatus || conversationObject?.status) === "sent" ? (
                <div className="actionIconsSprite success-icon"></div>
              ) : ["failed", "undelivered"].includes(conversationObject?.messageStatus || conversationObject?.status) ? (
                <div className="actionIconsSprite failure-icon"></div>
              ) : (conversationObject?.messageStatus || conversationObject?.status) === "delivered" ? (
                <div className="actionIconsSprite delivered-icon "></div>
              ) : (conversationObject?.messageStatus || conversationObject?.status) === "read" ? (
                <div className="actionIconsSprite read-icon "></div>
              ) : null}
            </Tooltip>
          </span>
        )}
      </span>
    </div>
  );
}

function EmojiOverlay({ conversationObject }) {
  const emojiObject = conversationObject?.associatedMessages?.find((associatedMessage) => associatedMessage?.type === "REACTION");
  if (!emojiObject) {
    return <></>;
  }
  return (
    <div
      className={`conversation-emoji-overlay ${
        conversationObject?.direction === 2 ? "conversation-emoji-overlay-left" : "conversation-emoji-overlay-right"
      } hs-border-dark-grey d-flex align-items-center justify-content-center`}
    >
      {emojiObject?.body}
    </div>
  );
}

const NoConversations = ({ navigate, channelRedirectUrl, chatView, appProperties }) => (
  <div className="d-flex flex-column align-items-center justify-content-center">
    <div className="staticIconsSprite chatIcon-sprite"></div>
    {!appProperties?.serviceInstalled ? (
      <div className="d-flex flex-column align-items-center noConvo-info text-center hs-max-w-300-px">
        <span style={{ fontSize: chatView ? 14 : 16, color: "#818094" }}>Stay connected with your customers. </span>
        <span style={{ fontSize: chatView ? 12 : 14, color: "#818094" }}>
          Connect your channel and begin typing a contact name or number above to initiate a new message.{" "}
        </span>
      </div>
    ) : (
      <div className="d-flex flex-column align-items-center noConvo-info text-center hs-max-w-300-px">
        <span style={{ fontSize: chatView ? 14 : 16, color: "#818094" }}>Stay connected with your customers. </span>
        {!chatView && (
          <span
            style={{
              fontSize: chatView ? 12 : 14,
              color: "#818094",
            }}
          >
            Begin typing a contact name or number above to initiate a new message.
          </span>
        )}
      </div>
    )}
    {!appProperties?.serviceInstalled ? (
      <Button
        className="rounded m-2 addChannel-btn"
        onClick={() => {
          const textSmsUrl = "/channels/textsms?";
          if (chatView) {
            window.open(textSmsUrl + channelRedirectUrl, "_blank");
          } else {
            navigate(textSmsUrl + channelRedirectUrl);
          }
        }}
        type=""
      >
        Add Channels
      </Button>
    ) : null}
  </div>
);

function ServiceIndicationIcon({ serviceName }) {
  return (
    <>
      {serviceName && (
        <div
          className={`staticIconsSprite contact-service-indicator-icon shadow contact-service-indicator-icon-${serviceName
            ?.toLowerCase()
            ?.replace(/ /g, "_")}`}
        />
      )}
    </>
  );
}

function ListView({
  numberList,
  setNumbersList,
  selectedContact,
  handleNumberSelect,
  setIsChannelPresent,
  setSelectedContact,
  setFilterContact,
  appProperties,
  services,
  setCustomerInfoObj,
  selectedContactId,
  showConversations,
  setShowConversations,
  setShowDescription,
  showDescription,
  newToNumberInputRef,
  setShowChatOverlay,
}) {
  const listRef = useRef(null);
  const { fetchData } = useHttp();
  const [selectedService, setSelectedService] = useState(null);
  const [searchInputLoader, setSearchInputLoader] = useState(null);
  const [filterSearchInput, setFilterSearchInput] = useState("");
  const [searchNoContactContent, setSearchNoContactContent] = useState(false);
  const [controller, setController] = useState(null);
  const paginationRef = useRef();
  const { Text } = Typography;

  const fetchApi = () => {
    setSearchInputLoader(<div className="actionIconsSprite search-loading"></div>);
    if (controller) controller.abort();
    const controllerObj = new AbortController();
    setController(controllerObj);
    let contactCount = numberList?.length;
    fetchData(
      `omessage/contacts?limit=100` +
        (filterSearchInput ? "&searchText=" + encodeURIComponent(filterSearchInput) : "") +
        (selectedService && selectedService !== "all" && selectedService?.slice(0, 1) !== "+"
          ? "&serviceId=" + selectedService
          : selectedService?.slice(0, 1) === "+"
          ? "&orgMember=" + encodeURIComponent(selectedService)
          : ""),
      "GET",
      null,
      appProperties,
      null,
      null,
      controllerObj.signal
    )
      .then(function (response) {
        try {
          if (response) {
            response = JSON.parse(response);
            setShowConversations(true);
            let resp = response?.data?.contacts;
            paginationRef.current = response?.data?.paginationModel?.lastKey;
            setNumbersList(resp);
            contactCount = resp?.length;
            setFilterContact(filterSearchInput);
            setSearchInputLoader(null);
            if (filterSearchInput.length > 0) {
              setSearchNoContactContent(true);
            }

            setShowDescription((prev) => {
              if (prev === true) {
                return false;
              }
              return prev;
            });
          } else {
            setSearchInputLoader(null);
          }
        } catch (error) {
          setSearchInputLoader(null);
          console.error("Error processing response:", error);
        }
      })
      .catch((err) => {
        setSearchInputLoader(null);
      })
      .finally(() => {
        if (paginationRef.current && filterSearchInput && contactCount < 20) {
          debounceFetchData();
        }
      });
  };
  useEffect(() => {
    if (appProperties && filterSearchInput === "") {
      fetchApi();
    }
  }, [selectedService, setNumbersList, fetchData, setFilterContact, setShowConversations, appProperties?.companyId, filterSearchInput]);

  const debounceFetchData = useDebouncedCallback(() => {
    if (controller) controller.abort();
    const controllerObj = new AbortController();
    setController(controllerObj);
    controller.abort();
    fetchData(
      "omessage/contacts?lastKey=" +
        encodeURIComponent(paginationRef.current ?? "") +
        "&limit=100" +
        (filterSearchInput !== "" ? "&searchText=" + encodeURIComponent(filterSearchInput) : "") +
        (selectedService !== null && selectedService !== "all" && selectedService?.slice(0, 1) !== "+"
          ? "&serviceId=" + selectedService
          : selectedService?.slice(0, 1) === "+"
          ? "&orgMember=" + encodeURIComponent(selectedService)
          : ""),
      "GET",
      null,
      appProperties,
      null,
      null,
      controllerObj.signal
    )
      .then(function (response) {
        try {
          if (response) {
            response = JSON.parse(response);
            let resp = response?.data?.contacts;
            paginationRef.current = response.data?.paginationModel?.lastKey;
            setNumbersList([...numberList, ...resp]);
          } else {
            paginationRef.current = null;
          }
        } catch (error) {
          console.error("Error processing response:", error);
        }
      })
      .catch((err) => {
        console.log(err);
        paginationRef.current = null;
      })
      .finally(() => {
        if (paginationRef.current && filterSearchInput && numberList?.length < 20) {
          debounceFetchData();
        }
      });
  }, 500);

  const handleScroll = async () => {
    const element = listRef.current;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      if (paginationRef.current) {
        debounceFetchData();
      } else {
        console.log("Pagination Modal Body is NUll");
      }
    }
  };

  const handleUpdateInfo = (resp, customerDisplayNameObject) => {
    const { sourceFrom, sourceId, sourceModule, firstName, unreadCount, integId, orgMember, orgCustomer, contactId, avatar, lastName } = resp;
    setNumbersList((prevRecords) => {
      const index = prevRecords?.findIndex((obj) => obj?.orgMember === orgMember && obj?.orgCustomer === orgCustomer);
      if (index !== -1) {
        const updatedRecords = [...prevRecords];
        updatedRecords[index] = {
          ...updatedRecords[index],
          unreadCount: null,
        };
        return updatedRecords;
      }
      return prevRecords;
    });
    selectedContactId.current = contactId || DEFAULT_CONTACT_ID;

    console.log("CTC selectedContactId.current >>>>>>>>>>>>>>>>>>>>>", selectedContactId.current);
    console.log("CTC selectedContactId >>>>>>>>>>>>>>>>>>>>>", selectedContactId);
    setCustomerInfoObj((prev) => ({
      ...prev,
      displayName: sourceFrom,
      avatar: avatar,
      firstName: firstName ?? lastName,
      unreadCount: unreadCount,
      integId: integId,
      sourceId: sourceId,
      sourceModule: sourceModule,
      contactAvatar: getContactAvatar(resp, customerDisplayNameObject),
    }));
  };

  const getDisplayNameOfCustomer = (contactData) => {
    let customerNameString = [contactData?.firstName, contactData?.lastName].filter(Boolean).join(" ");
    let isPhoneNumber = false;
    if (!customerNameString) {
      isPhoneNumber = true;
      customerNameString = contactData?.orgCustomer;
    }
    let iconLetter = isPhoneNumber ? "#" : customerNameString?.slice(0, 1)?.toUpperCase();

    return {
      displayName: customerNameString,
      iconLetter: iconLetter,
      isPhoneNumber: isPhoneNumber,
    };
  };
  const fetchContactsWithQuery = () => {
    if (filterSearchInput) {
      setCustomerInfoObj(null);
      setIsChannelPresent(false);
      setSelectedContact(null);
      selectedContactId.current = null;
      fetchApi();
    }
  };

  useEffect(() => {
    if (
      numberList?.length > 0 &&
      selectedContactId.current === DEFAULT_CONTACT_ID &&
      urlParams.get("contactId") &&
      urlParams.get("service") === "teams"
    ) {
      var contact = numberList.find((obj) => obj?.contactId === urlParams.get("contactId"));
      if (contact) {
        selectedContactId.current = contact?.contactId;
        const selectedNumbers = {
          fromNumber: contact?.orgMember,
          toNumber: contact?.orgCustomer,
          integId: contact?.integId,
        };
        handleNumberSelect("", selectedNumbers);
        handleUpdateInfo(contact);
      }
    }
  }, [numberList]);

  return (
    <div className="bg-white d-flex flex-column gap-2 h-100">
      {showConversations ? (
        <>
          <Row className="px-3 d-flex justify-content-between align-items-center inbox-header">
            <Col
              className="d-flex align-items-center"
              style={{
                fontSize: 18,
                fontWeight: 500,
              }}
            >
              <span style={{ cursor: "default" }}>Conversations</span>
              {numberList?.length > 0 && <span className="numberlist-count ms-1">{numberList?.length}</span>}
            </Col>
            <Col>
              <HSButton
                size="s"
                type="primary"
                onClick={() => {
                  if (services?.length > 0) {
                    setIsChannelPresent(false);
                    setSelectedContact(null);
                    setCustomerInfoObj(null);
                    selectedContactId.current = DEFAULT_CONTACT_ID;
                    setShowChatOverlay(true);
                    newToNumberInputRef?.current?.focus();
                  }
                }}
                prefixIcon={<div className="actionIconsSprite plusIconWhite" />}
              >
                New
              </HSButton>
            </Col>
          </Row>
          <div className="d-flex gap-lg-2 align-items-center justify-content-between px-3 inbox-contact-search">
            <Input
              placeholder="Search"
              rootClassName="h-100"
              suffix={
                <div className="h-100 d-flex align-items-center">
                  {filterSearchInput?.length > 0 && (
                    <div className="d-flex align-items-center">
                      <div
                        className="actionIconsSprite clear-search"
                        onClick={() => {
                          setFilterSearchInput("");
                          setSearchInputLoader(<div className="actionIconsSprite search-loading"></div>);
                        }}
                      ></div>
                      <Button
                        type="default"
                        className="hs-btn-default"
                        onClick={() => {
                          fetchContactsWithQuery();
                        }}
                      >
                        Search
                      </Button>
                    </div>
                  )}
                </div>
              }
              tabIndex={1}
              value={filterSearchInput}
              onChange={(e) => {
                setFilterSearchInput(e.target.value);
                setCustomerInfoObj(null);
                setIsChannelPresent(false);
                setSelectedContact(null);
                selectedContactId.current = DEFAULT_CONTACT_ID;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetchContactsWithQuery();
                }
              }}
              className="hs-input-box"
              variant="borderless"
            />
            <TreeSelect
              tabIndex={2}
              variant="borderless"
              suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
              defaultValue={[
                {
                  value: "all",
                  title: "All",
                },
              ]}
              placement="bottomRight"
              dropdownStyle={{ width: 180 }}
              popupMatchSelectWidth={false}
              onChange={(e) => {
                setSelectedService(e);
                setCustomerInfoObj(null);
                setIsChannelPresent(false);
                setSelectedContact(null);
                selectedContactId.current = DEFAULT_CONTACT_ID;
              }}
              treeData={[
                {
                  value: "all",
                  title: "All",
                },
                ...services,
              ]}
              className="hs-input-box h-100"
            />
          </div>
          {searchInputLoader ? (
            <div className="d-flex align-items-center justify-content-center h-100">{searchInputLoader}</div>
          ) : (
            <>
              {numberList?.length > 0 ? (
                <div
                  ref={listRef}
                  className={`hs-h-84 hs-default-list-scroll flex-grow-*`}
                  style={{ scrollBehavior: "smooth" }}
                  onScroll={handleScroll}
                >
                  <List
                    header={null}
                    footer={null}
                    dataSource={numberList}
                    className="chatList mx-2"
                    renderItem={(resp, index) => {
                      const customerDisplayNameObject = getDisplayNameOfCustomer(resp);
                      return (
                        <List.Item
                          tabIndex={index + 4}
                          value={resp.timeInMillis}
                          onClick={() => {
                            const selectedNumbers = {
                              fromNumber: resp?.orgMember,
                              toNumber: resp?.orgCustomer,
                              integId: resp?.integId,
                            };
                            handleNumberSelect(resp?.lastName, selectedNumbers);
                            handleUpdateInfo(resp, customerDisplayNameObject);
                          }}
                          className="conv-list-item px-1"
                          style={{
                            borderRadius: 6,
                            backgroundColor:
                              selectedContactId.current === resp.contactId ||
                              (!selectedContactId.current && selectedContact?.toNumber === resp.orgCustomer && resp.new)
                                ? "rgba(245, 245, 255, 1)"
                                : null,
                            padding: "12px 0px",
                            marginTop: 4,
                            borderBlockEnd: 0,
                          }}
                        >
                          <List.Item.Meta
                            className="align-items-center"
                            avatar={getContactAvatar(resp, customerDisplayNameObject)}
                            title={
                              <div className="d-flex justify-content-between">
                                <Text ellipsis style={{ fontSize: 15 }}>
                                  {customerDisplayNameObject?.displayName}
                                </Text>
                                <div style={{ fontSize: 13, whiteSpace: "nowrap" }}>
                                  {formatDate(resp.messageTime) === "Today"
                                    ? formatTime(resp.messageTime)
                                    : resp.new
                                    ? dayjs().format("hh:mm A")
                                    : formatDate(resp.messageTime)}
                                </div>
                              </div>
                            }
                            description={
                              <div className="d-grid gap-1">
                                <div className="d-flex justify-content-between me-2" style={{ color: "#68677F" }}>
                                  <span className="d-flex align-items-center" style={{ fontSize: 13 }}>
                                    <span className="me-1">
                                      {resp?.messageTagStatus === "queued" ? (
                                        <div className="actionIconsSprite contact-queue-icon"></div>
                                      ) : resp?.messageTagStatus === "sent" ? (
                                        <div className="actionIconsSprite sent-icon"></div>
                                      ) : ["failed", "undelivered"].includes(resp?.messageTagStatus) ? (
                                        <div className="actionIconsSprite contact-failure-icon"></div>
                                      ) : resp?.messageTagStatus === "delivered" ? (
                                        <div className="actionIconsSprite contact-delivered-icon"></div>
                                      ) : resp?.messageTagStatus === "read" ? (
                                        <div className="actionIconsSprite contact-read-icon"></div>
                                      ) : null}
                                    </span>
                                    <span
                                      className={
                                        resp?.messageTagType && !resp.messageTagType.includes("dummy") && !resp.messageTagType.includes("text")
                                          ? `me-1 actionIconsSprite contact-${
                                              resp.messageTagType.includes("gif")
                                                ? "gif"
                                                : resp.messageTagType.includes("video")
                                                ? "video"
                                                : resp.messageTagType.includes("image")
                                                ? "image"
                                                : "file"
                                            }-icon`
                                          : ""
                                      }
                                    ></span>
                                    <Text ellipsis style={{ maxWidth: "100%", minWidth: "50%" }}>
                                      {resp.messageTag ? (
                                        <>{resp?.messageTag?.length > 19 ? `${resp?.messageTag?.slice(0, 19)}...` : resp?.messageTag}</>
                                      ) : resp?.messageTagType?.includes("gif") ? (
                                        "GIF"
                                      ) : resp?.messageTagType?.includes("video") ? (
                                        "Video"
                                      ) : resp?.messageTagType?.includes("image") ? (
                                        "Image"
                                      ) : (
                                        resp?.messageTagType && !resp?.messageTagType?.includes("dummy") && "File"
                                      )}
                                    </Text>
                                  </span>
                                  {resp?.unreadCount > 0 &&
                                    !(selectedContact?.toNumber === resp.orgCustomer && selectedContact?.fromNumber === resp?.orgMember) && (
                                      <Tag
                                        className="rounded-5 border-0 h-100"
                                        style={{
                                          backgroundColor: "#2ED47A",
                                          color: "#fff",
                                        }}
                                      >
                                        {resp?.unreadCount}
                                      </Tag>
                                    )}
                                  {resp.new && (
                                    <Tag
                                      className="rounded-5 border-0 h-100"
                                      style={{
                                        backgroundColor: "#FEF5EA",
                                        color: "#1C1D22",
                                      }}
                                    >
                                      {resp.new}
                                    </Tag>
                                  )}
                                </div>
                              </div>
                            }
                          />
                        </List.Item>
                      );
                    }}
                  />
                </div>
              ) : (
                <>
                  {!searchNoContactContent ? (
                    <div className="d-flex m-auto flex-column justify-content-center align-items-center">
                      <div className="staticIconsSprite inboxNumberListEmpty-icon"></div>
                      <span className="p-2" style={{ color: "#606060" }}>
                        Your inbox is empty
                      </span>
                      <span style={{ color: "#8B8D97" }}>New message will show up here</span>
                    </div>
                  ) : (
                    <div className="d-flex m-auto flex-column justify-content-center align-items-center">
                      <span className="p-2" style={{ color: "#606060" }}>
                        No matches found.
                      </span>
                      <span style={{ color: "#8B8D97" }}>
                        <span
                          className=""
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFilterSearchInput("");
                          }}
                        >
                          Clear
                        </span>
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <div className="d-flex h-100 align-items-center justify-content-center">
          <div className="actionIconsSprite search-loading"></div>
        </div>
      )}
    </div>
  );
}

function getDefaultAvatar() {
  return <div className="h-100 w-100 avatarIcon rounded-circle staticIconsSprite" />;
}

function getContactAvatar(resp, customerDisplayNameObject) {
  return (
    <div className="position-relative">
      <div className="ms-1 hs-bg-light-orange rounded-circle d-flex align-items-center justify-content-center list-row-avatar">
        {resp?.avatar ? (
          <Avatar rootClassName="w-100 h-100" src={resp?.avatar} />
        ) : !customerDisplayNameObject?.isPhoneNumber ? (
          <span className="hs-color-dark-orange hs-fw-600 hs-fs-18">{customerDisplayNameObject?.iconLetter}</span>
        ) : (
          getDefaultAvatar()
        )}
      </div>
      {resp?.sourceFrom && (
        <div className="contact-service-indicator-outer-circle">
          <ServiceIndicationIcon serviceName={resp?.sourceFrom} />
        </div>
      )}
    </div>
  );
}

const formatDate = (date) => {
  const today = dayjs().format("YYYY-MM-DD");
  const yesterday = dayjs().subtract(1, "days").format("YYYY-MM-DD");
  if (date) {
    date = dayjs(date).format("YYYY-MM-DD");
    if (date === today) {
      return "Today";
    } else if (date === yesterday) {
      return "Yesterday";
    } else {
      return dayjs(date).format("D MMM, YYYY");
    }
  }
};

const formatTime = (time) => {
  if (time) {
    let date = dayjs(time);
    return date.format("h:mm A");
  }
};

const convertURLsToLinks = (obj, isMMS) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = obj?.message?.split(urlRegex);
  return parts?.map((part, index) => {
    if (part?.match(urlRegex)) {
      return (
        <a
          // style={{
          //   color: obj?.direction === 2 && !isMMS && !["failed", "undelivered"].includes(obj?.messageStatus || obj?.status) ? "#fff" : "black",
          // }}
          key={index}
          href={part}
          target="_blank"
          rel="noreferrer"
        >
          {part}
        </a>
      );
    } else {
      return part;
    }
  });
};
