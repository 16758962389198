import dayjs from "dayjs";

function fromNow(createdTime) {
  const now = new Date();
  const past = new Date(createdTime);
  const diffInSeconds = Math.floor((now - past) / 1000);

  const isFuture = diffInSeconds < 0;
  const absoluteDiffInSeconds = Math.abs(diffInSeconds);

  if (absoluteDiffInSeconds < 60) return `${absoluteDiffInSeconds} second${absoluteDiffInSeconds === 1 ? "" : "s"}${isFuture ? "" : " ago"}`;
  const diffInMinutes = Math.floor(absoluteDiffInSeconds / 60);
  if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes === 1 ? "" : "s"}${isFuture ? "" : " ago"}`;
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours} hour${diffInHours === 1 ? "" : "s"}${isFuture ? "" : " ago"}`;
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 7) return `${diffInDays} day${diffInDays === 1 ? "" : "s"}${isFuture ? "" : " ago"}`;
  const diffInWeeks = Math.floor(diffInDays / 7);
  if (diffInWeeks < 4) return `${diffInWeeks} week${diffInWeeks === 1 ? "" : "s"}${isFuture ? "" : " ago"}`;
  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) return `${diffInMonths} month${diffInMonths === 1 ? "" : "s"}${isFuture ? "" : " ago"}`;
  const diffInYears = Math.floor(diffInDays / 365);
  return `${diffInYears} year${diffInYears === 1 ? "" : "s"}${isFuture ? "" : " ago"}`;
}

function checkGivenDateIsAfterCurrentDate(date) {
  return dayjs(date).isAfter(dayjs(), "minutes");
}

function getMomentDate(targetDate) {
  return dayjs(new Date(targetDate));
}

function getHSFormattedDateString(targetDate) {
  return dayjs(targetDate).format("DD MMM, YYYY hh:mm");
}

const getIsoFormattedDateAndTime = (date, time) => {
  const dateAndTimeStringInLocal = dayjs(date).format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss");
  const dateObj = new Date(dateAndTimeStringInLocal);
  return dateObj.toISOString();
};

export { checkGivenDateIsAfterCurrentDate, getHSFormattedDateString, getIsoFormattedDateAndTime, getMomentDate, fromNow };
