import React from "react";
import { Card, Col, Divider, Row } from "antd";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  plugins,
} from "chart.js";
import "../assets/css/analytics.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const VoiceAnalytics = () => {
  const labels = [
    "Oct 15",
    "Oct 19",
    "Oct 23",
    "Oct 27",
    "Nov 01",
    "Nov 04",
    "Nov 09",
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "My First Dataset",
        data: [1, 2004, 537, 1000, 537, 2000, 1500],
        fill: true,
        borderColor: "#5551ff",
        backgroundColor: `rgba(85, 85, 255, 0.1)`,
        tension: 0.5,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 4,
        borderCapStyle: "round",
      },
    ],
  };

  const options = {
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: {
        ticks: {
          maxTicksLimit: 7,
          color: "black",
          fontSize: 16,
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },

        ticks: {
          color: "black",
          fontSize: 14,
        },
      },
    },
  };

  return (
    <div className="voiceAnalytics">
      <Row gutter={0}>
        <Col span={24}>
          <Card className="analyticsCard">
            <Row justify="space-around" align="middle" className="metricRow">
              <Col className="">
                <div className="callsMetricItem">
                  <div className="totalCallsIcon" />
                  <div className="ms-1">
                    <div className="callsMetricText">Total Calls</div>
                    <div className="callsMetricNum">1900</div>
                  </div>
                </div>
              </Col>
              <Divider
                type="vertical"
                style={{ height: "60px", marginTop: "20px" }}
              />
              <Col>
                <div className="callsMetricItem">
                  <div className="inboundIcon" />
                  <div className="ms-1">
                    <div className="callsMetricText">Inbound</div>
                    <div className="callsMetricNum">964</div>
                  </div>
                </div>
              </Col>
              <Divider
                type="vertical"
                style={{ height: "60px", marginTop: "20px" }}
              />
              <Col>
                <div className="callsMetricItem">
                  <div className="outboundIcon" />
                  <div className="ms-1">
                    <div className="callsMetricText">Outbound</div>
                    <div className="callsMetricNum">537</div>
                  </div>
                </div>
              </Col>
              <Divider
                type="vertical"
                style={{ height: "60px", marginTop: "20px" }}
              />
              <Col>
                <div className="callsMetricItem">
                  <div className="missedCallIcon" />
                  <div className="ms-1">
                    <div className="callsMetricText">Missed Call</div>
                    <div className="callsMetricNum">17</div>
                  </div>
                </div>
              </Col>
              <Divider
                type="vertical"
                style={{ height: "60px", marginTop: "20px" }}
              />
              <Col>
                <div className="callsMetricItem">
                  <div className="voiceMailIcon" />
                  <div className="ms-1">
                    <div className="callsMetricText">Voicemails</div>
                    <div className="callsMetricNum">180</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <div className="d-flex  flex-row justify-content-between mt-4 mb-2">
          <span className="hs-fs-18 hs-fw-500 mt-2 hs-me-750" >
            Statistics for selected Period
          </span>
          <span className="mt-3 hs-color-violet fs-6 hs-fw-500">
            Last 30 Days
          </span>
        </div>
      </Row>
      <Row>
        {/* <Col span={24}> */}
        <Card className="chartCard">
          <div className="chartContainer">
            <Line data={data} options={options} className="align-self-center" />
          </div>
        </Card>

        {/* </Col> */}
      </Row>
    </div>
  );
};

export default VoiceAnalytics;