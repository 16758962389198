import React, { useEffect } from "react";
import { Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import CustomerInformationComponent from "../common/CustomerInformationComponent";

const CallSaveAndClose = (props) => {
  let {
    module,
    customerNumber,
    callNotes,
    setCallNotes,
    handleSaveAndCloseClick,
    totalCallDuration,
    saveAndCloseClicked,
    callerImageComponent,
    searchValue,
    lastName,
    appProperties,
    recordId,
    recordLink,
    skeletonImage,
    skeletonField,
    avatar,
    callProperties,
    callNotesFormName,
    customerInfoDetails,
  } = props;

  const validTotalCallDuration = !isNaN(totalCallDuration) ? totalCallDuration : 0;
  const hours = Math.floor(validTotalCallDuration / 3600);
  const minutes = Math.floor((validTotalCallDuration % 3600) / 60);
  const remainingSeconds = validTotalCallDuration % 60;

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <div className="h-100 overflow-auto">
      <CustomerInformationComponent
        callerImageComponent={callerImageComponent}
        lastName={lastName}
        module={module}
        searchValue={searchValue}
        appProperties={appProperties}
        recordId={recordId}
        recordLink={recordLink}
        customerNumber={customerNumber}
        skeletonImage={skeletonImage}
        skeletonField={skeletonField}
        avatar={avatar}
        callProperties={callProperties}
        showEndCallBtn={false}
        customerInfoDetails={customerInfoDetails}
      />

      <div className="d-flex justify-content-center w-100 hs-h-7">
        <div className="text-white  d-flex justify-content-center hs-fs-16  hs-fw-500">
          {formatTime(hours)}:{formatTime(minutes)}:{formatTime(remainingSeconds)}
        </div>
      </div>
      <div className="ps-4 pe-4 hs-h-45 ">
        <Form form={callNotesFormName} name={"callDescription"} rootClassName="h-100 w-100 call-textarea">
          <Form.Item name="callDescription" initialValue={callNotes} rootClassName="h-100 w-100">
            <textarea className="w-100 h-100 hs-border-16 p-2 ps-3" placeholder="Enter Call Description" />
          </Form.Item>
        </Form>
      </div>
      <div className="w-100 hs-h-20 d-flex align-items-center justify-content-center">
        <Button className="notes-save-button text-white" onClick={() => handleSaveAndCloseClick(callNotes)} loading={saveAndCloseClicked}>
          Save and Close
        </Button>
      </div>
    </div>
  );
};

export default CallSaveAndClose;
