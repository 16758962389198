import React, { useState, useEffect } from "react";
import { useVoiceContext } from "../../context/VoiceContext";

function Timer(props) {
  const [callProperties, setCallProperties] = useVoiceContext();
  const [duration, setDuration] = useState(0);
  let { onDurationEnd, setTotalCallDuration, totalCallDuration, callEnded, setCallEnded } = props;
  useEffect(() => {
    const intervalId = setInterval(() => {
      setDuration((prevSeconds) => prevSeconds + 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [duration]);

  useEffect(() => {
    console.log("timerset IOPGGchanged:", callEnded);
    if (callEnded) {
      console.log("Twilio-Event call IOPGG ended inside timerset >>>>>>>>>>>>");
      setTotalCallDuration(duration);
      setCallProperties((prev) => ({
        ...prev,
        disableAgentStatusChange: true,
        callStatus: "save-close",
      }));
    }
    setCallEnded(false);
  }, [callEnded]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const remainingSeconds = duration % 60;
  return (
    <div>
      {formatTime(hours)}:{formatTime(minutes)}:{formatTime(remainingSeconds)}
    </div>
  );
}

export default Timer;
