import ZohoCRM from "./ZohoCRMController";
import { ZOHO_CRM_API_VARIABLE_NAME } from "./../constants/AppConstants";

const ZohoCRMEmbedded = {
  init: function (setAppProperties) {
    return new Promise((resolve, reject) => {
      window?.ZOHO?.embeddedApp?.on("Dial", function (data) {
        // You can add additional logic here, such as logging or triggering other actions
        let callToNumber = data?.Code + data?.Number;
        let associatedObjectId = data?.EntityID;
        let associatesObjectType = data?.EntityType;
        if (callToNumber) {
          if (setAppProperties) {
            setAppProperties((prev) => {
              return {
                ...prev,
                callerNumber: callToNumber,
                callRecordId: associatedObjectId,
                callRecordModule: associatesObjectType,
                callType: "outbound",
              };
            });
          }
          ZohoCRMEmbedded.showCallNotification(data, "outbound");
        }
      });

      window?.ZOHO?.embeddedApp?.on("DialerActive", function (data) {
        console.log("CTC tukzer_unit DialerActive >>>>>>>>>", data);
      });

      window?.ZOHO?.embeddedApp.on("PageLoad", function (data) {
        ZohoCRM.selectedEntityModuleFromZohoCRM = data?.Entity;
        if (data?.module) {
          ZohoCRM.selectedEntityModuleFromZohoCRM = data?.module;
        }
        if (setAppProperties) {
          setAppProperties((prev) => {
            return { ...prev, loadedSDK: data };
          });
        }

        ZohoCRM.selectedEntityIdsFromZohoCRM = data?.EntityId;
        if (data?.EntityId) {
          ZohoCRM.serviceInitialized = true;
        }
        ZohoCRM.configData = data?.configdata?.parameters;
        resolve(data);
      });

      window?.ZOHO?.embeddedApp?.init().then(function () {
        console.log("tukzer_unit >>>>>> :::::::::::: EmbeddedApp inited successfully >> ", setAppProperties, window.location.pathname);
        if (setAppProperties) {
          setAppProperties((prev) => {
            return { ...prev, serviceInitialized: true };
          });
        }
      });
    });
  },
  showCallNotification: function (pipedriveSDK, callState, setAppProperties) {
    return new Promise((resolve, reject) => {
      if (callState === "inbound") {
        window?.ZOHO?.CRM?.UI?.Dialer.notify();
      }
      window?.ZOHO?.CRM?.UI?.Dialer.maximize();
    });
  },
  hideCallNotification: function (pipedriveSDK) {
    return new Promise((resolve, reject) => {
      window?.ZOHO?.CRM?.UI?.Dialer.minimize();
    });
  },
  setWebhook: function (webhookUrl, webhookId, appProperties, integId) {
    return new Promise((resolve, reject) => {
      var parameters = {
        selectedId: "${!" + appProperties.module + ".Id}",
        module: appProperties.module,
        eventId: webhookId,
        integId: integId,
      };
      var customActionValueToBeStored = {
        parameters: JSON.stringify(parameters),
      };
      customActionValueToBeStored.url = webhookUrl;
      window.ZOHO.CRM.ACTION.setConfig(customActionValueToBeStored);
    });
  },
  getWebhook: function () {
    return new Promise((resolve, reject) => {
      resolve(ZohoCRM.configData);
    });
  },
  getUserData: function (isUserHashNeeded) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.CONFIG.getCurrentUser()
        .then(function (data) {
          window.ZOHO.CRM.CONFIG.getOrgInfo()
            .then(function (dataOrg) {
              var companyId = dataOrg.org[0].zgid;
              var emailId = data.users[0].email;
              var userId = data.users[0].zuid;
              var companyOrgId = companyId;

              var userData = {
                companyId: companyId,
                userId: userId,
                companyOrgId: companyOrgId,
                emailId: emailId,
              };
              if (isUserHashNeeded) {
                ZohoCRMEmbedded.getApplicationToken(userId).then(function (userHash) {
                  if (userHash) {
                    userData["userHash"] = userHash;
                  } else {
                    userData["userHash"] = "-";
                  }
                  resolve(userData);
                });
              } else {
                resolve(userData);
              }
            })
            .catch(function (err) {
              reject(err);
            });
        })
        .catch(function (err) {
          console.log("handleFetchTemplates getUserData catch >>>>>>>>>>", err);
        });
    }).catch(function (err) {
      console.log("handleFetchTemplates whole catch >>>>>>>>>>", err);
    });
  },
  getFilterObjectForShrinkView: function () {
    return new Promise((resolve, reject) => {
      resolve({});
    });
  },
  saveApplicationToken: function (hashToBeSaved) {
    return new Promise((resolve, reject) => {
      var customVariableApiName = ZOHO_CRM_API_VARIABLE_NAME + "hash";
      ZohoCRMEmbedded.get(customVariableApiName)
        .then(function (userHashObjectStoredFromZohoCRM) {
          window.ZOHO.CRM.CONFIG.getCurrentUser()
            .then(function (data) {
              var userId = data.users[0].zuid;
              let promiseArray = [];
              var userHashJson = {};
              if (typeof userHashObjectStoredFromZohoCRM == "string") {
                try {
                  userHashJson = JSON.parse(userHashObjectStoredFromZohoCRM);
                } catch (e) {
                  console.log(e);
                }
              }

              if (userHashJson === undefined || userHashJson === "") {
                userHashJson = {};
              }
              userHashJson[userId] = hashToBeSaved;
              var customVariableJson = {
                apiname: customVariableApiName,
                value: userHashJson,
              };

              promiseArray.push(saveCRMData(customVariableJson));
              Promise.all(promiseArray).then(function () {
                resolve();
              });
            })
            .catch(function (err) {
              reject(err);
            });
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  get: function (key) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.API.getOrgVariable(key)
        .then(function (response) {
          if (typeof response == "string") {
            response = JSON.parse(response);
          }
          var successResp = response.Success;
          if (typeof successResp == "string") {
            successResp = JSON.parse(successResp);
          }

          var contentObj = successResp?.Content;
          if (contentObj) {
            resolve(contentObj);
          } else {
            resolve("");
          }
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  getApplicationToken: function (userId) {
    return new Promise((resolve, reject) => {
      var customVariableApiName = ZOHO_CRM_API_VARIABLE_NAME + "hash";
      ZohoCRMEmbedded.get(customVariableApiName)
        .then(function (userHashObjectStoredFromZohoCRM) {
          if (userHashObjectStoredFromZohoCRM) {
            var userHashJson = {};
            if (typeof userHashObjectStoredFromZohoCRM == "string") {
              try {
                userHashJson = JSON.parse(userHashObjectStoredFromZohoCRM);
              } catch (e) {
                console.log(e);
              }
            }

            if (userHashJson === undefined || userHashJson === "") {
              userHashJson = {};
            }
            if (userId in userHashJson) {
              var hash = userHashJson[userId];
              resolve(hash);
            } else {
              resolve("");
            }
          } else {
            resolve("");
          }
        })
        .catch(function (err) {
          console.log("h1s-token-zoho-crm :: yet-to-save :: getApplicationToken ::  final catch  else 2ee>>>>>", err);
          resolve("");
        });
    });
  },
  openRecord: function (sdk, id, module) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.UI.Record.open({ Entity: module, RecordID: id }).then(function (data) {
        resolve(data);
      });
    });
  },
  populateRecord: function (sdk, id, module, phone) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getRecordById: function (sdk, id, module) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.API.getRecord({
        Entity: module,
        RecordID: id,
      }).then(function (record) {
        resolve(record?.data?.[0]);
      });
    });
  },
  fetchContact: function (fetchData, appProperties, phoneFieldConfiguration) {
    return new Promise(async (resolve, reject) => {
      if (ZohoCRM.selectedEntityModuleFromZohoCRM) {
        var contactNumbersObj = await fetchContactFromZohoCRM(fetchData, appProperties, phoneFieldConfiguration);
        resolve(contactNumbersObj);
      }
    });
  },
  getAssociatedObjectId: function () {
    return new Promise((resolve, reject) => {
      if (ZohoCRM.selectedEntityIdsFromZohoCRM) {
        if (typeof ZohoCRM.selectedEntityIdsFromZohoCRM === "object") {
          if (ZohoCRM.selectedEntityIdsFromZohoCRM != undefined && ZohoCRM.selectedEntityIdsFromZohoCRM.length > 0) {
            if (ZohoCRM.selectedEntityIdsFromZohoCRM.length > 1) {
              resolve(ZohoCRM.selectedEntityIdsFromZohoCRM.map((val) => val).join(","));
            } else {
              resolve(ZohoCRM.selectedEntityIdsFromZohoCRM[0]);
            }
          } else {
            resolve("");
          }
        } else {
          resolve(ZohoCRM.selectedEntityIdsFromZohoCRM);
        }
      } else {
        resolve("");
      }
    });
  },
  getAssociatedObjectType: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(ZohoCRM.selectedEntityModuleFromZohoCRM);
    });
  },
};

function saveCRMData(parameterMap) {
  return new Promise((resolve, reject) => {
    window.ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", parameterMap)
      .then(function (data) {
        resolve(data);
      })
      .catch(function (err) {
        console.log("hs-token-zoho-crm :: saveCRMData >>>>>> ERRROR>>>>>>> saved_after_err", err);
      });
  });
}
const fetchContactFromZohoCRM = async (fetchData, appProperties, phoneFieldConfiguration) => {
  try {
    const numbersList = [];
    let {
      primaryPhoneApiName,
      secondaryPhoneApiName,
      secondaryPhoneTwoApiName,
      primaryPhoneLookUp,
      secondaryPhoneLookUp,
      secondaryPhoneTwoLookUp,
    } = phoneFieldConfiguration?.data?.config || {};

    const records = await window.ZOHO.CRM.API.getRecord({
      Entity: ZohoCRM.selectedEntityModuleFromZohoCRM,
      RecordID: ZohoCRM.selectedEntityIdsFromZohoCRM,
    });

    const hasAssociatedModule = primaryPhoneLookUp || secondaryPhoneLookUp || secondaryPhoneTwoLookUp;

    if (hasAssociatedModule) {
      const phoneRecords = await getAllAssociatedModulesPhoneRecords(
        primaryPhoneLookUp,
        secondaryPhoneLookUp,
        secondaryPhoneTwoLookUp,
        records,
        primaryPhoneApiName,
        secondaryPhoneApiName,
        secondaryPhoneTwoApiName,
        numbersList
      );
      return phoneRecords;
    } else {
      const phoneRecords = await assemblePhoneRecords(
        records,
        primaryPhoneApiName,
        secondaryPhoneApiName,
        secondaryPhoneTwoApiName,
        numbersList
      );
      return phoneRecords;
    }
  } catch (err) {
    throw err;
  }
};

function assemblePhoneRecords(records, primaryPhoneApiName, secondaryPhoneApiName, secondaryPhoneTwoApiName, numbersList) {
  records?.data?.forEach((recordObj) => {
    const recordInfoObj = {
      id: recordObj["id"],
      module: ZohoCRM.selectedEntityModuleFromZohoCRM,
    };

    let phoneApiName = "Phone";
    if (primaryPhoneApiName) {
      phoneApiName = primaryPhoneApiName;
    }
    let phoneRecord = recordObj[phoneApiName];
    if (phoneRecord) {
      recordInfoObj.phone = phoneRecord;
      numbersList.push(recordInfoObj);
    } else {
      phoneApiName = secondaryPhoneApiName;
      phoneRecord = recordObj[phoneApiName];
      if (phoneRecord) {
        recordInfoObj.phone = phoneRecord;
        numbersList.push(recordInfoObj);
      }
    }
  });
  return Promise.resolve(numbersList);
}

async function getAllAssociatedModulesPhoneRecords(
  primaryPhoneLookUp,
  secondaryPhoneLookUp,
  secondaryPhoneTwoLookUp,
  records,
  primaryPhoneApiName,
  secondaryPhoneApiName,
  secondaryPhoneTwoApiName,
  numbersList
) {
  const fields = await ZohoCRM.getFields(ZohoCRM.selectedEntityModuleFromZohoCRM);

  const fetchAssociatedPhoneRecords = async (lookup, apiName) => {
    const field = fields.fields.find((field) => field.api_name === lookup);
    const module = field?.lookup?.module?.api_name;
    if (module) {
      const promises = records.data.map(async (recordObj) => {
        const recordId = recordObj[lookup]?.id;
        if (recordId) {
          const associatedModuleRecord = await window.ZOHO.CRM.API.getRecord({
            Entity: module,
            RecordID: recordId,
          });

          const recordInfoObj = {
            id: recordId,
            module: module,
          };
          const phoneValue = associatedModuleRecord?.data?.[0]?.[apiName];
          if (phoneValue) {
            recordInfoObj.phone = phoneValue;
            numbersList.push(recordInfoObj);
          }
        }
      });
      return Promise.all(promises);
    } else {
      return;
    }
  };

  await fetchAssociatedPhoneRecords(primaryPhoneLookUp, primaryPhoneApiName);
  if (numbersList.length === 0) {
    await fetchAssociatedPhoneRecords(secondaryPhoneLookUp, secondaryPhoneApiName);
  }
  if (numbersList.length === 0) {
    await fetchAssociatedPhoneRecords(secondaryPhoneTwoLookUp, secondaryPhoneTwoApiName);
  }

  return numbersList;
}

export default ZohoCRMEmbedded;
