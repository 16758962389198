import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Col, Divider, Input, Row, Select, Spin, Tag } from "antd";
import { AppContext } from "../../context/AppContext";
import ZohoCRM from "../../controller/ZohoCRMController";
import useHttp from "../../hooks/useHttp";
import useTemplates from "../../hooks/useTemplates";
import { getLabelForTemplate, RefreshButton } from "../utils/CommonVessels";

export const SavedTemplatesSelect = React.memo((props) => {
  const {
    setTemplateId,
    templateId,
    setMessageAreaLoading,
    handleMessage,
    tabIndex,
    moduleName,
    showPreview,
    fetchOnlyByModule,
    handleMedia,
    showOnlyPopup,
    suffixIcon,
    open,
    setOpen,
    groupBy = "",
    setPreviewResponse,
    toNumber = "",
    chatView,
    integrationId,
  } = props;

  const [appProperties] = useContext(AppContext);
  const [savedTemplatesList, setSavedTemplatesList] = useState([]);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [templateApi, setTemplateApi] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentModule, setCurrentModule] = useState(moduleName);
  const { fetchSavedTemplates, fetchSavedTemplatesByModule } = useTemplates();
  const { fetchData } = useHttp();

  function handleTemplateId(templateIdSelected) {
    if (templateIdSelected === "add::::Templates") {
      appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (redirectionUrl) {
        if (moduleName) {
          let templateUrlLink = "/templates?" + redirectionUrl + "&resource=" + (moduleName ?? "") + "&openTemplate=true";
          window.open(templateUrlLink, "_blank");
        } else {
          appProperties?.controller?.getAssociatedObjectType().then(function (moduleName) {
            let templateUrlLink = "/templates?" + redirectionUrl + "&resource=" + (moduleName ?? "") + "&openTemplate=true";
            window.open(templateUrlLink, "_blank");
          });
        }
      });
    }
  }
  function handleTemplateLoading(templateToBeLoaded) {
    setTemplateLoading(templateToBeLoaded);
  }

  const handleOnChange = (v) => {
    handleTemplateLoading(true);
    setTemplateId(v);
    let selectedTemplateEntity = savedTemplatesList.find((item) => item.templateId.toLowerCase().includes(v));
    previewTemplate(v, selectedTemplateEntity?.integId);
    handleMedia(selectedTemplateEntity?.mediaFileList || []);
    handleTemplateId(v);
  };

  const previewTemplate = (templateId, integId = appProperties.randomIntegId) => {
    if (templateId !== "add::::Templates") {
      setMessageAreaLoading(true);
      appProperties.controller.getAssociatedObjectType().then(function (associatedModule) {
        appProperties.controller.getAssociatedObjectId().then(function (associatedId) {
          if (!showPreview) {
            associatedId = "";
            associatedModule = "";
          }

          fetchData(
            `omessage/${integId}/template/${templateId}/preview?associatedObjectId=${associatedId ?? ""}&associatedObjectType=${
              associatedModule ?? ""
            }&phone=${toNumber && !chatView ? encodeURIComponent(toNumber) : ""}`,
            "GET",
            null,
            appProperties
          )
            .then((response) => {
              const responseData = JSON.parse(response);
              setMessageAreaLoading(false);
              setTemplateLoading(false);
              if (responseData?.data?.data || responseData?.data?.mediaFileList || responseData?.data?.components) {
                setPreviewResponse && setPreviewResponse(responseData?.data);
                handleMessage(responseData?.data?.data || "");
              }
            })
            .catch((error) => {});
        });
      });
    } else {
      setTemplateLoading(false);
    }
  };

  useEffect(() => {
    if (open) handleFetchTemplates(true, true);
  }, [open]);

  useEffect(() => {
    appProperties?.controller?.getAssociatedObjectType().then(function (currentModuleFromApplication) {
      if (currentModuleFromApplication) {
        setCurrentModule(currentModuleFromApplication);
      }
    });
  }, [appProperties?.controller, ZohoCRM?.serviceInitialized]);

  const mapOptions = useCallback((listOfOptions) => {
    if (!groupBy) {
      return listOfOptions.map((option) => {
        return option;
      });
    }

    const groupedItems = {};

    // Group items by category

    listOfOptions.forEach((option) => {
      const groupByKey = option[groupBy] ?? "application";

      if (!groupedItems[groupByKey]) {
        groupedItems[groupByKey] = [];
      }

      groupedItems[groupByKey].push(option);
    });

    // Create the final list in the desired format

    const groupedOptions = Object.keys(groupedItems).map((category) => ({
      label: <Tag className="select-group-tag">{category}</Tag>,
      title: category,
      options: groupedItems[category],
    }));

    return groupedOptions;
  }, []);

  function filterWhatsappTemplatesForNonWhatsappServices(templatesData) {
    const isWhatsappService =
      appProperties?.installedApps?.find((installedApp) => installedApp?.integProps?.integId === integrationId)?.right?.service_name ===
      "whatsapp";

    if (!isWhatsappService) {
      templatesData = templatesData?.filter((template) => template?.templateType !== "whatsapp");
    }
    return templatesData;
  }

  const handleFetchTemplates = async (refresh, templatesSelectOpen) => {
    if ((!templateApi && templatesSelectOpen) || (refresh && templatesSelectOpen)) {
      setSearchTerm("");
      let moduleToFetch = moduleName || currentModule;
      if (moduleToFetch) {
        setTemplateLoading(true);
        fetchSavedTemplatesByModule(appProperties, moduleToFetch).then((responseData) => {
          var templatesData = [];

          if (responseData?.data) {
            templatesData = responseData.data;
          }
          setTemplateLoading(false);
          templatesData = filterWhatsappTemplatesForNonWhatsappServices(templatesData);
          setSavedTemplatesList(templatesData);
          setTemplateApi(true);
        });
      } else if (!fetchOnlyByModule) {
        setTemplateLoading(true);
        fetchSavedTemplates(appProperties)
          .then((responseData) => {
            var templatesData = [];

            if (responseData?.data) {
              templatesData = responseData.data;
            }

            templatesData = filterWhatsappTemplatesForNonWhatsappServices(templatesData);

            setTemplateLoading(false);
            setSavedTemplatesList(templatesData);
            setTemplateApi(true);
          })
          .finally(() => setTemplateLoading(false));
      }
    }
  };

  let templatesOption = useMemo(() => {
    let savedTemplatesListWithEyeLabel = [];
    if (savedTemplatesList) {
      savedTemplatesList.forEach((item) => {
        if (item.friendlyName.toLowerCase().includes(searchTerm.toLowerCase())) {
          item.value = item.templateId;
          item.label = getLabelForTemplate(item?.friendlyName, item?.templateType, item?.content);
          savedTemplatesListWithEyeLabel.push(item);
        }
      });
    }
    return savedTemplatesListWithEyeLabel;
  }, [savedTemplatesList, searchTerm]);

  const handleFetchTemplatesButton = (e) => {
    handleFetchTemplates("refresh", e);
  };

  const getSuffixIcon = () => {
    if (showOnlyPopup) {
      return <></>;
    }

    if (templateLoading) {
      return <div className="actionIconsSprite dropDownLoading" />;
    }

    return suffixIcon ?? <div className="actionIconsSprite templatesDropdownIcon" />;
  };

  const handleDropDownVisibleChange = (visible) => {
    setOpen && setOpen(visible);
  };

  const templateSearchInputRef = useRef(null);

  useEffect(() => {
    if (open && templateSearchInputRef.current) {
      templateSearchInputRef.current.focus({
        preventScroll: true,
      });
    }
  }, [open, templateSearchInputRef.current, savedTemplatesList]);

  const selectedTemplateId = useMemo(() => {
    const matchedTemplate = savedTemplatesList.find((item) => item.templateId === templateId);
    return matchedTemplate?.templateId || null;
  }, [savedTemplatesList, templateId]);

  return (
    <Select
      tabIndex={tabIndex}
      // open={true}
      variant="borderless"
      className={`savedTemplateSelect ${showOnlyPopup ? "hs-select-show-only-popup" : null} hs-saved-template-select w-100`}
      open={open}
      dropdownStyle={{ width: 300 }}
      placeholder={<div className="hs-fs-small">Choose template</div>}
      value={selectedTemplateId}
      options={mapOptions(templatesOption)}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").toString().includes(input)}
      suffixIcon={getSuffixIcon()}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toString()
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toString().toLowerCase())
      }
      // onBlur={() => {
      //   handleDropDownVisibleChange(false);
      // }}
      onDropdownVisibleChange={handleDropDownVisibleChange}
      dropdownRender={(menu) => (
        <>
          <div className="p-2 pb-0">
            <Row className="pe-2">
              <Col span={18} className="pt-1" style={{ fontWeight: 600 }}>
                Templates
              </Col>
              <Col span={3}>
                <RefreshButton onClickFunction={handleFetchTemplatesButton} loading={templateLoading} />
              </Col>
              <Col span={2}>
                <div onClick={() => handleTemplateId("add::::Templates")} className="btn mt-0 pt-0">
                  <div className="actionIconsSprite addTemplatePlusIcon" />
                </div>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="px-1">
            <div className="d-flex mt-1 mb-1 ">
              <Input
                size="small"
                className="rounded"
                placeholder={"Search template"}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                variant="borderless"
                ref={templateSearchInputRef}
              />
            </div>
          </div>
          <Divider />
          <div className="d-flex flex-column">
            <Spin indicator={<LoadingOutlined spin />} spinning={templateLoading}>
              {menu}
            </Spin>
          </div>
        </>
      )}
      onSelect={(v) => handleOnChange(v)}
      notFoundContent={
        <div className="hs-h-80-px d-grid align-items-center justify-content-center">
          {templateLoading ? <LoadingOutlined spin /> : "No templates!"}
        </div>
      }
      listHeight={150}
    />
  );
});

export default SavedTemplatesSelect;
