import { Form, message, Switch } from "antd";
import React, { useEffect } from "react";
import HButton from "../../../components/custom/input/button/Button";
import Input from "../../../components/custom/input/input/Input";

const PhoneNumberNode = ({ id, data, nodeId, callFlowId, number, phoneNumberData, setPhoneNumberData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("fallbackNumber", phoneNumberData?.fallBackNumber);
  }, [phoneNumberData]);

  const handleApply = async () => {
    if (phoneNumberData?.fallBackNumber) {
      setPhoneNumberData({
        ...phoneNumberData,
        apply: true,
      });
      message.success("Applied Successfully");
    }
  };

  const handleVoiceMailStatusChange = (currentStatus) => {
    setPhoneNumberData({
      ...phoneNumberData,
      voiceMailStatus: currentStatus ? 1 : 0,
      apply: true,
    });
  };

  return (
    <>
      <div>
        <label className="hs-fs-16 hs-fw-500 mb-3">Select Phone for this Call flow.</label>
        <br />
        <Form form={form}>
          <Input
            formItemLabel="Fallback Number"
            placeholder="Fallback Number"
            required={true}
            formItemName="fallbackNumber"
            onChange={(e) =>
              setPhoneNumberData({
                ...phoneNumberData,
                fallBackNumber: e.target.value,
              })
            }
            validator={(_, value) => {
              const phonePattern = /^\+?[0-9\s-]{10,15}$/;
              if (!value) {
                return Promise.reject(new Error("Phone number is required."));
              }
              if (!phonePattern.test(value)) {
                return Promise.reject(new Error("Please provide a valid phone number."));
              }
              return Promise.resolve();
            }}
            value={phoneNumberData?.fallBackNumber}
          />
        </Form>

        <div className="d-flex flex-row  mb-4 mt-4 justify-content-between">
          <div>VoiceMail in Missed Calls</div>
          <Switch
            className="addPhoneSwitch"
            onChange={handleVoiceMailStatusChange}
            checked={phoneNumberData?.voiceMailStatus === 1 ? true : false}
          ></Switch>
        </div>

        {/* <div className='mt-4'>
          <label className='hs-fs-16 mb-3 '>Hold Music</label>

          <VoiceSelect onChange={handleTypeChange} options={options} value={phoneNumberData.selectType} props={props} loading={loading} fileName={phoneNumberData?.fileName} textToSpeech={phoneNumberData?.text}
            setTextToSpeech={(value) => setPhoneNumberData(prevState => ({
              ...prevState,
              text: value
            }))} url={phoneNumberData?.url} setUrl={(value) => setPhoneNumberData(prevState => ({
              ...prevState,
              url: value
            }))} optionSpecification={true} label={"Type"} />
        </div> */}

        <HButton buttonClassName="" onClick={handleApply} size="s">
          Apply
        </HButton>
      </div>
    </>
  );
};

export default PhoneNumberNode;
