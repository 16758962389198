import { config } from "../../config";
import { Button as AntdButton, Typography, Spin } from "antd";

export default function HButton(props) {
  const {
    children,
    type = "primary",
    size = "m",
    buttonClassName = "",
    prefixIcon,
    suffixIcon,
    icon,
    onClick = () => {},
    variant,
    loading,
    disabled,
  } = props;
  const buttonConfiguration = config.button ?? "";
  const buttonConfigurationByType = config.button.type[type] ?? "";
  const buttonConfigurationBySize = config.button.size[size] ?? "";
  const buttonConfigurationByVariant = config.button.variant[variant] ?? "";
  const { Text } = Typography;
  return (
    <AntdButton
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      className={`${buttonConfiguration?.customClasses} ${buttonConfigurationBySize?.customClasses} ${buttonConfigurationByType?.customClasses} ${buttonConfigurationByVariant?.customClasses} ${buttonClassName}`}
    >
      <div className="d-flex justify-content-between mx-auto gap-1">
        {prefixIcon && <>{prefixIcon}</>}
        <Text ellipsis className={`px-auto m-auto ${buttonConfigurationByType?.textCustomClasses}`}>
          {children}
        </Text>
        {suffixIcon && <>{suffixIcon}</>}
      </div>
    </AntdButton>
  );
}
