import { Popconfirm, Tooltip } from "antd";
import React from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import "../../../assets/css/commons.css";
import "../../assets/css/nodes.css";

const visible = { backgroundColor: "#C2C2C2", width: "1px", height: "1px" };
const invisible = { backgroundColor: "transparent", border: "none" };
export const CustomNode = ({ title, iconClass, id, state, content }) => {
  const { getNodes, setNodes, getEdges, setEdges, updateEdge } = useReactFlow();

  const handleDelete = () => {
    const nodes = getNodes();
    const edges = getEdges();

    const incomingEdges = edges.filter((edge) => edge.target === id);
    const outgoingEdges = edges.filter((edge) => edge.source === id);
    console.log("Incoming edges:", incomingEdges);
    console.log("Outgoing edges:", outgoingEdges);

    const secondNode = outgoingEdges.length > 0 ? nodes.find((node) => node.id === outgoingEdges[0].target) : null;
    console.log("Second node:", secondNode);

    const secondNodeIncomingEdges = edges.filter((edge) => edge.target === secondNode.id);
    const secondNodeOutgoingEdges = edges.filter((edge) => edge.source === secondNode.id);

    let nodeToDeletePosition;
    if (secondNode && secondNode.type === "AddNode") {
      nodeToDeletePosition = secondNode.position;
    } else {
      nodeToDeletePosition = nodes.find((node) => node.id === id)?.position;
    }

    if (!nodeToDeletePosition) return;

    const moveUpAmount = 130;
    const updatedNodes = nodes
      .filter((node) => node.id !== id && (!secondNode || node.id !== secondNode.id))
      .map((node) => {
        if (node.position.y > nodeToDeletePosition.y) {
          return {
            ...node,
            position: {
              ...node.position,
              y: node.position.y - moveUpAmount,
            },
          };
        }
        return node;
      });

    let updatedEdges = edges.filter(
      (edge) => edge.source !== id && edge.target !== id && (!secondNode || (edge.source !== secondNode.id && edge.target !== secondNode.id))
    );

    if (secondNode && secondNode.type === "AddNode") {
      incomingEdges.forEach((inEdge) => {
        secondNodeOutgoingEdges.forEach((outEdge) => {
          updatedEdges.push({
            id: `ethm${inEdge.source}-${outEdge.target}`,
            source: inEdge.source,
            target: outEdge.target,
            type: "CustomEdge",
          });
        });
      });
    }

    console.log("Updated nodes:", updatedNodes);
    console.log("Updated edges:", updatedEdges);

    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  return (
    <>
      <div className={`d-flex align-items-center justify-content-between customNode`}>
        <div className={iconClass} />
        <div className="d-flex  flex-column ">
          <span className="hs-fs-9-px hs-fw-500 me-3 ">{title}</span>
          <span className="hs-fs-9-px hs-fw-500 hs-color-mediumDark">
            {title === "Ring Team" ? <Tooltip title={content}>{content}</Tooltip> : content}
          </span>{" "}
        </div>
        <Popconfirm
          rootClassName="nodeDeletePopconfirm"
          showArrow={false}
          title={
            <div className="d-flex flex-row mt-1" onClick={handleDelete}>
              <div className="nodeDeleteIcon helloSendIcons" />
              <div className="text-danger">Delete</div>
            </div>
          }
          icon=""
          showCancel={false}
          okButtonProps={{ style: { display: "none" } }}
          overlayStyle={{ width: "140px" }}
        >
          {/* <span className="pb-4 pe-2 hs-color-lightgray cursor-pointer">...</span> */}
        </Popconfirm>
      </div>
    </>
  );
};

export const HandleSvg = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#C2C2C2",
          height: "4px",
          width: "4px",
          borderRadius: "50%",
        }}
      ></div>
    </>
  );
};

export const OpenNode = () => {
  return (
    <div
      style={{
        backgroundColor: "rgb(231 231 231)",
        borderRadius: "20px",
        width: "73px",
        height: "23px",
      }}
      className="d-flex align-items-center"
    >
      <Handle type="target" position={Position.Top} style={invisible} />
      <div
        style={{
          color: "rgb(145 143 143)",
          fontSize: "10px",
          paddingLeft: "17px",
        }}
      >
        Opened
      </div>
      <Handle type="source" position={Position.Bottom} style={visible} />
    </div>
  );
};

export const ClosedNode = () => {
  return (
    <div
      style={{
        backgroundColor: "rgb(231 231 231)",
        borderRadius: "20px",
        width: "73px",
        height: "23px",
      }}
      className="d-flex align-items-center"
    >
      <Handle type="target" position={Position.Top} style={invisible} />
      <div
        style={{
          color: "rgb(145 143 143)",
          fontSize: "10px",
          paddingLeft: "19px",
        }}
      >
        Closed
      </div>
      <Handle type="source" position={Position.Bottom} style={visible} />
    </div>
  );
};
export const NoAnswer = () => {
  return (
    <div
      style={{
        backgroundColor: "rgb(231 231 231)",
        borderRadius: "20px",
        width: "80px",
        height: "23px",
      }}
      className="d-flex align-items-center"
    >
      <Handle type="target" position={Position.Top} style={invisible} />
      <div
        style={{
          color: "rgb(145 143 143)",
          fontSize: "10px",
          paddingLeft: "8px",
        }}
      >
        No Answered
      </div>
      <Handle type="source" position={Position.Bottom} style={visible} />
    </div>
  );
};
