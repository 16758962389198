// import { Select } from 'antd'
import HSelect from "../../../components/custom/input/select/Select";
import React from "react";
import UploadDragger from "./UploadDragger";

const VoiceSelect = (prop) => {
  const {
    value,
    textAreaValue,
    setTextAreaValue,
    onChange,
    options,
    props,
    loading,
    fileName,
    textToSpeech,
    setTextToSpeech,
    url,
    setUrl,
    customClass,
    optionSpecification,
    label,
  } = prop;
  const handleTextToSpeech = (e) => {
    setTextAreaValue(e.target.value);
  };
  const handleUrlUpdate = (e) => {
    setUrl(e.target.value);
    setTextToSpeech("");
  };
  return (
    <div className="voiceSelect w-100">
      <HSelect
        label={label}
        customProps={{
          onSelect: onChange,
          value: value,
          className: customClass,
        }}
        defaultValue={value}
        options={options}
        enableSearch={false}
        // customProps={{
        //     className: "music-type-select typesOfPlayAudio",
        // }}
        suffixIcon={<div className="call-action-icons callDropdownIcon" />}
        onChange={onChange}
      />

      {optionSpecification &&
        (value === "textToSpeech" ? (
          <div className="mt-4">
            <label className="hs-fs-14">Text</label>
            <br />
            <textarea className="speechTextArea" placeholder="Text goes here" onChange={handleTextToSpeech} value={textAreaValue}></textarea>
          </div>
        ) : value === "playAudio" ? (
          <div className="mt-4">
            <label className="hs-fs-14">Upload Music</label>
            <UploadDragger props={props} loading={loading} fileName={fileName} />
          </div>
        ) : value === "url" ? (
          <div className="mt-4">
            <label className="hs-fs-14">Link</label>
            <br />
            <textarea className="speechTextArea" placeholder="Paste the link" onChange={handleUrlUpdate} value={url}></textarea>
          </div>
        ) : (
          ""
        ))}
    </div>
  );
};

export default VoiceSelect;
