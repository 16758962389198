import { Col, Row } from "antd";
import "../../assets/css/setting.css";

import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { getCurrentUserDetails } from "../utils/commonUtils";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const updatedSearch = "?" + params.toString();
  const [appProperties] = useContext(AppContext);
  const [hideUsers, setHideUsers] = useState(false);

  const navigateTo = (path) => {
    navigate(path + updatedSearch);
  };

  useEffect(() => {
    const user = getCurrentUserDetails(appProperties);
    console.log("log >> user >> ", user);
    setHideUsers(user?.roleId === 13);
  }, [appProperties]);
  const settingsOptions = [
    {
      key: "general",
      label: "Admin Settings",
      description:
        "Manage your email notifications and logging preferences in the general settings. Tailor these options to suit your communication and tracking needs.",
      iconClass: "generalSettingsIcon",
      onClick: () => navigateTo("/settings/general"),
    },
    {
      key: "template",
      label: "Template",
      description: "Manage and customize message templates for consistent, personalized communication.",
      iconClass: "templateSettingsIcon",
      onClick: () => navigateTo("/settings/templates"),
    },
    !hideUsers && {
      key: "users",
      label: "Users",
      description: "Manage user access and permissions to streamline team collaboration and ensure secure communication.",
      iconClass: "usersSettingsIcon",
      onClick: () => navigateTo("/settings/users"),
    },
    !appProperties?.hideChannels && {
      key: "channels",
      label: "Channels",
      description:
        "Build strong customer connections through various channels. Engage with personalized support to foster lasting relationships.",
      iconClass: "channelsSettingsIcon",
      onClick: () => navigateTo("/settings/channels"),
      settingsSecondRow: true,
    },
    {
      key: "blockedNumbers",
      label: "Block Numbers",
      description:
        "Block unwanted numbers to stop sending or receiving messages or calls, ensuring a spam-free and distraction-free communication experience.",
      iconClass: "blockedNumbersIcon",
      onClick: () => navigateTo("/settings/blockedNumbers"),
      settingsSecondRow: true,
    },
  ].filter(Boolean);

  return (
    <>
      <Row className="px-2 ms-sm-2">
        <Row className="px-2 pt-sm-5 ms-sm-4 mt-3">
          <Col span={24} className="interFontWeightMedium" style={{ fontSize: 15, color: "#111827", marginBottom: 10 }}>
            Configuration Settings
          </Col>
          <Col className="settings-description" span={24} style={{ fontSize: 12, color: "#374151" }}>
            Easily configure your settings to optimize performance. Customize the app to fit your specific needs and preferences.
          </Col>
        </Row>
        <Row className="p-sm-5">
          {settingsOptions.map((option, index) => (
            <Col
              key={option.key}
              style={{ cursor: "pointer" }}
              xs={24}
              sm={8}
              className={classNames("p-lg-3 p-2 mt-2 mt-sm-0 settingsHover rounded-3", {
                "mt-sm-4": index >= 3, // Adds top margin to the second row and beyond
              })}
              onClick={option.onClick}
            >
              <Row className="d-flex align-items-center align-items-sm-start">
                <Col span={3}>
                  <div className={`actionIconsSprite ${option.iconClass}`} />
                </Col>
                <Col xs={18} sm={21} style={{ paddingLeft: 2 }}>
                  <Row>
                    <div style={{ color: "#111827", fontSize: 14 }}>{option.label}</div>
                  </Row>
                  <Row className="interFontWeightMedium settings-description" style={{ fontSize: 12, color: "#9CA3AF" }}>
                    {option.description}
                  </Row>
                </Col>
                <Col xs={3} sm={0}>
                  <div className="actionIconsSprite right-arrow-icon"></div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Row>
    </>
  );
};

export default Settings;
