import { Steps as AntdSteps, Col, Row } from "antd";
import { useEffect, useState } from "react";
import HButton from "../../components/custom/input/button/Button";
import VideoComponent from "../../components/custom/video/VideoComponent";
import useTemplates from "../../hooks/useTemplates";
import { capitalizeFirstLetter } from "../utils/commonUtils";
import { useVoiceTeams } from "../../voice/hooks/useVoiceTeams";
import { useCallFlow } from "../../voice/hooks/useCallFlow";
import { useVoiceAgentStatus } from "../../voice/hooks/useVoiceAgentStatus";

export default function OnboardingFinishModalContent({ serviceName, appProperties }) {
  const onboardingComponents = appProperties?.providerCommonObj?.onBoardingFlow?.getFinishModalComponent(appProperties);
  const leftServiceName = appProperties?.leftServiceClientDetails?.displayName;
  console.log("leftServiceName>>>>>>>>>>>", leftServiceName, appProperties?.leftServiceClientDetails);
  const leftServiceDisplayName = appProperties?.leftServiceDisplayName;
  console.log("leftServiceName>>>>>>>>>>>", leftServiceDisplayName);

  return (
    <OnboardingModalContent
      videoGallery={<OnboardingModalContent.VideoGallery gallery={onboardingComponents?.getGallery(leftServiceName.trim())} />}
    >
      <OnboardingModalContent.Banner serviceName={serviceName} />
      <OnboardingModalContent.Steps
        steps={onboardingComponents?.steps}
        calculateStep={onboardingComponents?.calculateStep}
        appProperties={appProperties}
        serviceName={serviceName}
      />
      <OnboardingModalContent.Description
        leftServiceName={leftServiceDisplayName}
        rightServiceName={serviceName}
        appProperties={appProperties}
      />
      <OnboardingModalContent.GotoButton
        hyperLink={
          leftServiceName === "Zoho CRM" ? appProperties.domain : appProperties.domain + (appProperties?.controller?.redirectPath || "")
        }
        leftServiceName={leftServiceDisplayName}
      />
    </OnboardingModalContent>
  );
}

function OnboardingModalContent({ children, videoGallery }) {
  return (
    <Row align={"middle"}>
      <Col span={videoGallery ? 12 : 24}>
        <div className="m-4 me-0 p-4 pe-0 d-grid gap-3 h-100">{children}</div>
      </Col>
      {videoGallery && (
        <Col span={12} className="h-100">
          <div className="h-100">
            <div className="banners-sprite hs-onboarding-modal-bg">
              <div className="p-4 h-100 d-flex align-items-center py-5">{videoGallery}</div>
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
}

OnboardingModalContent.Banner = Banner;
OnboardingModalContent.Steps = Steps;
OnboardingModalContent.Description = Description;
OnboardingModalContent.VideoGallery = VideoGallery;
OnboardingModalContent.GotoButton = GotoButton;

function GotoButton({ hyperLink, leftServiceName }) {
  return (
    <HButton size="xl" buttonClassName={"hs-w-fit rounded-pill fw-bold"} onClick={() => window.open(hyperLink, "_blank")}>
      Go to {capitalizeFirstLetter(leftServiceName)}
    </HButton>
  );
}

function VideoGallery({ gallery }) {
  return (
    <div className="d-grid gap-2 my-4">
      <span className="text-white mx-auto">Video Gallery</span>
      {gallery && <VideoComponent videoObj={gallery} gap="3" />}
    </div>
  );
}

function Steps({ size = "small", direction = "vertical", calculateStep, steps, serviceName }) {
  const [step, setStep] = useState();
  const { fetchSavedTemplates } = useTemplates();
  const { getVoiceTeams } = useVoiceTeams();
  const { getCurrentVoiceAgentStatus } = useVoiceAgentStatus();
  const { getCallFlow } = useCallFlow();

  useEffect(() => {
    if (calculateStep && serviceName === "whatsapp") {
      calculateStep(fetchSavedTemplates).then((step) => {
        setStep(step);
      });
    } else if (calculateStep && serviceName === "twilio_voice") {
      calculateStep(getVoiceTeams, getCallFlow, getCurrentVoiceAgentStatus).then((step) => {
        setStep(step);
      });
    } else if (calculateStep && serviceName === "twilio") {
      calculateStep(fetchSavedTemplates).then((step) => {
        setStep(step);
      });
    }
  }, [calculateStep, serviceName]);

  return <AntdSteps responsive rootClassName="hs-steps-modal" direction={direction} size={size} status="process" current={step} items={steps} />;
}

function Description({ leftServiceName, rightServiceName, appProperties }) {
  return (
    <>
      <div>
        {appProperties?.providerCommonObj?.category === "call" && (
          <>
            <b>Note:</b> Set Up Call Teams and Call Flow can be verified and adjusted later.
          </>
        )}
      </div>

      {`Once all steps are completed, navigate to your ${capitalizeFirstLetter(leftServiceName)} account to begin ${
        appProperties?.providerCommonObj?.category === "call" ? "making calls" : "messaging"
      } through the connected account.`}
    </>
  );
}

function Banner({ serviceName }) {
  return (
    <div className="d-grid gap-3">
      <div className={`banners-sprite ${serviceName}-banner`} />
      {"Please ensure that you have completed the following steps:"}
    </div>
  );
}
