import { Card } from "antd";
import React from "react";
function NoAccess(props) {
  return (
    <div className="h-100 overflow-hidden w-100">
      <div className="hs-h-80 d-flex justify-content-center align-items-center">
        <Card
          style={{
            width: 300,
            background: "none",
            border: "1px solid #374151",
          }}
        >
          <div className="w-100 d-flex justify-content-center hs-h-20">
            <div className="call-action-icons cursor-pointer voice-restrict-access-indicator" />
          </div>
          <div className="w-100 d-flex justify-content-center hs-h-7">
            <span className="customer-in-offline-message text-white"> Call Access Restricted </span>
          </div>
          <div className="w-100 d-flex justify-content-center hs-h-7">
            <span className="customer-in-offline-message-secondary"> Contact your administrator to request Call access permissions</span>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default NoAccess;
