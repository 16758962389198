import { Input, Modal } from "antd";
import { useEffect, useState } from "react";

export default function VideoComponent({ videoObj, search, gap = "0" }) {
  const [openVideoIndex, setOpenVideoIndex] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [videoSourceObj, setVideoSourceObj] = useState(videoObj);

  useEffect(() => {
    if (search) {
      let filteredObj = videoObj.filter((item) => item.headline.toLowerCase().includes(searchInput.toLowerCase()));
      setVideoSourceObj(filteredObj);
    }
  }, [searchInput, videoObj, search]);

  return (
    <div>
      {search && (
        <Input
          placeholder="Search videos"
          className="hs-bg-off-white rounded border-default focus-border h-45 mb-2"
          variant="borderless"
          prefix={<span className="actionIconsSprite search-icon"></span>}
          onChange={(e) => {
            setSearchInput(e.target.value?.trim());
          }}
        />
      )}
      <div className={`d-flex flex-grow-1 flex-column gap-${gap}`} style={{ maxHeight: "70vh" }}>
        {videoSourceObj?.map((obj, index) => (
          <div key={index} className="d-flex align-item-center video-component-wrap mb-2">
            <div className="video-info  d-flex align-items-center justify-content-center">
              <div className={`video-thumbnail-2`}>
                <div
                  className="actionIconsSprite videoLogo-webhook m-auto"
                  onClick={() => {
                    setOpenVideoIndex(index);
                  }}
                ></div>
              </div>
              {openVideoIndex === index && (
                <Modal
                  className="webhook-video-modal"
                  footer={null}
                  open={openVideoIndex === index}
                  onCancel={() => {
                    setOpenVideoIndex(null);
                  }}
                  centered
                  width={"80vw"}
                  style={{
                    top: 20,
                    height: "80vh",
                  }}
                >
                  <div className="iframe-wrapper">
                    <iframe
                      src={obj.sourceUrl}
                      title="HelloSend"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </Modal>
              )}
            </div>
            <div className="video-info-list my-auto">
              <span>{obj?.headline}</span>
              <ol className="ms-2">
                {obj?.steps?.map((listItem, index) => (
                  <li key={index}>{listItem}</li>
                ))}
              </ol>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
