import Link from "../components/custom/info/Link.jsx";
import AccountPhone from "../pages/OnboardingComponents/AccountPhone.jsx";
import AddPhone from "../pages/OnboardingComponents/AddPhone.jsx";
import AuthorizePage from "../pages/OnboardingComponents/AuthorizePage";
import GuidetoSendSms from "../pages/OnboardingComponents/GuideToSendSms.jsx";
import OnboardingFinishModalContent from "../pages/OnboardingComponents/OnboardingFinishModalContent.jsx";
import { getSmsSteps, getVoiceSteps, getWhatsAppSteps } from "../pages/OnboardingComponents/onboardingUtils/onboardingFinishModalSteps.jsx";

import { FB_API_VERSION, FB_SDK_URL } from "../pages/utils/commonUtils.js";
import VoiceOnboardingFinishPopup from "../voice/onboarding/VoiceOnboardingFinishPopup.jsx";
import { WHATSAPP_DISPLAY_NAME } from "./AppConstants.js";
export const urlParams = new URLSearchParams(window.location.search);
const url = new URL(window.location.href);

const params = new URLSearchParams(url.search);

params.delete("sideBar");
params.delete("onBoardingPage");
params.delete("onInstall");

export const ProviderCommonObj = {
  isMMSSupported: false,
  isAlphaSenderSupported: false,
  category: "SMS",
  isMessagingServiceSenderSupported: false,
  isEnableInboundSms: false,
  app: "",
  title: "",
  AuthorizationType: "",
  loginContent: [],
  steps: [
    {
      title: "account",
      description: "Account",
      content: <AccountPhone />,
    },
    {
      title: "installation",
      description: "Installation",
      content: <AuthorizePage />,
    },
    {
      title: "addPhone",
      description: "Add Phone",
      content: <AddPhone />,
    },
    {
      title: "guide",
      description: "Guide to send SMS",
      content: <GuidetoSendSms />,
    },
  ],
  menu: [],
  supportedAttachmentTypes: {
    image: "image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/tiff, text/vcard, video/mp4, video/mpeg, audio/mpeg",
  },
  maximumAttachmentSizeInBytes: 5242880,
  maximumAttachmentLimit: 5,
};

export const ProviderExtendedCommonObj = {
  hello_send: {
    howToAuthLink: null,
    app: "hello_send",
    category: "sms",
    isEnableInboundSms: false,
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isMessagingServiceSenderSupported: false,
    onBoardingConnected: true,
  },
  twilio: {
    AuthorizationType: "auth",
    AuthorizeReference: [
      <>
        Go to{" "}
        <a href="https://www.twilio.com/" target="_blank" rel="noopener noreferrer">
          https://www.twilio.com/
        </a>{" "}
        and click on the 'Sign up' button to create a new account. If you already have an account, log in to your account.
      </>,
      "On the dashboard, you will see your Account SID and Auth token.",
      "Copy your Account SID and Auth token and use them to authenticate Twilio service.",
    ],
    AuthorizeReferenceText: "That's it! You now have your Twilio Account SID and Auth token.",
    AuthorizeReferenceURL: "https://www.youtube.com/embed/hv-P_qDN2-Y",
    app: "twilio",
    category: "sms",
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Twilio_",
    title: "Authorize Twilio to connect SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
      video:
        "video/mpeg,video/mp4,video/quicktime,video/webm,video/3gpp,video/3gpp2,video/3gpp-tt,video/H261,video/H263,video/H263-1998,video/H263-2000,video/H264",

      file: "application/pdf, application/vcard, application/vnd.apple.pkpass, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
      {
        label: "Messaging Service",
        key: "MessagingService",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          steps: getSmsSteps(),
          head: "SMS capability successfully activated!",
          subHead: "Ready to elevate your customer communication? Here’s how:",
        };
      },
    },
  },
  twilio_voice: {
    AuthorizationType: "auth",
    AuthorizeReference: [
      <>
        Go to{" "}
        <a href="https://www.twilio.com/" target="_blank" rel="noopener noreferrer">
          https://www.twilio.com/
        </a>{" "}
        and click on the 'Sign up' button to create a new account. If you already have an account, log in to your account.
      </>,
      "On the dashboard, you will see your Account SID and Auth token.",
      "Copy your Account SID and Auth token and use them to authenticate Twilio service.",
    ],
    AuthorizeReferenceText: "That's it! You now have your Twilio Account SID and Auth token.",
    AuthorizeReferenceURL: "https://www.youtube.com/embed/hv-P_qDN2-Y",
    app: "twilio",
    category: "call",
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Twilio_",
    title: "Authorize Twilio to connect call numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
      file: "application/pdf, application/vcard, application/vnd.apple.pkpass, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties, callProperties, setCallProperties) => {
        const callFlowEndPoint = `/voice/callFlow/edit/${callProperties?.savedNumbers?.[0]?.callFlowId}/phoneNumber/${encodeURIComponent(
          callProperties?.savedNumbers?.[0]?.phoneNumber
        )}`;
        const handleMakeTestCall = () => {
          if (appProperties) {
            window.open(appProperties?.domain, "_blank");
          }
        };
        return {
          steps: getVoiceSteps(callFlowEndPoint, appProperties),
          note: "Set Up Call Teams and Call Flow can be verified and adjusted later.",
          head: "Call capability successfully activated!",
          subHead: (
            <div className="d-flex flex-row">
              <div>Ready to make your first call?</div>
              <div className="ps-1 hs-fs-12 hs-fw-500 hs-color-violet cursor-pointer" onClick={handleMakeTestCall}>
                Make Test Call
              </div>
            </div>
          ),
        };
      },
    },
  },

  facebook: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Facebook",
    category: "messenger",
    AuthorizationType: "outh",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    isEnableInboundSms: false,
    app: "facebook",
    title: "Connect facebook and configure all pages",
    menu: [
      {
        label: "Select Pages",
        key: "Select Pages",
      },
    ],
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          steps: getSmsSteps(),
          head: "SMS capability successfully activated!",
          subHead: "Ready to elevate your customer communication? Here’s how:",
        };
      },
    },
  },
  ringcentral: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_RingCentral",
    AuthorizationType: "oauth",
    AuthorizeReference: [
      "Begin by clicking on the 'Authorize' button.",
      "Enter your RingCentral username and password.",
      "Click on the 'Authorize' button to grant permission.",
      "Once completed, your RingCentral account is connected.",
      "In the right panel, you'll find all SMS-enabled numbers available for configurations.",
    ],
    AuthorizeReferenceURL: "https://www.youtube.com/embed/U_HI1fQe8MM?si=oGTp9XcgM-lCXQY0",
    category: "sms",
    isEnableInboundSms: true,
    app: "ringcentral",
    title: "Authorize Ringcentral to connect SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          steps: getSmsSteps(),
          head: "SMS capability successfully activated!",
          subHead: "Ready to elevate your customer communication? Here’s how:",
        };
      },
    },
  },
  teams_helloSend: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/hellosend-microsoftteams",
    AuthorizationType: "no_auth",
    category: "sms",
    isEnableInboundSms: false,
    app: "teams_hellosend",
    title: "Authorize Teams and port all your numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
      file: "application/pdf, application/vcard, application/vnd.apple.pkpass, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    isMMSSupported: true,
    AuthorizeReference: [
      "Begin by clicking the 'Authorize' button.",
      "Enter your Microsoft Teams username and password.",
      "Verify your account by following the prompts.",
      "Once your account is verified, all your phone numbers will be listed for configuration.",
    ],
    AuthorizeReferenceURL: "https://www.youtube.com/embed/CWnXcFlWm4A",
  },
  whatsapp: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Facebook",
    category: "whatsapp",
    AuthorizationType: "oauth_sdk",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg",
      audio: "audio/aac, audio/amr, audio/mpeg, audio/mp4, audio/ogg",
      video: "video/3gp, video/mp4",
      document:
        "text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    onAuthorizeOpenSuccessModal: true,
    isMMSSupported: true,
    isEnableInboundSms: false,
    baseUrlForTheScript: FB_SDK_URL,
    loadScripts: () => {
      return new Promise((resolve) => {
        try {
          window.fbAsyncInit = function () {
            window.FB.init({
              appId: process.env.REACT_APP_FB_APP_ID,
              autoLogAppEvents: true,
              xfbml: true,
              version: FB_API_VERSION,
            });
          };
          (function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = FB_SDK_URL;
            fjs.parentNode.insertBefore(js, fjs);
          })(document, "script", "facebook-jssdk");
        } catch (error) {
        } finally {
          resolve(FB_SDK_URL);
        }
      });
    },
    doUnloadAuthorizationProcess: () => {},
    getHandleMessageAuthEvent: (wabaId) => {
      const handleAuthMessageEvent = (event) => {
        if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") return;
        try {
          const data = JSON.parse(event.data);
          if (data.type === "WA_EMBEDDED_SIGNUP" && data.event === "FINISH") {
            wabaId.current = data.data.waba_id;
          }
        } catch {}
      };
      return handleAuthMessageEvent;
    },
    doUnloadAuthorizationProcess: (listenerFunctionToRemove) => {
      window.removeEventListener("message", listenerFunctionToRemove);
    },
    getAuthorizeFunction: (callBackFunction, handleAuthMessageEvent) => {
      return new Promise((resolve) => {
        if (handleAuthMessageEvent) {
          window.addEventListener("message", handleAuthMessageEvent);
        }

        // Launch method and callback registration
        const launchWhatsAppSignup = () => {
          window.FB.login(callBackFunction, {
            config_id: process.env.REACT_APP_FB_CONFIG_ID,
            response_type: "code",
            override_default_response_type: true,
            extras: {
              setup: {},
              featureType: "",
              sessionInfoVersion: "3",
            },
          });
        };
        resolve(launchWhatsAppSignup);
      });
    },
    app: "whatsapp",
    title: `Connect ${WHATSAPP_DISPLAY_NAME} and configure all numbers`,
    description: (
      <>
        Connect {WHATSAPP_DISPLAY_NAME} business numbers and bring all your conversations to one place. To know more
        <Link externalLink baseUrl="https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration">
          Click here
        </Link>
      </>
    ),
    note: <></>,
    helper: [
      <>
        Click <b>'Connect'</b> to log into your Facebook Business account.
      </>,
      <>Follow the steps to link or register your {WHATSAPP_DISPLAY_NAME} number and back up your data if using a personal number.</>,
    ],
    successAuthModal: {
      title: "Congratulations !",
      content: `Your account has been successfully connected your ${WHATSAPP_DISPLAY_NAME} account. Please add Templates to continue.`,
      button: {
        ok: {
          text: "Add Templates",
        },
        cancel: {
          text: "Cancel",
        },
      },
    },
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="whatsapp" />,
          steps: getWhatsAppSteps(WHATSAPP_DISPLAY_NAME, appProperties),
          calculateStep: async (fetchSavedTemplates) => {
            return new Promise((resolve, reject) => {
              var step = 0;
              const whatsappProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "whatsapp");
              if (whatsappProp?.right?.auth?.authorized) {
                step = step + 2;
              }

              fetchSavedTemplates?.(appProperties)
                .then((res) => {
                  if (res?.success) {
                    const hasWhatsappTemplate = res.data?.some((template) => template.integId === whatsappProp?.integProps?.integId);
                    if (hasWhatsappTemplate) {
                      step = step + 1;
                    }
                  }
                })
                .finally(() => {
                  resolve(step);
                });
            });
          },
          getGallery: (appName) => {
            return appProperties?.controller?.WHATSAPP_GALLERY;
          },
        };
      },
    },
  },
};
