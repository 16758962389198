import { BaseEdge, EdgeLabelRenderer, useReactFlow, getSmoothStepPath } from "reactflow";

export default function CustomEdge({ id, sourceX, sourceY, targetX, targetY }) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={{ strokeDasharray: "2,2", strokeWidth: "1px", strokeOpacity: "0.5" }} />
    </>
  );
}
