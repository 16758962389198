import { urlParams } from "../constants/AppConstants";

export let Hubspot = {
  service: "Hubspot",
  planDetails: {
    topPlan: "Gold",
    topPlanKey: "OMNI_GOLD",
    topPlanId: "omni_gold-USD-Monthly",
    totalUsers: 3,
  },
  sideBarExtend: {
    homeSideBar: true,
    serviceSideBar: true,
    pagesSidebar: true,
    usersSideBar: true,
    workFlowSideBar: true,
  },
  workFlowIconInTemplates: true,

  loadScripts: function () {
    return new Promise((resolve, reject) => {});
  },

  pricingLink: function (appProperties) {
    return new Promise((resolve, reject) => {
      const { hash, osyncId, installedApps } = appProperties;
      const integIds = installedApps?.map((integration) => integration?.integProps?.integId);

      var isOmniAccountParam = urlParams.get("isOmniAccount");
      var emailId = urlParams.get("emailId");
      var userId = urlParams.get("userId");
      var leftServiceId = urlParams.get("leftServiceId");

      var hrefUrl = `/pricing?productId=100&groupName=OMNI_CHANNEL&leftServiceId=${leftServiceId}&serviceName=hubspot&osyncId=${osyncId}&hash=${hash}&isOmniAccount=${isOmniAccountParam}&onInstall=true&emailId=${emailId}&userId=${userId}&integIds=${integIds}`;

      console.log("hrefUrl >>>>>>>>>>>>>>>>>>>.", hrefUrl);
      resolve(hrefUrl);
    });
  },

  checkIsAlreadyInstalled: function (fetchData) {
    return new Promise((resolve, reject) => {
      var isOmniAccount = urlParams.get("isOmniAccount");
      var integIdFromReq = urlParams.get("integId");
      var hashFromReq = urlParams.get("hash");
      var osyncIdFromReq = urlParams.get("osyncId");
      var rightServiceId = urlParams.get("rightServiceId");
      var leftServiceId = urlParams.get("leftServiceId");
      var rightServiceName = urlParams.get("service");

      if (isOmniAccount) {
        if (osyncIdFromReq === "" || osyncIdFromReq === undefined) {
          osyncIdFromReq = localStorage.getItem("Omni_osyncId");
        }
        if (hashFromReq === "" || hashFromReq === undefined) {
          hashFromReq = localStorage.getItem("Omni_hash");
        }
        if (integIdFromReq === "" || integIdFromReq === undefined) {
          integIdFromReq = localStorage.getItem("Omni_integId");
        }
      }
      console.log("isOmniAccount>>>>>>", isOmniAccount);
      console.log("integIdFromReq>>>>>>", integIdFromReq);
      console.log("osyncIdFromReq>>>>>>", osyncIdFromReq);
      console.log("hashFromReq>>>>>>", hashFromReq);

      if (isOmniAccount && integIdFromReq && hashFromReq) {
        var extensionData = {
          hash: hashFromReq,
          integId: integIdFromReq,
          osyncId: osyncIdFromReq,
          leftServiceName: "hubspotcrm",
          displayName: "hubspot",
          emailId: "shanthi@oapps.xyz",
          rightServiceName: rightServiceName,
          serviceId: rightServiceId,
          isOmniAccount: isOmniAccount,
          leftServiceId: leftServiceId,
        };

        resolve(extensionData);
      }
    });
  },

  getUserData: function () {
    var code = urlParams.get("code");
    var portalId = urlParams.get("portalId");
    var emailId = urlParams.get("emailId");
    var userId = urlParams.get("userId");
    return new Promise((resolve, reject) => {
      var userData = {
        companyId: portalId,
        companyOrgId: portalId,
        code: code,
        email: emailId,
        userId: userId,
      };
      resolve(userData);
    });
  },
  getAssociatedObjectId: function (thisObj) {
    return new Promise((resolve, reject) => {
      if (urlParams.get("associatedObjectId")) {
        resolve(urlParams.get("associatedObjectId"));
      } else {
        resolve("");
      }
    });
  },
  getAssociatedObjectType: function (thisObj) {
    return new Promise((resolve, reject) => {
      if (urlParams.get("associatedObjectType")) {
        resolve(urlParams.get("associatedObjectType"));
      } else {
        resolve("");
      }
    });
  },
  getAssociatedIntegId: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      if (urlParams.get("integId")) {
        resolve(urlParams.get("integId"));
      } else {
        resolve("");
      }
    });
  },
  getAssociatedHash: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      if (urlParams.get("hash")) {
        resolve(urlParams.get("hash"));
      } else {
        resolve("");
      }
    });
  },
  fetchContact: function (thisObj) {
    return new Promise((resolve, reject) => {
      var phone = urlParams.get("phone");
      var mobile = urlParams.get("mobile");
      if (phone) {
        resolve(phone.trim());
      } else if (mobile) {
        resolve(mobile.trim());
      } else {
        resolve("");
      }
    });
  },
  doAfterSuccessfulEnable: function (dataObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getSubscriptionId: function (fetchSubscriptionData, appProperties) {
    return new Promise((resolve, reject) => {
      fetchSubscriptionData(appProperties).then((response) => {
        resolve(response);
      });
    });
  },
};
