import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Contentbar from "../components/main/Contentbar";
import Headerbar from "../components/main/Headerbar";
import Sidebar from "../components/main/Sidebar";
const AppLayout = ({ setLoading, breadCrumbItems, setBreadCrumbItems }) => {
  const [headerTitle, setHeaderTitle] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [sideBarAbsent, setSideBarAbsent] = useState(true);
  let path = window.location.pathname;

  let urlParams = new URLSearchParams(window.location.search);
  let sideBar = urlParams.get("sideBar");
  const toggleSidebar = () => {
    setSideBarAbsent((prev) => !prev);
  };
  useEffect(() => {
    if (
      path === "/zoho-crm/webhook" ||
      path === "/zoho-crm/landing" ||
      path === "/teams/landing" ||
      (sideBar === "false" &&
        path !== "/messages" &&
        path !== "/zoho-crm/webhook" &&
        path !== "/content" &&
        path !== "/sendsms" &&
        path !== "/zoho-crm/landing" &&
        path !== "/teams/landing")
    ) {
      setSideBarAbsent(false);
    } else if (sideBar === "false" && (path === "/sendsms" || path === "/messages" || path === "/content")) {
      setSideBarAbsent(true);
    }
  }, [path, sideBar]);

  return (
    <Layout>
      <div
        className={`sidebar-overlay ${sideBarAbsent ? "hide-sidebar" : "show-sidebar"} `}
        onClick={() => {
          setSideBarAbsent(true);
        }}
      >
        <Sidebar toggleSidebar={toggleSidebar} />
      </div>
      <Layout className="hs-bg-off-white h-100 hs-min-h-100-vh">
        <Headerbar toggleSidebar={toggleSidebar} headerTitle={headerTitle} setServiceName={setServiceName} />
        <Contentbar
          breadcrumbItems={breadCrumbItems}
          setBreadCrumbItems={setBreadCrumbItems}
          serviceName={serviceName}
          setLoading={setLoading}
          setHeaderTitle={setHeaderTitle}
        />
      </Layout>
      {/* <VoiceLayout/> */}
    </Layout>
  );
};

export default AppLayout;
