import { Card, Col, Row, Tooltip } from "antd";
import React from "react";
import HButton from "../../../components/custom/input/button/Button";

const VoiceTeamCard = ({ voiceTeams, onAddAgentsClick, onViewAgentsClick, showDeleteModalPopup }) => {
  const RenderMetric = ({ value }) => (
    <div className="d-flex flex-row hs-w-35">
      <div className="w-100 text-truncate">
        <Tooltip title={value}>
          <div className="ps-1 ellipsis hs-max-w-90">{value}</div>
        </Tooltip>
      </div>
    </div>
  );
  const ConnectingDot = ({ iconClass }) => <div className={iconClass} />;

  const renderTeamMetric = (label, value) => (
    <Col align="middle">
      <div className="hs-fw-400 hs-fs-11-9-px">{label}</div>
      <div className="hs-fw-600 hs-fs-20">{value}</div>
    </Col>
  );

  const renderActionButton = (label, teamObj, onClick) => (
    <Col span={11}>
      <HButton
        type="secondary"
        buttonClassName="hs-fw-500 hs-fs-12 hs-color-BlackRussian w-100"
        variant="cancel"
        size="s"
        onClick={() => onClick(teamObj)}
      >
        {label}
      </HButton>
    </Col>
  );

  return (
    <Row>
      {voiceTeams?.map((team, idx) => (
        <Col key={idx} className="me-3 mb-3">
          <Card className="voice-team-card">
            <Row className="gap-3 h-100">
              <Row justify="space-between" align="middle" className="w-100">
                <div className="w-100 d-flex flex-row">
                  <div className="hs-fw-600 w-100 hs-fs-15-3 pb-1">{team?.friendlyName}</div>
                  <div className="hs-w-15 d-flex justify-content-end">
                    <div className="call-action-icons voiceAgentsDeleteIcon cursor-pointer" onClick={() => showDeleteModalPopup(team?.teamId)} />
                  </div>
                </div>
                <div className="w-100 d-flex flex-row hs-fw-400 hs-fs-11-9-px hs-color-dark-grey">
                  <div className="d-flex flex-row hs-w-30">
                    <div className="w-100 d-flex flex-row justify-content-center align-items-center">
                      <div className="hs-w-25">
                        <div className="call-action-icons team-members-icon" />
                      </div>

                      <div className="hs-w-75">
                        {team?.voiceAgentsEntity?.length} {team?.voiceAgentsEntity?.length > 1 ? "members" : "member"}
                      </div>
                    </div>
                  </div>

                  {team?.teamMetrics?.callFlowFriendlyName && (
                    <>
                      <ConnectingDot iconClass="call-action-icons dot-icon ps-1" />
                      <RenderMetric value={team.teamMetrics.callFlowFriendlyName} />
                    </>
                  )}

                  {team?.teamMetrics?.phoneNumber && (
                    <>
                      <ConnectingDot iconClass="call-action-icons dot-icon ps-1" />
                      <RenderMetric value={team?.teamMetrics?.phoneNumber} />
                    </>
                  )}
                </div>
              </Row>

              <Row justify="space-evenly" align="middle" className="w-100">
                {renderTeamMetric("Online", team?.teamMetrics?.onlineAgents?.length)}
                {renderTeamMetric("Busy", team?.teamMetrics?.busyAgents?.length)}
                {renderTeamMetric("Offline", team?.teamMetrics?.offlineAgents?.length)}
              </Row>

              <Row justify="space-between" className="w-100">
                {renderActionButton("View Users", team, onViewAgentsClick)}
                {renderActionButton("Assign Users", team, onAddAgentsClick)}
              </Row>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default VoiceTeamCard;
