import { Col, Row } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import AuthorizePage, { servicesImages } from "../OnboardingComponents/AuthorizePage";
import { Logo } from "../utils/CommonVessels";

export default function NoStepsAuthPage(props) {
  const { rightService, setRightServiceObj, setSuccessAuthModal, backToChannelsButton } = props;

  return (
    <>
      {backToChannelsButton}
      <Row align={"top"} justify={"center"} className="w-100 hs-vh-75">
        <Row className="w-100">
          <Col span={20}>
            <MainContent rightService={rightService} setRightServiceObj={setRightServiceObj} setSuccessAuthModal={setSuccessAuthModal} />
          </Col>
        </Row>
      </Row>
    </>
  );
}

function MainContent({ rightService, setRightServiceObj, setSuccessAuthModal }) {
  const [appProperties] = useContext(AppContext);

  const { app, description, helper, note } = appProperties?.providerCommonObj ?? {};
  const appLogo = servicesImages[app + ".png"];
  return (
    <div className="p-4 d-grid gap-4">
      <Logo appLogo={appLogo} />
      <AppDescription description={description} />
      <AppHelper helper={helper} />
      <AppConnector setSuccessAuthModal={setSuccessAuthModal} rightService={rightService} setRightServiceObj={setRightServiceObj} />
    </div>
  );
}

function AppDescription({ description = "" }) {
  return <div id="nostepsauthpage-app-description">{description}</div>;
}

function AppConnector({ rightService, setRightServiceObj, setSuccessAuthModal }) {
  return (
    <AuthorizePage
      authorizeButtonName="Connect"
      rightServiceObj={rightService}
      setRightServiceObj={setRightServiceObj}
      source={"noStepsAuth"}
      setSuccessAuthModal={setSuccessAuthModal}
    />
  );
}

function AppHelper({ helper }) {
  if (!helper || !Array.isArray(helper)) {
    return <></>;
  }

  return (
    <div className="p-3 hs-border-8 hs-bg-light-blue-3">
      <ul className="ps-4 d-grid gap-2 mb-0">
        {helper.map((helperItem, index) => {
          return <li key={index}>{helperItem}</li>;
        })}
      </ul>
    </div>
  );
}
