import { Card } from "antd";
import React, { useState } from "react";
import HButton from "../../../components/custom/input/button/Button";
import { voiceAgentStatus } from "../../utils/VoiceUtils";
import { useVoiceContext } from "../../context/VoiceContext";
function OfflineComponent(props) {
  const [callProperties, setCallProperties] = useVoiceContext();
  let { handleStatusChange, setShowOfflineMessageWhileInit, agentStatus, outboundDisabledForThisUser } = props;

  const [buttonLoading, setButtonLoading] = useState(false);

  const goOnline = () => {
    setButtonLoading(true);
    handleStatusChange(voiceAgentStatus.ONLINE_WEB).then(function (res) {
      const statusValue = res?.data?.voiceAgentStatusEntity?.voiceAgentStatus;
      if (statusValue === voiceAgentStatus.ONLINE_WEB) {
        setCallProperties((prev) => ({
          ...prev,
          focusToNumberField: "visible",
        }));
      } else {
        setCallProperties((prev) => ({
          ...prev,
          focusToNumberField: "hidden",
        }));
      }
      setButtonLoading(false);
      setShowOfflineMessageWhileInit(false);
    });
  };

  return (
    <div className="hs-h-80 overflow-hidden w-100">
      <div className="hs-h-80 d-flex justify-content-center align-items-center">
        <Card
          style={{
            width: 300,
            background: "none",
            border: "1px solid #374151",
          }}
        >
          <div className="w-100 d-flex justify-content-center hs-h-20">
            <div
              className={`call-action-icons cursor-pointer ${
                agentStatus === voiceAgentStatus.OFFLINE ? "customer-in-offline-indicator" : "customer-in-busy-indicator"
              }`}
            />
          </div>
          <div className="w-100 d-flex justify-content-center hs-h-7">
            <span className="customer-in-offline-message text-white">
              <>
                You're <>{agentStatus === voiceAgentStatus.OFFLINE ? `offline` : `busy`}</>
              </>
            </span>
          </div>
          <div className="w-100 d-flex justify-content-center hs-h-7">
            <span className="customer-in-offline-message-secondary">
              {" "}
              <>Switch to online mode to receive calls.</>{" "}
            </span>
          </div>

          <>
            <div className="w-100 d-flex justify-content-center hs-h-60">
              <HButton
                loading={buttonLoading}
                onClick={goOnline}
                size="md"
                buttonClassName={"hs-w-fit mt-3 fw-bold"}
                style={{
                  background: "#605BFF",
                }}
              >
                {" "}
                Go Online
              </HButton>
            </div>
          </>
        </Card>
      </div>
    </div>
  );
}

export default OfflineComponent;
