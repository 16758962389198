import React, { useState, useEffect } from "react";
import { Row, Col, Drawer } from "antd";
import "../../assets/css/omessage.css"; // Ensure your CSS file is imported here
import SideBarMenuItem from "../../components/main/SideBarItemComponents/SideBarMenuItem";

export function TopMenuItems(props) {
  const { appProperties } = props;

  useEffect(() => {
    if (appProperties?.osyncId) {
      appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (queryParamsFromApp) {
        var urlParams = new URLSearchParams(queryParamsFromApp);
        urlParams.delete("sideBar");
        urlParams.delete("topBar");
      });
    }
  }, [appProperties?.osyncId]);

  const [settingsOffCanvas, setSettingsOffCanvas] = useState(false);
  const showSettingsMenu = () => {
    setSettingsOffCanvas(true);
  };
  const smsSettingsClose = () => {
    setSettingsOffCanvas(false);
  };

  return (
    <>
      {appProperties?.serviceInstalled ? (
        <div className="d-flex align-items-center">
          <span className={props.className} onClick={showSettingsMenu}>
            <div className="actionIconsSprite sendSMSMoreMenuIcon" alt="menu" />
          </span>
        </div>
      ) : null}
      <Drawer
        className="removeOutlineAntDrawer"
        title={
          <Row className="">
            <Col span={8} onClick={smsSettingsClose}>
              <div className="d-flex justify-content-start">
                <div className="actionIconsSprite topBarArrow" alt="menu" />
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex justify-content-center">
                <div>Menu</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="d-flex justify-content-end">
                <div className="sprite-icon topBarHellosend" alt="menu" />
              </div>
            </Col>
          </Row>
        }
        placement="right"
        closable={false}
        onClose={smsSettingsClose}
        width={200}
        open={settingsOffCanvas}
      >
        <SideBarMenuItem toggleSidebar={() => {}} sideBarCommonHandling={true} />
      </Drawer>
    </>
  );
}
