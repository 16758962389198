import { Col, Grid, List, Menu, Modal, Popover, Row, Spin, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import VoiceSelect from "./common/VoiceSelect";
import { EmptyListView, RenderNameWithEllipsis, UpgradeAlertPopup } from "../../pages/utils/CommonVessels";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useVoiceContext } from "../context/VoiceContext";
import { useVoiceNumbers } from "../hooks/useVoiceNumbers";
import { getCurrentFeature } from "../utils/VoiceUtils";
import CallHistoryDetails from "./call-screens/CallHistoryDetails";
const { Text } = Typography;

const VoiceCallLog = ({
  mobileView,
  handleDialPadHistoryClickToCall,
  setCallDetails,
  setOpenCallDetails,
  openCallDetails,
  callDetails,
  fetchCallLogs,
}) => {
  const [appProperties] = useContext(AppContext);
  const listRef = useRef(null);
  const scrollPosition = useRef(0);

  useEffect(() => {
    if (!openCallDetails && listRef.current) {
      listRef.current.scrollTop = scrollPosition.current;
    }
  }, [openCallDetails]);

  const handleScroll = () => {
    if (listRef.current) {
      scrollPosition.current = listRef.current.scrollTop;
    }
  };
  const { md, xl } = Grid.useBreakpoint();
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const [callProperties, setCallProperties] = useVoiceContext();
  const [logTableDataSource, setLogTableDataSource] = useState();
  const [callLogs, setCallLogs] = useState();
  const { fetchData } = useHttp();
  const [callHistoryListLoading, setCallHistoryListLoading] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecordingUrl, setCurrentRecordingUrl] = useState(null);
  const [phoneNumberList, setPhoneNumberList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("all");
  const [modalData, setModalData] = useState({});
  const [voiceIntegId, setVoiceIntegId] = useState();
  const { getVoiceSavedNumbers } = useVoiceNumbers();
  const audioRef = useRef(null);

  const getSavedNumber = async () => {
    if (voiceIntegId) {
      setLoading(true);
      getVoiceSavedNumbers("all")
        .then((response) => {
          const phoneNumbers = response?.map((item) => item.phoneNumber) || [];
          setPhoneNumberList(phoneNumbers);
        })
        .catch((error) => console.error("Error fetching or parsing data:", error))
        .finally(() => setLoading(false));
    }
  };

  const formatCallData = (call) => {
    const durationInSeconds = call?.callDuration || 0;
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    let formattedDuration = "";
    if (seconds > 0) formattedDuration = `${seconds}s`;
    if (minutes > 0) formattedDuration = `${minutes}m ${formattedDuration}`;
    if (hours > 0) formattedDuration = `${hours}h ${formattedDuration}`;

    const formattedUtcDuration = dayjs.utc(durationInSeconds * 1000).format("HH:mm:ss");

    const formattedDate = dayjs(call?.createdTime).format("D MMM YYYY h:mm A");
    const recordingUrl = Array.isArray(call?.recordingUrls) ? call?.recordingUrls.find((url) => url !== null) : null;
    const isMissed = call?.callStatus === "missed" || call?.callStatus === "ringing";
    const isCompleted = call?.callStatus === "completed";
    const voiceMailRecordingUrl = call?.voiceMailRecordingUrl;
    const customerNumber = call?.callDirection === "INBOUND" ? call?.fromNumber : call?.toNumber;
    const phoneNumber = call?.callDirection === "INBOUND" ? call?.toNumber : call?.fromNumber;
    const callNotes = call?.callDescription ? call?.callDescription.trim() : null;
    const contactName = call?.contactName ? call?.contactName.trim() : null;

    return {
      formattedDate,
      formattedDuration,
      formattedUtcDuration,
      recordingUrl,
      isMissed,
      isCompleted,
      voiceMailRecordingUrl,
      customerNumber,
      phoneNumber,
      callNotes,
      durationInSeconds,
      contactName,
    };
  };

  const openAudioPlayerModal = (url, title, customerNumber, formattedDate, iconBasedOnDirection) => {
    setCurrentRecordingUrl(url);
    setModalData({ title: title, customerNumber: customerNumber, formattedDate: formattedDate, iconBasedOnDirection: iconBasedOnDirection });
    setIsModalOpen(true);
  };
  const antIcon = <LoadingOutlined className="loading-button" spin />;

  const customIcon = <Spin indicator={antIcon} />;

  const onAudioPlayerModalClose = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsModalOpen(false);
    setCurrentRecordingUrl(null);
  };

  const handleClickToCall = (customerNumber, phoneNumber, recordId, module) => {
    setCallProperties((prev) => ({
      ...prev,
      isDialPadVisible: true,
      callerNumber: customerNumber,
      callType: "outbound",
      callRecordId: recordId,
      callRecordModule: module,
    }));
  };
  useEffect(() => {
    if (appProperties?.installedApps) {
      const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
      if (voiceIntegrations?.[0]?.integProps?.integId) {
        setVoiceIntegId(voiceIntegrations?.[0]?.integProps?.integId);
      }
    }
  }, [appProperties?.installedApps]);

  useEffect(() => {
    const fetchLogsBasedOnCallDirection = async () => {
      setLoading(true);
      setCallHistoryListLoading(true);
      setLogTableDataSource([]);
      let callFilterType = null;
      if (selectedMenuItem === "all") {
        callFilterType = "ALL";
      } else if (selectedMenuItem === "inbound") {
        callFilterType = "INBOUND";
      } else if (selectedMenuItem === "outbound") {
        callFilterType = "OUTBOUND";
      } else if (selectedMenuItem === "missedCalls") {
        callFilterType = "MISSED";
      } else if (selectedMenuItem === "voicemail") {
        callFilterType = "VOICEMAIL";
      }
      if (callFilterType) {
        const featureName = getCurrentFeature(appProperties);
        if (featureName === "Message") {
          setUpgradeAlertPopup(true);
        } else {
          const response = await fetchData("voice/logs?callFilterType=" + callFilterType, "GET", null, appProperties);
          if (response) {
            try {
              const responseData = JSON.parse(response);
              setCallLogs(responseData?.data);
            } catch (error) {
              console.error("Error parsing JSON:", error);
            } finally {
              setCallHistoryListLoading(false);
              setLoading(false);
            }
          }
        }
      }
    };

    if (selectedMenuItem) {
      fetchLogsBasedOnCallDirection();
      if (!phoneNumberList) {
        getSavedNumber();
      }
    }
  }, [selectedMenuItem, appProperties?.licenseObj?.licenseDetails?.planName]);

  const callHeaderItems = [
    { label: "All", key: "all" },
    { label: "Inbound", key: "inbound" },
    { label: "Outbound", key: "outbound" },
    { label: "Missed Calls", key: "missedCalls" },
    { label: "Voice Mail", key: "voicemail" },
  ];

  const callHeaderColumns = [
    {
      title: (
        <>
          <div className="d-flex flex-column justify-content-start ps-5"> Customer</div>
        </>
      ),
      dataIndex: "contacts",
      key: "contacts",
      ellipsis: true,
      width: 350,
    },
    { title: "User", dataIndex: "phoneNumber", key: "phoneNumber", ellipsis: true },
    { title: "Date & Time", dataIndex: "dateAndTime", key: "dateAndTime" },
    { title: "Duration", dataIndex: "duration", key: "duration" },
    { title: "Notes", dataIndex: "notes", key: "notes" },
    { title: "Recordings", dataIndex: "recordings", key: "recordings" },
    { title: "Voice Mail", dataIndex: "voiceMail", key: "voiceMail" },
  ];

  const phoneNumberOptions = useMemo(() => {
    return [
      { label: "All", value: "all" },
      ...(phoneNumberList?.map((number) => ({
        label: number,
        value: number,
      })) || []),
    ];
  }, [phoneNumberList]);

  const onPhoneNumberChange = (value) => {
    setSelectedPhoneNumber(value);
  };

  const callIconHandler = (callDirection, callStatus) => {
    if (callDirection === "INBOUND" && callStatus === "missed") {
      return "call-action-icons missedCallIcon";
    } else if (callDirection === "INBOUND") {
      return "callDirection-inbound call-action-icons";
    } else if (callDirection === "OUTBOUND") {
      return "callDirection-outbound call-action-icons";
    }
  };

  const callStatusHandler = (callDirection, callStatus, participantUserName) => {
    if (callDirection === "INBOUND" && callStatus === "ringing") {
      return "Missed";
    } else if (callDirection === "INBOUND" && callStatus === "missed") {
      return "Missed";
    } else if (callDirection === "INBOUND" && callStatus === "completed") {
      return "Answered";
    } else if (callDirection === "OUTBOUND" && callStatus === "missed") {
      return "Unanswered";
    } else if (callDirection === "OUTBOUND" && callStatus === "completed") {
      return "Answered";
    }
  };

  const handleCallHistoryInfoClick = (item) => {
    appProperties?.controller?.openRecord(callProperties?.loadedSDK, item?.associatedObjectId, item?.associatedObjectType);
  };
  const formatCallLog = (call, index) => {
    const {
      formattedDate,
      formattedUtcDuration,
      formattedDuration,
      recordingUrl,
      isMissed,
      isCompleted,
      voiceMailRecordingUrl,
      customerNumber,
      phoneNumber,
      contactName,
    } = formatCallData(call);
    const iconBasedOnDirection = callIconHandler(call?.callDirection, call?.callStatus);
    return {
      key: index + 1,
      contacts: (
        <div className="d-flex flex-row align-items-center  contact-item">
          <div className="col-1">
            <div className={`${iconBasedOnDirection}`} />
          </div>
          <div className="d-flex flex-column justify-content-start ms-3">
            <div>
              <RenderNameWithEllipsis content={contactName} />
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <div>{customerNumber}</div>
              <div className="call-icon ms-0">
                <div
                  className="call-action-icons logs-clickToCall-Icon"
                  onClick={() => handleClickToCall(customerNumber, phoneNumber, call?.associatedObjectId, call?.associatedObjectType)}
                />
              </div>
            </div>
            <div
              className={`ms-1 ${
                callStatusHandler(call?.callDirection, call?.callStatus, call?.participantUserName) === "Missed" && "hs-color-red"
              }`}
            >
              {callStatusHandler(call?.callDirection, call?.callStatus, call?.participantUserName)}
            </div>
          </div>
        </div>
      ),
      phoneNumber: (
        <div className="">
          {call?.participantUserName ? <RenderNameWithEllipsis content={call?.participantUserName ? call?.participantUserName : "-"} /> : null}
          <div>{phoneNumber}</div>
        </div>
      ),
      dateAndTime: formattedDate,
      duration: call.callDuration ? formattedUtcDuration : "00:00:00",
      notes:
        call?.callDescription && call?.callDescription.trim() ? (
          <Tooltip
            placement="rightTop"
            title={<div style={{ maxHeight: "150px", overflowY: "auto", whiteSpace: "pre-wrap" }}>{call?.callDescription}</div>}
          >
            <div className="callDescriptionIcon call-action-icons cursor-pointer" />
          </Tooltip>
        ) : (
          <div className="ms-2">-</div>
        ),

      recordings:
        isCompleted && recordingUrl ? (
          <div
            className="call-action-icons recordingsPlayIcon cursor-pointer ms-lg-3 "
            onClick={() => openAudioPlayerModal(recordingUrl, "CallRecording", customerNumber, formattedDate, iconBasedOnDirection)}
          ></div>
        ) : (
          <div className="ms-lg-4"> -</div>
        ),
      voiceMail:
        isMissed && voiceMailRecordingUrl ? (
          <div
            className="call-action-icons recordingsPlayIcon cursor-pointer ms-lg-3"
            onClick={() => openAudioPlayerModal(voiceMailRecordingUrl, "Voicemail", customerNumber, formattedDate, iconBasedOnDirection)}
          ></div>
        ) : (
          <div className="ms-lg-4"> -</div>
        ),
    };
  };
  const handleOpenCallDetails = (item) => {
    setOpenCallDetails(true);
    setCallDetails(item);
  };
  useEffect(() => {
    let filteredLogs = [];
    const filterByDirectionAndStatus = (direction, status) => {
      if (callLogs) {
        return callLogs?.filter((call) => {
          return (
            (direction === "ALL" || call.callDirection === direction) &&
            (status === "ALL" || call.callStatus === status) &&
            (selectedPhoneNumber !== "all" ? call.toNumber === selectedPhoneNumber || call.fromNumber === selectedPhoneNumber : true)
          );
        });
      }
    };
    switch (selectedMenuItem) {
      case "all":
        filteredLogs = filterByDirectionAndStatus("ALL", "ALL");
        break;

      case "inbound":
        filteredLogs = filterByDirectionAndStatus("INBOUND", "ALL");
        break;

      case "outbound":
        filteredLogs = filterByDirectionAndStatus("OUTBOUND", "ALL");
        break;

      case "missedCalls":
        filteredLogs = filterByDirectionAndStatus("INBOUND", "missed");
        break;

      case "voicemail":
        filteredLogs = Array.isArray(callLogs)
          ? callLogs.filter(
              (call) =>
                call?.callStatus === "missed" &&
                call?.voiceMailRecordingUrl &&
                (selectedPhoneNumber !== "all" ? call.toNumber === selectedPhoneNumber || call.fromNumber === selectedPhoneNumber : true)
            )
          : [];
        break;

      default:
        filteredLogs = [];
        break;
    }

    setLogTableDataSource(filteredLogs?.map((call, index) => formatCallLog(call, index)));
  }, [selectedMenuItem, selectedPhoneNumber, callLogs]);

  function formatTime(createdTime) {
    return dayjs(createdTime).format("hh:mm A");
  }
  const formatDate = (date) => {
    return dayjs(date).format("DD MMM");
  };
  const formatTimeDisplay = (createdTime) => {
    const now = new Date();
    const createdDate = new Date(createdTime);

    const nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const createdDateOnly = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());

    const differenceInDays = Math.floor((nowDateOnly - createdDateOnly) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
      return formatTime(createdDate);
    } else if (differenceInDays === 1) {
      return "Yesterday";
    } else {
      return formatDate(createdDate);
    }
  };
  return (
    <>
      {!mobileView ? (
        <div className="d-flex flex-column w-100 hs-h-90vh overflow-hidden ps-lg-4 ">
          <div className="d-flex hs-h-10 align-items-center">
            <Row className="d-flex hs-100 align-items-center">
              <Col className="call-action-icons voice-info-icon" />
              <Col className="ps-2 ">
                Where you can view all past calls, including incoming, outgoing, and missed calls, along with call details like duration,
                date/time, and recordings to know more{" "}
                <a
                  href="https://help.oapps.xyz/portal/en/kb/articles/call-module-for-crm#Call_Logs_in_HelloSend"
                  target="_blank"
                  rel="noreferrer"
                  className="custom-anchor"
                >
                  Click here.
                </a>
              </Col>
            </Row>
          </div>
          <div className="bg-white h-100 hs-h-92">
            <div className="d-flex justify-content-between px-3 py-2">
              <Menu
                onClick={(e) => setSelectedMenuItem(e.key)}
                selectedKeys={[selectedMenuItem]}
                mode="horizontal"
                items={callHeaderItems}
                rootClassName="call-logs-menu w-75"
              />
              {phoneNumberList?.length > 1 && (
                <div className="ps-3 d-flex justify-content-end align-items-center hs-w-20 hs-h-7  ">
                  <VoiceSelect
                    optionSpecification={false}
                    options={phoneNumberOptions}
                    customClass="hs-h-40-px mt-2"
                    value={selectedPhoneNumber}
                    onChange={(value) => onPhoneNumberChange(value)}
                  />
                </div>
              )}
            </div>
            {loading ? (
              <div className="d-flex justify-content-center antd-loading-icon hs-h-50 hs-fs-24">
                <LoadingOutlined spin className="mt-5" />
              </div>
            ) : (
              <>
                <div className="hs-h-85">
                  <Table
                    loading={loading}
                    className="m-0 voiceNumberTable h-100 phoneAddTable "
                    dataSource={logTableDataSource}
                    columns={callHeaderColumns}
                    locale={{
                      emptyText: (
                        <EmptyListView
                          icon={"callCardIcons emptyVoiceCallListView display-inline-block"}
                          tagLineOne={"No Call Record"}
                          tagLineTwo={"Your Recent Record will appear here"}
                          tagHeight={"50vh"}
                        />
                      ),
                    }}
                    pagination={false}
                    scroll={{
                      y: (() => {
                        if (xl) {
                          return "70dvh";
                        } else if (md) {
                          return "67vh";
                        }
                        return "70vh";
                        // const screenHeight = window.innerHeight;

                        // if (screenHeight <= 600) {
                        //   return 350; // Small screens
                        // } else if (screenHeight <= 900) {
                        //   return 500; // Medium screens
                        // } else if (screenHeight <= 1000) {
                        //   return 650; // Large screens
                        // } else {
                        // return "67vh";
                        // }
                      })(),
                      x: "100%",
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <Modal
            className="audio-player hs-bg-white"
            open={isModalOpen}
            style={{ borderRadius: 20 }}
            onCancel={onAudioPlayerModalClose}
            footer={null}
            title={modalData?.title}
          >
            <div className="d-flex justify-content-center gap-5 flex-column w-100 p-2 pt-4">
              <div>
                <div className="d-flex align-items-center gap-2">
                  <div className={`${modalData?.iconBasedOnDirection}`} />
                  <div className="gap-1 d-flex flex-column">
                    <div className="hs-fs-14 hs-fw-500">{modalData?.customerNumber}</div>
                    <div className="hs-fs-11 hs-color-light-grey-2">{modalData?.formattedDate}</div>
                  </div>
                </div>
              </div>
              <div>
                <audio ref={audioRef} src={currentRecordingUrl} controls className="w-100" />
              </div>
            </div>
          </Modal>
          {upgradeAlertPopup && <UpgradeAlertPopup upgradeAlertPopup={upgradeAlertPopup} setUpgradeAlertPopup={setUpgradeAlertPopup} />}
        </div>
      ) : !openCallDetails ? (
        <div>
          <div
            ref={listRef}
            className="p-3"
            style={{
              maxHeight: "620px",
              overflowY: "auto",
            }}
            onScroll={handleScroll}
          >
            <List
              rootClassName="callHistoryList"
              itemLayout="horizontal"
              loading={{ spinning: callHistoryListLoading, indicator: customIcon }}
              dataSource={callLogs}
              renderItem={(item, index) => {
                const { customerNumber, phoneNumber, formattedDuration, recordingUrl, callNotes, contactName } = formatCallData(item);
                return (
                  <List.Item className="text-white call-history-item cursor-pointer" onClick={() => handleOpenCallDetails(item)}>
                    <List.Item.Meta
                      className="text-white d-flex align-items-center"
                      avatar={<div className={`${callIconHandler(item?.callDirection, item?.callStatus)}`}></div>}
                      title={
                        <div className="call-title-container">
                          <div className="d-flex flex-column">
                            <div className="hs-fs-16">
                              <span className="text-white hs-fs-13 hs-fw-500">{item?.contactName}</span>
                            </div>
                            <div className="d-flex flex-row">
                              <span className="text-white hs-fs-13 hs-fw-500">
                                {item?.callDirection === "INBOUND" ? item?.fromNumber : item?.toNumber}
                              </span>
                              <span className="call-icon">
                                <div
                                  className="call-action-icons logs-clickToCall-Icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDialPadHistoryClickToCall(
                                      customerNumber,
                                      phoneNumber,
                                      item?.associatedObjectId,
                                      item?.associatedObjectType
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      }
                      description={
                        <div
                          className={` hs-fw-400 hs-fs-12 ${
                            callStatusHandler(item?.callDirection, item?.callStatus, item?.participantUserName) === "Missed"
                              ? "hs-color-red"
                              : "hs-color-md-grey"
                          }`}
                        >
                          {callStatusHandler(item?.callDirection, item?.callStatus, item?.participantUserName)}
                          <div className="d-flex flex-row">
                            <div className="hs-fs-11 hs-color-md-grey">{formatTimeDisplay(item?.createdTime)}</div>
                            {formattedDuration && (
                              <div className="d-flex flex-row">
                                <div className="call-action-icons dot-icon" />
                                <div>{formattedDuration}</div>
                              </div>
                            )}
                            {recordingUrl && <div className="call-action-icons call-history-callRecord-icon " />}
                            {callNotes && <div className="call-action-icons call-history-callNote-icon  " />}
                          </div>
                        </div>
                      }
                    />
                    {item?.associatedObjectId && item?.associatedObjectId && (
                      <div
                        className="call-details-icon call-action-icons cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCallHistoryInfoClick(item);
                        }}
                      />
                    )}
                  </List.Item>
                );
              }}
              locale={{
                emptyText: (
                  <EmptyListView
                    tagLineOne={<div className="text-white">No call history available</div>}
                    icon={`call-action-icons empty-call-history-icon  display-inline-block`}
                    tagLineTwo={`Your recent calls will appear here`}
                    tagHeight={"35vh"}
                  />
                ),
              }}
            />
          </div>
        </div>
      ) : (
        <CallHistoryDetails callDetails={callDetails} setOpenCallDetails={setOpenCallDetails} />
      )}
    </>
  );
};

export default VoiceCallLog;
