import React from 'react'
import { Spin, Upload } from 'antd'
import { LoadingOutlined } from "@ant-design/icons";

const UploadDragger = ({ props, loading, fileName, defaultValue }) => {
    const { Dragger } = Upload;
    return (
        <div> <Dragger {...props} style={{ background: "#fff" }} className="gragger" showUploadList={false}>
            <div className="align-items-center">
                <div className="audioUploadIcon hs-ms-140" />
                <p className="ant-upload-text">
                    <span className="hs-color-violet">
                        {loading ? (
                            <Spin className="" indicator={<LoadingOutlined spin className="" />} />
                        ) : fileName ? (
                            fileName
                        ) : (
                            defaultValue
                        )}
                    </span>{" "}  Drag and Drop</p>
                <p className="ant-upload-hint">(Max file Size: 2Mb)</p>
            </div>
        </Dragger></div>
    )
}

export default UploadDragger