import { Col, Row } from "antd";
import React from "react";

const NodesDrawer = ({ title, titleStyle, description, onClose, descriptionStyle, open, data }) => {
  return (
    <div className="w-100 h-100">
      <Row className="w-100 hs-border-bottom-light">
        <Col span={24} className="p-3">
          <Row className="w-100 pb-1">
            <div className="hs-fs-16 hs-fw-500  d-flex flex-row mt-3 ">
              <div className={titleStyle} />
              {title}
            </div>
          </Row>
          <Row className="w-100">
            <div className={descriptionStyle}>{description}</div>
          </Row>
        </Col>
      </Row>
      <Row className="w-100">
        <Col span={24} className="p-3">
          <div>{data}</div>
        </Col>
      </Row>
    </div>
  );
};

export default NodesDrawer;
