import { Avatar } from "antd";
import React from "react";

const colors = ["#605BFF", "#28A745", "#FFC107", "#605BFF", "#FF5733", "#C70039", "#900C3F", "#581845"];

const CommonUserAvatar = ({ name, icon, size, profileWithStatus, status }) => {
  const getInitialLetter = (name) => (name ? name.charAt(0).toUpperCase() : "");

  // Function to generate a unique color based on the user's name
  const generateColor = (name) => {
    if (!name) return colors[0];

    // Create a simple hash function to generate a unique index
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash += name.charCodeAt(i);
    }

    // Use the hash to get a color from the colors array
    return colors[hash % colors.length];
  };
  const statusColors = {
    online: "#35c658", //green
    offline: "rgb(178, 176, 176)", //grey
    busy: "orange", //orange
  };
  const backgroundColor = !icon ? generateColor(name) : "transparent";
  return (
    <div style={{ position: "relative", width: size, height: size }}>
      <Avatar size={size} src={icon || null} style={{ backgroundColor }}>
        {!icon && getInitialLetter(name)}
      </Avatar>
      {profileWithStatus && (
        <div
          style={{
            position: "absolute",
            bottom: 2,
            right: 2,
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: statusColors[status],
            border: "2px solid white",
          }}
        ></div>
      )}
    </div>
  );
};

export default CommonUserAvatar;
