import { useContext, useCallback } from "react";
import useHttp from "../../hooks/useHttp";
import { AppContext } from "../../context/AppContext";

export const useVoiceNumbers = () => {
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
  const voiceIntegId = voiceIntegrations?.[0]?.integProps?.integId;

  const getVoiceSavedNumbers = useCallback(
    (scope) => {
      return new Promise((resolve, reject) => {
        if (voiceIntegId) {
          fetchData(`voice/${voiceIntegId}/savedNumbers?scope=` + scope, "GET", null, appProperties).then((response) => {
            if (response) {
              const resData = JSON.parse(response);
              resolve(resData?.data);
            }
          });
        }
      });
    },
    [voiceIntegId, fetchData, appProperties]
  );

  const deleteVoiceSavedNumber = useCallback(
    (phoneNumber) => {
      return new Promise((resolve, reject) => {
        if (voiceIntegId) {
          fetchData(`voice/${voiceIntegId}/deleteNumber?phoneNumber=${encodeURIComponent(phoneNumber)}`, "DELETE", null, appProperties).then(
            (response) => {
              if (response) {
                const resData = JSON.parse(response);
                resolve(resData?.data);
              }
            }
          );
        }
      });
    },
    [voiceIntegId, fetchData, appProperties]
  );

  const addNumber = useCallback(
    (payload) => {
      return new Promise((resolve, reject) => {
        if (voiceIntegId) {
          fetchData(`voice/${voiceIntegId}/savePhone`, "POST", payload, appProperties).then((response) => {
            if (response) {
              const resData = JSON.parse(response);
              resolve(resData?.data);
            } else {
              resolve(response);
            }
          });
        }
      });
    },
    [voiceIntegId, fetchData, appProperties]
  );
  return { getVoiceSavedNumbers, deleteVoiceSavedNumber, addNumber };
};
