import React, { useContext } from "react";
import CommonUserAvatar from "../../CommonUserAvatar";
import { AppContext } from "../../../context/AppContext";
import { Divider } from "antd";

const CallHistoryDetails = ({ callDetails, setOpenCallDetails }) => {
  console.log("callDetails", callDetails);
  const [appProperties] = useContext(AppContext);
  const handleCallDetailsClose = () => {
    setOpenCallDetails(false);
  };
  const agentAction = callDetails?.callDirection === "INBOUND" ? "Attended By" : "Initiated By";
  const timestamp = callDetails?.createdTime;
  const date = new Date(timestamp);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  const recordingUrl = Array.isArray(callDetails?.recordingUrls) ? callDetails?.recordingUrls.find((url) => url !== null) : null;
  const voiceMailRecordingUrl = callDetails?.voiceMailRecordingUrl;

  const durationInSeconds = callDetails?.callDuration || 0;
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;
  const formattedCallDuration = minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  const participantIconUrl = appProperties?.licenseObj?.allLicensedUserDetails.find(
    (user) => user.osyncUserId === callDetails?.participant
  )?.avatar;
  return (
    <>
      {" "}
      <div className="hs-h-85 overflow-hidden w-100">
        <div className="hs-h-10 d-flex flex-row justify-content-between px-1 align-items-center border-bottom-dark-grey">
          <div className="text-white">Call Details</div>
          <div className="call-action-icons call-close-icon cursor-pointer" onClick={handleCallDetailsClose} />
        </div>
        <div className="call-history-details-container hs-h-90">
          <div className="mx-3 call-history-details-content h-100">
            <div className="hs-h-40 d-flex flex-column justify-content-center  hs-max-h-125-px gap-1">
              {callDetails?.contactName ? (
                <div className="d-flex flex-row align-items-center">
                  <div className="text-white  hs-fs-13 ps-2"> {callDetails?.contactName}</div>
                </div>
              ) : null}
              <div className="d-flex flex-row align-items-center">
                <div
                  className={`call-action-icons ${
                    callDetails?.callDirection === "INBOUND" ? "call-history-call-inbound-icon" : "call-history-call-outbound-icon"
                  } `}
                />
                <div className="text-white  hs-fs-13 ps-2">
                  {" "}
                  {callDetails?.callDirection === "INBOUND" ? callDetails?.fromNumber : callDetails?.toNumber}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className="call-action-icons call-history-date-icon" />
                <div className="hs-color-md-grey pt-2 hs-fs-12 ps-2">{formattedDate}</div>
              </div>
              <div className={`d-flex flex-row align-items-center`}>
                <div className="call-action-icons call-history-duration-icon" />
                <div className="d-flex flex-row">
                  <div className="hs-color-md-grey hs-fs-12 pt-2 ps-2">{formattedTime}</div>
                  {true && (
                    <div className="d-flex flex-row pt-2">
                      <div className="call-action-icons dot-icon" />
                      {formattedCallDuration && <div className="hs-color-md-grey hs-fs-12">{formattedCallDuration}</div>}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider style={{ borderColor: "#374151" }} />
            {callDetails?.participantUserName !== "null" && callDetails?.participantUserName && (
              <>
                <div className="hs-h-25 d-flex flex-column justify-content-center gap-2 hs-max-h-100-px">
                  <div className="text-white ps-2">{agentAction}</div>
                  <div className="d-flex flex-row align-items-center gap-3 ps-2">
                    <CommonUserAvatar
                      icon={participantIconUrl}
                      name={callDetails?.participantUserName === "null" ? "Unknown Caller" : callDetails?.participantUserName}
                    />
                    <div className="text-white">{callDetails?.participantUserName}</div>
                  </div>
                </div>
                <Divider style={{ borderColor: "#374151" }} />
              </>
            )}
            {recordingUrl || voiceMailRecordingUrl ? (
              <>
                <div className="hs-h-25 d-flex flex-column justify-content-center gap-2 hs-max-h-125-px">
                  <div className="text-white ps-2">{recordingUrl ? "Call Recording" : "Voicemail"}</div>
                  <div className="ps-2 ">
                    <audio
                      src={recordingUrl ? recordingUrl : voiceMailRecordingUrl ? voiceMailRecordingUrl : null}
                      controls
                      className="hs-w-90"
                    />
                  </div>
                </div>
                <Divider style={{ borderColor: "#374151" }} />
              </>
            ) : null}
            {callDetails?.callDescription ? (
              <div className="border-top-dark-grey d-flex flex-column justify-content-start gap-2">
                <div className="text-white ps-2">Notes</div>
                <div
                  className="hs-color-light-grey-3 ps-2"
                  style={{
                    overflowX: "hidden",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {callDetails?.callDescription}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default CallHistoryDetails;
