import React, { useContext, useState, useEffect } from "react";
import { Space, Row, Col, Button, Card, Typography, Image, Skeleton } from "antd";
import { AppContext } from "./../../context/AppContext";
import { TopMenuItems } from "./../../pages/utils/MoreMenu.jsx";
import BalanceCredits from "./BalanceCredits";
import useHttp from "./../../hooks/useHttp";

const { Text } = Typography;

export function Credits(props) {
  const handleCreditBalance = props.handleCreditBalance;
  const [active] = useState(true);
  const [chargeBeePlan, setChargeBeePlan] = useState("ChatGPT-USD-Monthly");

  const [chargeBeeCharge] = useState("ChatGPT-Credits-USD");
  const [showOneTimeCharge, setShowOneTimeCharge] = useState(false);
  const [avatarShape] = useState("circle");
  const [size] = useState("small");
  const [isSuccessCheckout, setIsSuccessCheckout] = useState(false);
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [balanceBackground, setBalanceBackground] = useState("creditsCardClass");
  const osyncId = appProperties.osyncId;
  const leftServiceId = appProperties.leftServiceId;
  const { fetchData } = useHttp();

  const menuItems = [];

  menuItems.push({
    key: "1",
    label: (
      <Button data-cb-type="portal" onClick={manageSubscription} type="text">
        Manage subscription
      </Button>
    ),
  });

  useEffect(() => {
    if (appProperties !== null && appProperties !== undefined) {
      if (appProperties.licenseObj != null && appProperties.licenseObj != undefined) {
        if (window.Chargebee != undefined && window.Chargebee != null) {
          window.Chargebee.registerAgain();
        }
      }
    }
  }, [appProperties.licenseObj, isSuccessCheckout]);

  function manageSubscription() {
    window.Chargebee?.registerAgain();
  }
  function selectedPlan() {
    let isSuccessCheckoutVariable = false;

    window.Chargebee?.registerAgain();

    var cbInstance = window.Chargebee?.getInstance();
    var cart = cbInstance.getCart();
    console.log(cart, "cart");

    cart.setCustomer({
      cf_oapps_id: appProperties.osyncId,
      cf_leftserviceid: appProperties.leftServiceId,
      cf_accounttype: "CHAT_GPT",
      cf_servicename: appProperties.service,
      cf_license: "CHAT_GPT",
    });

    cbInstance.setCheckoutCallbacks(function (cart) {
      // you can define a custom callbacks based on cart object

      return {
        loaded: function () {
          console.log(" dinesh ::::::::: loaded");
        },
        close: function () {
          if (isSuccessCheckoutVariable) {
            fetchData(`omni/license?leftServiceId=${leftServiceId}&osyncId=${osyncId}`, "GET", null, appProperties).then(function (response) {
              const licenseData = JSON.parse(response);
              console.log("license details after purchase", licenseData);
              appProperties.licenseObj = licenseData.data;
              setAppProperties(appProperties);
              setIsSuccessCheckout(true);
            });
          }
        },
        success: function (hostedPageId) {
          isSuccessCheckoutVariable = true;
        },
        step: function (value) {
          console.log("value -> which step in checkout");
          console.log(value);
        },
      };
    });
  }

  return (
    <>
      <Card size="small" style={{ height: 60 }} className={balanceBackground}>
        <Row className="p-0">
          <BalanceCredits
            setShowOneTimeCharge={setShowOneTimeCharge}
            setChargeBeePlan={setChargeBeePlan}
            setBalanceBackground={setBalanceBackground}
            chargeBeePlan={chargeBeePlan}
            chargeBeeCharge={chargeBeeCharge}
            handleCreditBalance={handleCreditBalance}
          />

          <Col span={6} className="d-flex justify-content-end">
            <BuyCreditsButton
              showOneTimeCharge={showOneTimeCharge}
              chargeBeePlan={chargeBeePlan}
              chargeBeeCharge={chargeBeeCharge}
              size={size}
              selectedPlan={selectedPlan}
            />
            <TopMenuItems className="d-flex align-items-center ps-2" style={{ color: "white" }} items={menuItems} />
          </Col>
        </Row>
      </Card>
    </>
  );

  function BuyCreditsButton(props) {
    const size = props.size;
    const chargeBeeCharge = props.chargeBeeCharge;
    const chargeBeePlan = props.chargeBeePlan;
    const showOneTimeCharge = props.showOneTimeCharge;
    const selectedPlan = props.selectedPlan;

    return (
      <Button
        data-cb-type="checkout"
        data-cb-item-0={chargeBeePlan}
        data-cb-item-0-quantity="1"
        data-cb-item-1={chargeBeeCharge}
        data-cb-item-1-quantity="1"
        className="mt-3 upgradeButton"
        shape="round"
        size={size}
        onClick={selectedPlan}
      >
        {showOneTimeCharge ? "Buy credits" : "Buy now"}
      </Button>
    );
  }
}
