import React, { useContext, useEffect, useState, useMemo } from "react";
import { Button, Col, Row, Table, Grid } from "antd";
import "../assets/css/omessage.css";
import { AppContext } from "../context/AppContext";
import "./../assets/css/users.css";
import { DeleteComponent, EmptyListView } from "./utils/CommonVessels";
import HsButton from "../components/custom/input/button/Button";
import { successNotifyWithDescription } from "./utils/CommonNotifications";
import { CommonSpinLoading, formatDate } from "./utils/CommonVessels";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import useHttp from "../hooks/useHttp";
import Input from "../components/custom/input/input/Input";
import HButton from "../components/custom/input/button/Button";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("local");

function BlockedNumbers() {
  const [appProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const [blockedNumberValue, setBlockedNumberValue] = useState("");
  const [savedBlockedNumbers, setSavedBlockedNumbers] = useState([]);

  const [showLoading, setShowLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [blockedNumberValueFailure, setBlockedNumberValueFailure] = useState([]);
  const [searchValue, setSearchValue] = useState(false);
  const { md } = Grid.useBreakpoint();
  const handleInputChange = (e) => {
    const value = e.target.value;
    const validInput = value.replace(/[^0-9,+\s]/g, "");
    setBlockedNumberValue(validInput);
  };

  useEffect(() => {
    const fetchBlockedNumbers = async () => {
      if (appProperties) {
        try {
          const body = "asc";
          const response = await fetchData(`blockednumbers?asc=false`, "GET", body, appProperties);
          const responseData = JSON.parse(response);

          const blockedNumbers = responseData?.data?.blockedNumbers || [];
          const dataSource = blockedNumbers.map((item, index) => ({
            email: appProperties?.licenseObj?.allLicensedUserDetails.find((user) => user.osyncUserId === item.createdBy)?.email ?? "-",
            key: index,
            blockedNumber: item.blockedNumber,
            createdTime: item.createdTime,
          }));

          setSavedBlockedNumbers(dataSource);
        } catch (error) {
          console.error("Error fetching blocked numbers:", error);
          setSavedBlockedNumbers([]); // Reset blocked numbers in case of failure
        } finally {
          setShowLoading(false); // Ensure this is always called
        }
      }
    };

    fetchBlockedNumbers();
  }, [appProperties]);

  const addBlockedNumbers = async () => {
    const trimmedBlockedNumber = blockedNumberValue.trim();
    if (!trimmedBlockedNumber) {
      setSearchValue(false);
      setBlockedNumberValueFailure("Enter a number to block!");
      return;
    }
    const isAlreadyBlocked = filteredBlockedNumbers.find((obj) => obj?.blockedNumber === trimmedBlockedNumber);
    if (isAlreadyBlocked) {
      setSearchValue(false);
      setBlockedNumberValueFailure("Number already blocked.");
      return;
    }

    try {
      const response = await fetchData(`blockednumber?phoneNumber=${encodeURIComponent(trimmedBlockedNumber)}`, "POST", null, appProperties);
      const responseData = JSON.parse(response);
      const blockedNumber = responseData?.data || {};
      blockedNumber.email =
        appProperties?.licenseObj?.allLicensedUserDetails.find((user) => user.osyncUserId === blockedNumber?.createdBy)?.email ?? "not found";
      setSavedBlockedNumbers((prev) => [blockedNumber, ...prev]);
      setBlockedNumberValue("");
      setBlockedNumberValueFailure(false); // Clear any error message
      successNotifyWithDescription("Add Blocked Numbers", "Number blocked successfully");
    } catch (error) {
      console.error("Error adding blocked number:", error);
      setBlockedNumberValueFailure("Failed to block the number. Please try again.");
    }
  };

  const deleteBlockedNumbers = async (record) => {
    const phoneNumber = record.blockedNumber;
    try {
      await fetchData(`blockednumber?phoneNumber=${encodeURIComponent(phoneNumber)}`, "DELETE", null, appProperties);
      setSavedBlockedNumbers((prev) => prev.filter((obj) => obj?.blockedNumber !== phoneNumber));
      successNotifyWithDescription("Deleted Blocked Numbers", "Number unblocked successfully!");
    } catch (error) {
      console.error("Error deleting blocked number:", error);
    }
  };

  const handleSearchChange = (e) => {
    if (e.key === "Enter") {
      const value = e.target.value.trim();
      if (!value) {
        setSearchValue(false);
        setSearchQuery("");
        return;
      }
      const localMatch = savedBlockedNumbers.find((number) => {
        return number.blockedNumber === value;
      });

      if (localMatch) {
        setSearchValue(false);
        setSearchQuery(localMatch.blockedNumber);
        return;
      }
      fetchData(`blockednumber?phoneNumber=${value}`, "GET", null, appProperties)
        .then((response) => {
          const responseData = JSON.parse(response);
          const blockedNumbers = responseData?.data?.blockedNumber;

          if (!blockedNumbers) {
            setSearchValue(true);
            setBlockedNumberValueFailure([]);
            setSearchQuery("");
            return;
          }

          setSearchValue(false);
          setSearchQuery(blockedNumbers);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setSearchValue(true);
        });
    }
  };

  const filteredBlockedNumbers = useMemo(() => {
    return searchQuery ? savedBlockedNumbers.filter((number) => number.blockedNumber.includes(searchQuery)) : savedBlockedNumbers;
  }, [searchQuery, savedBlockedNumbers]);

  const columns = [
    {
      title: <span className="hs-fw-600">Phone Number</span>,
      dataIndex: "blockedNumber",
      key: "blockedNumber",
      width: "8rem",
    },
    {
      title: <span className="hs-fw-600">Blocked by user</span>,
      dataIndex: "email",
      key: "email",
      width: "8rem",
    },
    {
      title: <span className="hs-fw-600">Created Time</span>,
      key: "createdTime",
      width: "8rem",
      dataIndex: "createdTime",
      render: (text) => {
        return <div>{formatDate(text)}</div>;
      },
    },
    {
      key: "deleteSelected",
      render: (record) => <UnblockNumberModal record={record} appProperties={appProperties} deleteBlockedNumbers={deleteBlockedNumbers} />,
      width: "20%",
    },
  ];

  return (
    <>
      <div className="h-100 ps-3 pe-3 overflow-hidden">
        <Row className="d-flex align-items-center hs-h-7 justify-content-start">
          <Col span={24}>
            <div className="flex-row d-flex align-items-center">
              <div style={{ cursor: "default" }} className="actionIconsSprite authInfo-icon"></div>
              <div className="ms-2 contentbarHeadingColor">Block numbers to stop messages and calls, effortlessly avoiding spam.</div>
            </div>
          </Col>
        </Row>
        <Row className="hs-h-10 d-flex align-items-center justify-content-start">
          <Col span={18} className="h-100 w-100">
            <Row className="d-flex align-items-center w-100 h-100">
              <Col span={12} className="w-75 h-100 d-flex align-items-center">
                <div className="d-flex flex-column w-100 h-100 justify-content-center">
                  <div className="d-flex justify-content-center h-75 w-100  align-items-center">
                    <Input placeholder="Enter phone number to block" value={blockedNumberValue} onChange={handleInputChange} />
                  </div>
                  <div>
                    <div id="fdnameError" className="hs-fs-12">
                      {blockedNumberValueFailure}
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12} className="w-100 ps-3 h-100 d-flex align-items-center">
                <div className="d-flex justify-content-start align-items-center h-100">
                  <HsButton
                    buttonClassName=""
                    size="l"
                    onClick={addBlockedNumbers}
                    icon={<div className="actionIconsSprite blockedNumbersWhiteIcon" />}
                  >
                    Add to Block List
                  </HsButton>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={6} className="h-100 w-100">
            <div className="d-flex align-items-center justify-content-end h-100 w-100">
              <div className="d-flex align-items-center justify-content-end h-75 w-100" style={{ border: "1px solid red !important" }}>
                <Input
                  className="contact-search-input hs-border-8"
                  prefix={<span className="actionIconsSpriteForSideBar blockedNumberSearchIcon" />}
                  placeholder="Look up blocked numbers"
                  onKeyDown={handleSearchChange}
                />
              </div>
              <div>
                <div id="fdnameError" className="hs-fs-12" style={{ display: searchValue ? "inherit" : "none" }}>
                  Phone Number not found!
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="hs-h-75 w-100">
          <Col span={24}>
            {showLoading ? (
              <CommonSpinLoading />
            ) : (
              <Table
                className="savedTempTable automationTable overflow-auto"
                scroll={{ y: "calc(100vh - 30vh)" }}
                pagination={false}
                columns={columns}
                dataSource={filteredBlockedNumbers}
                locale={{
                  emptyText: (
                    <EmptyListView
                      // tagLineOne={`No Teams added`}
                      // icon={`emptyTeamsPageIcon display-inline-block`}
                      tagLineTwo={`No numbers blocked`}
                      tagHeight={"13vh"}
                    />
                  ),
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default BlockedNumbers;

const UnblockNumberModal = ({ record, appProperties, deleteBlockedNumbers }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <DeleteComponent
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        record={record}
        confirmDeleteIcon={"confirmDeleteIcon"}
        confirmDeleteBtnName={"UnBlock"}
        confirmDeleteComponentTitle={"Unblock Number?"}
        confirmDeleteComponent={
          "Unblocking the number will allow it to send and receive messages/calls again. Are you sure you want to proceed?"
        }
        appProperties={appProperties}
        handleDelete={deleteBlockedNumbers}
      />
      <HButton
        buttonClassName="hs-fs-16 px-3"
        size="s"
        type="link"
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        prefixIcon={<span className="actionIconsSprite unBlockIcon"></span>}
        style={{ color: "#605bff" }}
      >
        Unblock
      </HButton>
    </>
  );
};
