import React, { useContext, useState, useEffect } from "react";
import { Space, Row, Col, Typography, Image, Skeleton } from "antd";
import { AppContext } from "./../../context/AppContext";
import EmptyCoinsSVG from "./../../assets/svg/empty-coins.svg";
import MoreCoinsSVG from "./../../assets/svg/more-coins.svg";
import LessCoinsSVG from "./../../assets/svg/less-coins.svg";

const { Text, Link } = Typography;
function BalanceCredits(props) {
  console.log("BalanceCredits >>>>>>>>>>>", props);
  const handleCreditBalance = props.handleCreditBalance;
  const setChargeBeePlan = props.setChargeBeePlan;
  const setShowOneTimeCharge = props.setShowOneTimeCharge;
  const setBalanceBackground = props.setBalanceBackground;
  const chargeBeePlan = props.chargeBeePlan;
  const chargeBeeCharge = props.chargeBeeCharge;
  const setBalanceCredits = props.setBalanceCredits;
  const [isSuccessCheckout] = useState(false);
  const [appProperties] = useContext(AppContext);
  const [credits, setCredits] = useState(<Skeleton.Avatar active={true} size="small" shape="circle" />);
  const [creditIcon, setCreditIcon] = useState(MoreCoinsSVG);

  useEffect(() => {
    if (appProperties !== null && appProperties !== undefined) {
      console.log(":::::::: -dinesh ::::::::", appProperties.licenseObj);
      if (appProperties.licenseObj !== null && appProperties?.licenseObj?.licenseDetails !== undefined) {
        console.log(":::::::: -dinesh 11111 ::::::::", appProperties.licenseObj.licenseDetails);
        let { creditsConsumed, creditsPurchased, licenseStatus } = appProperties.licenseObj.licenseDetails;
        let balanceCredits = creditsPurchased - creditsConsumed;
        console.log("balanceCredits >>>>>>>>>>>>>" + balanceCredits);
        setCredits(Number.parseFloat(balanceCredits).toLocaleString());

        if (setBalanceCredits != undefined) {
          setBalanceCredits(Number.parseFloat(balanceCredits).toLocaleString());
        }

        if (licenseStatus === 1) {
          setChargeBeePlan(chargeBeePlan);
          setShowOneTimeCharge(true);
          if (window.Chargebee != undefined && window.Chargebee != null) {
            window.Chargebee.registerAgain();
          }
        }

        const usagePercentage = (creditsConsumed / creditsPurchased) * 100;
        let creditObj = {
          balanceCredits: balanceCredits,
          usagePercentage: usagePercentage,
        };

        if (usagePercentage === 100) {
          setBalanceBackground("creditLowBalanceClass");
          setCreditIcon(EmptyCoinsSVG);
        } else if (usagePercentage > 90) {
          setBalanceBackground("creditLowBalanceClass");
          setCreditIcon(LessCoinsSVG);
        } else {
          setBalanceBackground("creditsCardClass");
        }
        handleCreditBalance(creditObj);
      }
    }
  }, [appProperties.licenseObj, isSuccessCheckout, appProperties.licenseObj?.licenseDetails]);
  return (
    <>
      {props?.sideBar ? (
        <div className="d-flex align-items-center justify-content-between lh-1">
          <div className={`d-flex flex-column align-items-center hs-fs-12 ${credits > 10 ? "lh-lg" : "lh-base"}`}>
            <div className="d-flex align-items-center hs-color-light-grey-2">
              <div className="actionIconsSprite creditsCoinIcon me-1"></div>
              <span> Credit Balance</span>
            </div>
            <div className="hs-fs-14 hs-fw-600">{credits} credits</div>
            {credits < 10 && <span className="hs-fs-11 hs-color-red">Credits running low</span>}
          </div>
        </div>
      ) : (
        <Col span={18}>
          <Space direction={props.className ? "" : "vertical"} className="fundsVal">
            <Text
              style={{
                color: props.className ? "#333" : "white",
                fontSize: props.className ? "13px" : "14px",
              }}
            >
              {props.className ? "" : "Balance"}
            </Text>
            <Row className={props.className === true ? "" : ""}>
              <Col span={6}>
                <Image width={20} className={props.className ? "" : "pt-2"} src={creditIcon} preview={false} />
              </Col>
              <Col span={18}>
                <Text
                  style={{
                    color: props.className ? "#666D71" : "black",
                    paddingRight: props.className ? "0.5em" : "13px",
                    fontSize: props.className ? "13px" : "20px",
                    paddingLeft: props.className ? "0.6em" : "0.3em",
                  }}
                  strong
                >
                  {credits}
                </Text>
              </Col>
            </Row>
          </Space>
        </Col>
      )}
    </>
  );
}

export default BalanceCredits;
