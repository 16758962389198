import React, { useEffect } from 'react';
import "../../assets/css/dialpad.css"

const DialPadKeys = ({ numbers, setNumbers, twilioDevice, disabled }) => {
    const handleNumberClick = (num) => {
        if (num === 'delete') {
            setNumbers(numbers?.slice(0, -1));
        } else {
            if ((numbers || "").length < 13) {
                setNumbers((numbers || "") + num);
            }

            if (twilioDevice) {
                console.log("DTMF>>>>", num)
                if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#', 'w', 'p'].includes(num)) {
                    twilioDevice.sendDigits(num);
                } else {
                    console.warn(`${num} is not a valid DTMF tone.`);
                }

            }
        }
    };
    const handleKeyPress = (event) => {
        const key = event.key;
        const keyMapping = {
            '1': '1', '2': '2', '3': '3', '4': '4', '5': '5',
            '6': '6', '7': '7', '8': '8', '9': '9', '+': '+',
            '0': '0', '#': '#', 'Backspace': 'delete'
        };

        if (keyMapping[key]) {
            handleNumberClick(keyMapping[key]);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [numbers]);
    const dialPadKeys = [
        [
            { num: "1", letters: "" },
            { num: "2", letters: "ABC" },
            { num: "3", letters: "DEF" },
        ],
        [
            { num: "4", letters: "GHI" },
            { num: "5", letters: "JKL" },
            { num: "6", letters: "MNO" },
        ],
        [
            { num: "7", letters: "PQRS" },
            { num: "8", letters: "TUV" },
            { num: "9", letters: "WXYZ" },
        ],
        [
            { num: "+", letters: "+" },
            { num: "0", letters: "+" },
            { num: "#", letters: "" },
        ],
    ];

    return (
        <>
            <div className={`dialpad-keys text-white w-100 ${disabled ? "disabled" : ""}`}>
                {dialPadKeys.map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                        {row.map((col, colIndex) => (
                            <div className={`col  ${disabled ? "" : "cursor-pointer"}`} key={colIndex} onClick={() => !disabled && handleNumberClick(col.num)}>
                                <span>{col.num}</span>
                                <span id="dialpadLetters">{col.letters}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
}

export default DialPadKeys