import { useContext, useState, useScript, useEffect } from "react";
import BalanceCredits from "../BalanceCredits";
import { RegisterChargebee, InitializeChargebee } from "../../../pages/utils/ChargebeeRegistration";
import { AppContext } from "../../../context/AppContext";
import { Chargebee_CHARGE_NAME } from "../../../constants/AppConstants";
import { Button } from "antd";

const SideBarCredits = ({ item }) => {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [chargeBeePlan, setChargeBeePlan] = useState(Chargebee_CHARGE_NAME);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.chargebee.com/v2/chargebee.js";
    script.async = true;

    script.onload = () => {
      setScriptLoaded(true);
      InitializeChargebee();
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function registerAgain() {
    RegisterChargebee("Omni", appProperties, setAppProperties);
  }
  return (
    <Button
      className="w-100 h-100"
      type="text"
      data-cb-type="checkout"
      data-cb-item-0={chargeBeePlan}
      data-cb-item-0-quantity="1"
      onMouseEnter={registerAgain}
    >
      <BalanceCredits
        setBalanceBackground={() => {}}
        setShowOneTimeCharge={() => {}}
        setChargeBeePlan={setChargeBeePlan}
        chargeBeePlan={chargeBeePlan}
        handleCreditBalance={() => {}}
        sideBar={true}
      />
    </Button>
  );
};
export default SideBarCredits;
