import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import useHttp from "../../hooks/useHttp";
import { useCallback } from "react";

export const useCallFlow = () => {
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);

  const getCallFlow = useCallback(() => {
    return new Promise((resolve, reject) => {
      fetchData(`voice/callFlow`, "GET", null, appProperties).then((response) => {
        if (response) {
          const resData = JSON.parse(response);
          resolve(resData?.data);
        }
      });
    });
  }, [fetchData, appProperties]);

  return { getCallFlow };
};
